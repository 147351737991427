import React, { useEffect } from "react";
// import DatePicker from "../../ui/forms/DatePicker";
import CrownIcon from "../../Static/RawImages/crown-icon.svg";
import ReactTooltip from "react-tooltip";
import NoRecord from "../../Components/NoRecord";
import ContentLoader from "../../Components/ContentLoader";
import { useState } from "react";
import { format } from "date-fns";
import { getAgentPerformance } from "../../API/agentApis";
import useAuthStore from "../../store/authStore";
import { showToast } from "../../utils/handleToast";
import { commaFormat } from "../../Functions/formatNumber";

const formatTime = (time) => (time ? time?.replace(" hrs,", "h")?.replace(" mins", "m#")?.split("#")[0] : "0m");

// Format Pluralization
const pr = new Intl.PluralRules("en-US", { type: "ordinal" });

const suffixes = new Map([
  ["one", "st"],
  ["two", "nd"],
  ["few", "rd"],
  ["other", "th"],
]);
const formatOrdinals = (n) => {
  const rule = pr.select(n);
  const suffix = suffixes.get(rule);
  return `${n}${suffix}`;
};

//

export default function MyPermonance() {
  const userData = useAuthStore((state) => state.userData);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [callsTarget, setCallsTarget] = useState(0);

  const [date, setDate] = useState(null);
  const [sort, setSort] = useState("");

  const clearFilter = () => {
    setData(null);
    setSort("");
  };

  async function fetchData() {
    setLoading(true);
    // const dateTemp = !!date ? format(new Date(date), "yyyy-MM-dd") : null;
    // console.log(dateTemp, sort);

    const res = await getAgentPerformance({ user_id: userData?.user_id });
    if (res) {
      setData(res?.data);
      setCallsTarget(res?.calls_target);
    } else {
      showToast({ message: "Unable to fetch details.", variant: "danger" });
    }

    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [date, sort]);

  return (
    <>
      {!!data?.length && !loading && (
        <div className="pageContent imp-lead-layout h-100">
          <div className="imp-leads-content mt-0 h-100">
            <div className="table-scroll h-100">
              <table className="agent-filter-table">
                {/* <thead>
                                    <tr>
                                        <th className="align-top">
                                            <div className="filter-label">Date</div>
                                            <DatePicker label="Search" onChange={setDate} value={date} />
                                        </th>

                                        <th className="align-top">
                                            <div className="filter-label">Total Oustanding</div>
                                            <select className="form-select" value={sort} onChange={(e) => setSort(e.target.value)}>
                                                {[
                                                    { label: "Sort by", value: "" },
                                                    { label: "Highest to Lowest", value: "desc" },
                                                    { label: "Lowest to Highest", value: "asc" },
                                                ]?.map(({ label, value }, index) => (
                                                    <option key={index} value={value}>
                                                        {label}
                                                    </option>
                                                ))}
                                            </select>
                                        </th>

                                        <th className="align-top" />
                                        <th className="align-top" />
                                        <th className="align-top" />

                                        <th colSpan={3} className="align-middle">
                                            <div className="d-flex align-items-center justify-content-end">
                                                <button className="btn btn-outline-primary">
                                                    <svg width="16" height="18" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M13 23L18.7735 13H14V1C14 0.447715 13.5523 0 13 0V0C12.4477 0 12 0.447715 12 1V13H7.2265L13 23Z"
                                                            fill="black"
                                                        />
                                                        <path
                                                            d="M1 20C0.447715 20 0 20.4477 0 21V28.5H25.5V21C25.5 20.4477 25.0523 20 24.5 20V20C23.9477 20 23.5 20.4477 23.5 21V26.5H2V21C2 20.4477 1.55228 20 1 20V20Z"
                                                            fill="black"
                                                        />
                                                    </svg>

                                                    {`Download Last 30 Day's Report`}
                                                </button>
                                            </div>
                                        </th>
                                    </tr>
                                </thead> */}
                <tbody>
                  {data?.map((item, index) => (
                    <tr key={index}>
                      <td className="py-4" style={{ position: "relative" }}>
                        <div>
                          <strong style={{ fontSize: "1rem" }}>{!!item?.created_at ? format(new Date(item?.created_at), "dd MMM yyyy") : "No Date"}</strong>
                          <br />

                          {!!item?.absent && <p class="mt-2 badge badge-pill bg-danger">Absent</p>}
                        </div>

                        <Divider />
                      </td>

                      <td className="py-4" style={{ position: "relative" }}>
                        <div className="text-center">
                          <strong style={{ fontSize: "1rem" }}>Rs. {commaFormat(parseInt(item?.total_collection)) || 0}</strong>
                          <br />
                          <span style={{ fontSize: "0.75rem" }}>{`(${item?.receipts || 0} receipts)`}</span>
                        </div>

                        <Divider />
                      </td>

                      <td className="py-4" style={{ position: "relative" }}>
                        <div className="text-center">
                          <strong style={{ fontSize: "1rem" }}>Rs. {commaFormat(parseInt(item?.actual_collection)) || 0}</strong>
                          <br />
                          <span style={{ fontSize: "0.75rem" }}>Actual Amount</span>
                        </div>

                        <Divider />
                      </td>

                      <td className="py-4 px-4">
                        <div className="text-center">
                          <span style={{ fontWeight: "500", color: "#000", fontSize: "1rem" }}>{item?.connected || 0}</span>
                          <br />
                          <span style={{ fontSize: "0.75rem" }}>Connected</span>
                        </div>
                      </td>

                      <td className="py-4 px-4">
                        <div className="text-center">
                          <span style={{ fontWeight: "500", color: "#000", fontSize: "1rem" }}>{item?.ptp || 0}</span>
                          <br />
                          <span style={{ fontSize: "0.75rem" }}>PTP</span>
                        </div>
                      </td>

                      <td className="py-4 px-4" style={{ position: "relative" }}>
                        <div className="text-center">
                          <span style={{ fontWeight: "500", color: "#000", fontSize: "1rem" }}>{!!item?.call_time ? formatTime(item?.call_time) : "0m"}</span>
                          <br />
                          <span style={{ fontSize: "0.75rem" }}>Call Time</span>
                        </div>

                        <Divider />
                      </td>

                      <td className="py-4 px-4">
                        <div className="text-center">
                          <span style={{ fontWeight: "500", color: "#000", fontSize: "1rem" }}>{!!item?.missed_calls ? item?.missed_calls : "0"}</span>
                          <br />
                          <span style={{ fontSize: "0.75rem" }}>Missed Calls</span>
                        </div>
                      </td>

                      {/* <td className="py-4 px-4">
                                                <div className="text-center">
                                                    <span style={{ fontWeight: "500", color: "#000", fontSize: "1rem" }}>78%</span>
                                                    <br />
                                                    <span style={{ fontSize: "0.75rem" }}>Recovered</span>
                                                </div>
                                            </td> */}

                      <td className="py-4 pe-0">
                        <div className="d-flex flex-column align-items-end justify-content-start gap-3">
                          <div
                            style={{
                              backgroundColor: "#faeaea",
                              borderRadius: "0.5rem",
                              color: "#b6706f",
                              fontWeight: "bold",
                              whiteSpace: "nowrap",
                            }}
                            className="d-flex align-items-center justify-content-end"
                          >
                            {Number(item?.connected) >= callsTarget && <span className="py-1 px-3">Calls</span>}

                            {!!item?.warning_count && Number(item?.connected) < callsTarget && (
                              <>
                                <span style={{ borderLeft: "0.1rem solid #fff" }} className="py-1 px-2" data-tip data-for="calls_tooltip">
                                  {formatOrdinals(item?.warning_count)}
                                </span>

                                <ReactTooltip effect="solid" place="bottom" className="py-2 px-3" id="calls_tooltip">
                                  <div style={{ fontWeight: "normal" }}>
                                    <strong>{`${formatOrdinals(item?.warning_count)} Warning`}</strong>
                                  </div>
                                </ReactTooltip>
                              </>
                            )}
                          </div>

                          <div
                            style={{
                              backgroundColor: !!item?.crown ? "#ecf7e3" : "#faeaea",
                              color: !!item?.crown ? "#849170" : "#b6706f",
                              borderRadius: "0.5rem",
                              fontWeight: "bold",
                              whiteSpace: "nowrap",
                            }}
                            className="d-flex align-items-center justify-content-end"
                          >
                            {Number(item?.actual_collection) >= item?.collection_target && <span className="py-1 px-3">Collection</span>}

                            {!!item?.crown && (
                              <>
                                <span style={{ borderLeft: "0.1rem solid #fff" }} className="pb-1 px-2" data-tip data-for="collection_tooltip">
                                  <img style={{ width: "1rem", objectFit: "contain", margin: "auto" }} src={CrownIcon} alt="crown-icon" />
                                </span>

                                {/* <ReactTooltip effect="solid" place="bottom" className="py-2 px-3" id="collection_tooltip">
                                                                    <div style={{ fontWeight: "normal" }}>
                                                                        <strong>{"Hurray!"}</strong>
                                                                        {" You have earned an incentive "} <br /> {`of `}
                                                                        <strong>Rs.200</strong>
                                                                    </div>
                                                                </ReactTooltip> */}
                              </>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {!loading && !data?.length && <NoRecord />}
      {loading && <ContentLoader />}
    </>
  );
}

function Divider() {
  return (
    <span
      style={{
        position: "absolute",
        top: "50%",
        right: "0%",
        height: "50%",
        width: "1px",
        backgroundColor: "#ccc",
        display: "block",
        transform: "translateY(-50%)",
      }}
    />
  );
}
