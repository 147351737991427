import axios from "axios";

export function handleApiError(error) {
  if (Number(error?.response?.status) === 401) {
    localStorage.clear();
    window.location.reload(false);
  }
  console.error("API Error:", error);
  
  return {
    isError: true,
    status: error?.response?.status,
    message: error?.response?.data?.message || error?.message || "An unknown error occurred",
    data: error?.response?.data || null
  };
}

export const apiGET = async (endPoint, params, cancelToken) => {
  try {
    const config = { params };
    if (!!cancelToken) {
      config.cancelToken = cancelToken;
    }
    
    const res = await axios.get(endPoint, config);
    return res.data;
  } catch (err) {
    handleApiError(err);
    throw err; 
  }
};

export const apiPOST = async (endPoint, body, params) => {
  try {
    const res = await axios.post(endPoint, body, { params });
    return res.data;
  } catch (err) {
    console.log(err);
    handleApiError(err);
    throw err; 
  }
};

export const apiPUT = async (endPoint, body, params) => {
  try {
    const res = await axios.put(endPoint, body, { params });
    return res.data;
  } catch (err) {
    handleApiError(err);
    throw err; 
  }
};

export const apiPATCH = async (endPoint, body, params) => {
  try {
    const res = await axios.patch(endPoint, body, { params });
    return res.data;
  } catch (err) {
    handleApiError(err);
    throw err; 
  }
};

export const apiDELETE = async (endPoint, body, params) => {
  try {
    const res = await axios.delete(endPoint, {
      data: body,
      params,
    });
    return res.data;
  } catch (err) {
    handleApiError(err);
    throw err;
  }
};