export const teleCollectioAsgmt = [
  {
    key: 1,
    client_name: "SmartCoin",
    loan_account_no: "SL7440962051",
    agent_name: "Utkarsh Thakur",
    borrower_name: "Bapi Sarkar",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/SL7440962051.mp3",
    call_time: "02m 44s",
    disposition: "Will pay later",
  },
  {
    key: 2,
    client_name: "SmartCoin",
    loan_account_no: "SL3433460253",
    agent_name: "Isha Dwivedi",
    borrower_name: "Ramlal",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/SL3433460253.mp3",
    call_time: "01m 36s",
    disposition: "PTP",
  },
  {
    key: 3,
    client_name: "Kreditbee",
    loan_account_no: "47248350",
    agent_name: "Utkarsh Thakur",
    borrower_name: "AMAN KUMARI",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/47248350.mp3",
    call_time: "03m 15s",
    disposition: "Will pay later",
  },
  {
    key: 4,
    client_name: "Kreditbee",
    loan_account_no: "46598592",
    agent_name: "Utkarsh Thakur",
    borrower_name: "JUNAID ALI",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/46598592.mp3",
    call_time: "02m 48s",
    disposition: "Settlement",
  },
  {
    key: 5,
    client_name: "Moneyview",
    loan_account_no: "183651598564",
    agent_name: "Innama Fatima",
    borrower_name: "Gondaliya Gijubhai",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/183651598564.mp3",
    call_time: "01m 30s",
    disposition: "PTP",
  },
  {
    key: 6,
    client_name: "Moneyview",
    loan_account_no: "151066672407",
    agent_name: "Innama Fatima",
    borrower_name: "Salauddin Idrish",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/151066672407.mp3",
    call_time: "02m 24s",
    disposition: "PTP",
  },
  {
    key: 7,
    client_name: "SmartCoin",
    loan_account_no: "SL0442136613",
    agent_name: "Utkarsh Thakur",
    borrower_name: "VIDYA NAND SHUKLA",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/SL0442136613.mp3",
    call_time: "02m 44s",
    disposition: "Wrong Number",
  },
  {
    key: 8,
    client_name: "SmartCoin",
    loan_account_no: "SL4416796151",
    agent_name: "Utkarsh Thakur",
    borrower_name: "Shouvik Kumar Das",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/SL4416796151.mp3",
    call_time: "02m 48s",
    disposition: "Settlement",
  },
  {
    key: 9,
    client_name: "Poonawalla",
    loan_account_no: "BLU0011NGS000007759839",
    agent_name: "Jyoti",
    borrower_name: "SRI PARAS AGENCY",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/BLU0011NGS000007759839.mp3",
    call_time: "03m 03s",
    disposition: "Will pay later",
  },
  {
    key: 10,
    client_name: "Poonawalla",
    loan_account_no: "BLU0118BL_000005008937",
    agent_name: "Iflah",
    borrower_name: "J S B TEX",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/BLU0118BL_000005008937.mp3",
    call_time: "06m 37s",
    disposition: "Will pay later",
  },
  {
    key: 11,
    client_name: "Moneyview",
    loan_account_no: "161776552801",
    agent_name: "Utkarsh Thakur",
    borrower_name: "Bappi Sarkar",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/161776552801.mp3",
    call_time: "03m 29s",
    disposition: "PTP",
  },
  {
    key: 12,
    client_name: "SmartCoin",
    loan_account_no: "SL2424610162",
    agent_name: "Innama Fatima",
    borrower_name: "Abhijit Dey",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/SL2424610162.mp3",
    call_time: "01m 36s",
    disposition: "Settlement",
  },
  {
    key: 13,
    client_name: "Kreditbee",
    loan_account_no: "47949399",
    agent_name: "Innama Fatima",
    borrower_name: "SEEMA SEEMA",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/47949399.mp3",
    call_time: "03m 15s",
    disposition: "PTP",
  },
  {
    key: 14,
    client_name: "Moneyview",
    loan_account_no: "127508041127",
    agent_name: "Utkarsh Thakur",
    borrower_name: "Junaid Ali",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/127508041127.mp3",
    call_time: "02m 28s",
    disposition: "Will pay later",
  },
  {
    key: 15,
    client_name: "Moneyview",
    loan_account_no: "118988095402",
    agent_name: "Innama Fatima",
    borrower_name: "Kamleshkumar Jayantilal Daraji",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/118988095402.mp3",
    call_time: "01m 30s",
    disposition: "PTP",
  },
  {
    key: 16,
    client_name: "Kreditbee",
    loan_account_no: "48785580",
    agent_name: "Innama Fatima",
    borrower_name: "JHUMA SARDAR",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/48785580.mp3",
    call_time: "02m 24s",
    disposition: "PTP",
  },
  {
    key: 17,
    client_name: "SmartCoin",
    loan_account_no: "SL1392831404",
    agent_name: "Innama Fatima",
    borrower_name: "DABBERU PRATHAP",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/SL1392831404.mp3",
    call_time: "02m 44s",
    disposition: "Settlement",
  },
  {
    key: 18,
    client_name: "Kreditbee",
    loan_account_no: "47989719",
    agent_name: "Innama Fatima",
    borrower_name: "MANSURI HAMIDABANU",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/47989719.mp3",
    call_time: "02m 48s",
    disposition: "PTP",
  },
  {
    key: 19,
    client_name: "Kreditbee",
    loan_account_no: "49803840",
    agent_name: "Innama Fatima",
    borrower_name: "BALDEV SINGH",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/49803840.mp3",
    call_time: "03m 03s",
    disposition: "PTP",
  },
  {
    key: 21,
    client_name: "Kreditbee",
    loan_account_no: "46470843",
    agent_name: "Innama Fatima",
    borrower_name: "ROHITKUMAR PRAVINBHAI CHENVA",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/46470843.mp3",
    call_time: "5m 0s",
    disposition: "PTP",
  },
  {
    key: 22,
    client_name: "Kreditbee",
    loan_account_no: "47019471",
    agent_name: "Innama Fatima",
    borrower_name: "KANUBHAI BATUKBHAI BALADHIYA",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/47019471.mp3",
    call_time: "04m 52s",
    disposition: "PTP",
  },
  {
    key: 23,
    client_name: "Kreditbee",
    loan_account_no: "49863280",
    agent_name: "Innama Fatima",
    borrower_name: "NIKHIL DULEY",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/49863280.mp3",
    call_time: "01m 42s",
    disposition: "P-PAID",
  },
  {
    key: 24,
    client_name: "Moneyview",
    loan_account_no: "117752861724",
    agent_name: "Innama Fatima",
    borrower_name: "Kiran Sudhakar Mukhare",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/117752861724.mp3",
    call_time: "02m 43s",
    disposition: "P-PAID",
  },
  {
    key: 25,
    client_name: "Kreditbee",
    loan_account_no: "49888373",
    agent_name: "Innama Fatima",
    borrower_name: "MOHD AFFAN",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/49888373.mp3",
    call_time: "03m 09s",
    disposition: "PTP",
  },
  {
    key: 26,
    client_name: "Moneyview",
    loan_account_no: "100549089256",
    agent_name: "Utkarsh Thakur",
    borrower_name: "Neelesh Ahirwar",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/100549089256.mp3",
    call_time: "07m 18s",
    disposition: "Settlement",
  },
  {
    key: 27,
    client_name: "Moneyview",
    loan_account_no: "160407812857",
    agent_name: "Utkarsh Thakur",
    borrower_name: "Firoz Khan Mustafa Khan",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/160407812857.mp3",
    call_time: "03m 01s",
    disposition: "PTP",
  },
  {
    key: 28,
    client_name: "Moneyview",
    loan_account_no: "SL3409912740",
    agent_name: "Utkarsh Thakur",
    borrower_name: "AHADI KETAN BALKRISHNA",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/SL3409912740.mp3",
    call_time: "02m 05s",
    disposition: "Paid",
  },
  {
    key: 29,
    client_name: "SmartCoin",
    loan_account_no: "SL6423669333",
    agent_name: "Utkarsh Thakur",
    borrower_name: "ALIM HUSSAIN",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/SL6423669333.mp3",
    call_time: "05m 55s",
    disposition: "Settlement",
  },
  {
    key: 30,
    client_name: "Moneyview",
    loan_account_no: "152836380767",
    agent_name: "Utkarsh Thakur",
    borrower_name: "Gangadhar Nayak",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/152836380767.mp3",
    call_time: "07m 04s",
    disposition: "Settlement",
  },
  {
    key: 31,
    client_name: "SmartCoin",
    loan_account_no: "SL6414689673",
    agent_name: "Utkarsh Thakur",
    borrower_name: "Roshan Yadav",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/SL6414689673.mp3",
    call_time: "01m 53s",
    disposition: "RTP",
  },
  {
    key: 32,
    client_name: "Wortgage",
    loan_account_no: "1983652",
    agent_name: "Utkarsh",
    borrower_name: "Shalu Sharma",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/1983652.mp3",
    call_time: "04:15:00",
    disposition: "Settlement",
  },
  {
    key: 33,
    client_name: "Wortgage",
    loan_account_no: "2183816",
    agent_name: "Utkarsh",
    borrower_name: "Himanshu Kumar",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/2183816.mp3",
    call_time: "03:05:00",
    disposition: "Settlement",
  },
  {
    key: 34,
    client_name: "Wortgage",
    loan_account_no: "2354847",
    agent_name: "Utkarsh",
    borrower_name: "Manish Yadav",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/2354847.mp3",
    call_time: "04:22:00",
    disposition: "Settlement",
  },
  {
    key: 35,
    client_name: "Kreditbee",
    loan_account_no: "34310824",
    agent_name: "Utkarsh",
    borrower_name: "Arti Singh",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/34310824.mp3",
    call_time: "04:58:00",
    disposition: "Settlement",
  },
  {
    key: 36,
    client_name: "Kreditbee",
    loan_account_no: "40294736",
    agent_name: "Utkarsh",
    borrower_name: "PATIL KISHORI AMOL",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/40294736.mp3",
    call_time: "03:48:00",
    disposition: "Settlement",
  },
  {
    key: 37,
    client_name: "Kreditbee",
    loan_account_no: "44023942",
    agent_name: "Jyoti",
    borrower_name: "PARVINBANU AIYUBKHAN PATHAN",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/44023942.mp3",
    call_time: "05:12:00",
    disposition: "Settlement",
  },
  {
    key: 38,
    client_name: "Kreditbee",
    loan_account_no: "44838113",
    agent_name: "Utkarsh",
    borrower_name: "Chetna",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/44838113.mp3",
    call_time: "04:20:00",
    disposition: "Settlement",
  },
  {
    key: 39,
    client_name: "Kreditbee",
    loan_account_no: "45528017",
    agent_name: "Utkarsh",
    borrower_name: "Kajalben Ravjibhai Dafda",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/45528017.mp3",
    call_time: "02:42:00",
    disposition: "Settlement",
  },
  {
    key: 40,
    client_name: "Kreditbee",
    loan_account_no: "46471878",
    agent_name: "Anam",
    borrower_name: "Poonam Devi",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/46471878.mp3",
    call_time: "04:01:00",
    disposition: "Settlement",
  },
  {
    key: 41,
    client_name: "Kreditbee",
    loan_account_no: "47805296",
    agent_name: "Innama",
    borrower_name: "PRIYA DAS",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/47805296.mp3",
    call_time: "03:57:00",
    disposition: "Settlement",
  },
  {
    key: 42,
    client_name: "Kreditbee",
    loan_account_no: "48912519",
    agent_name: "Iflaha",
    borrower_name: "JAGRUTI DHIRAJ DEVARE",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/48912519.mp3",
    call_time: "02:52:00",
    disposition: "Settlement",
  },
  {
    key: 43,
    client_name: "Kreditbee",
    loan_account_no: "49213522",
    agent_name: "Utkarsh",
    borrower_name: "Mahesh Kumar",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/49213522.mp3",
    call_time: "02:57:00",
    disposition: "Settlement",
  },
  {
    key: 44,
    client_name: "Kreditbee",
    loan_account_no: "49801843",
    agent_name: "Utkarsh",
    borrower_name: "VEERENDAR VEERENDAR",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/49801843.mp3",
    call_time: "04:27:00",
    disposition: "Settlement",
  },
  {
    key: 46,
    client_name: "Kreditbee",
    loan_account_no: "50667252",
    agent_name: "Utkarsh",
    borrower_name: "Souvik Das",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/50667252.mp3",
    call_time: "03:25:00",
    disposition: "Settlement",
  },
  {
    key: 47,
    client_name: "Moneyview",
    loan_account_no: "100669693547",
    agent_name: "Isha",
    borrower_name: "Gobin Ghosh",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/100669693547.mp3",
    call_time: "03:15:00",
    disposition: "Settlement",
  },
  {
    key: 48,
    client_name: "Moneyview",
    loan_account_no: "113515020045",
    agent_name: "Utkarsh",
    borrower_name: "Rani Nandkishor More",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/113515020045.mp3",
    call_time: "06:08:00",
    disposition: "Settlement",
  },
  {
    key: 49,
    client_name: "Moneyview",
    loan_account_no: "124051883858",
    agent_name: "Utkarsh",
    borrower_name: "Vishal",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/124051883858.mp3",
    call_time: "03:42:00",
    disposition: "Settlement",
  },
  {
    key: 50,
    client_name: "Moneyview",
    loan_account_no: "132236650684",
    agent_name: "Utkarsh",
    borrower_name: "Saif Ali",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/132236650684.mp3",
    call_time: "04:32:00",
    disposition: "Settlement",
  },
  {
    key: 51,
    client_name: "Moneyview",
    loan_account_no: "133151487056",
    agent_name: "Utkarsh",
    borrower_name: "Deepu Sheikh",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/133151487056.mp3",
    call_time: "04:42:00",
    disposition: "Settlement",
  },
  {
    key: 52,
    client_name: "Moneyview",
    loan_account_no: "133531325838",
    agent_name: "Isha",
    borrower_name: "Sonam Soni",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/133531325838.mp3",
    call_time: "03:14:00",
    disposition: "Settlement",
  },
  {
    key: 53,
    client_name: "Moneyview",
    loan_account_no: "138931961506",
    agent_name: "Jyoti",
    borrower_name: "Teklal Mandal",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/138931961506.mp3",
    call_time: "06:06:00",
    disposition: "Settlement",
  },
  {
    key: 54,
    client_name: "Moneyview",
    loan_account_no: "142885882618",
    agent_name: "Utkarsh",
    borrower_name: "Prakash",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/142885882618.mp3",
    call_time: "03:32:00",
    disposition: "Settlement",
  },
  {
    key: 55,
    client_name: "Moneyview",
    loan_account_no: "150444466664",
    agent_name: "Utkarsh",
    borrower_name: "SANJAYKUMAR BAJRANGLAL CHEJARA",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/150444466664.mp3",
    call_time: "03:27:00",
    disposition: "Settlement",
  },
  {
    key: 56,
    client_name: "Moneyview",
    loan_account_no: "151186038663",
    agent_name: "Utkarsh",
    borrower_name: "Manish Yadav",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/151186038663.mp3",
    call_time: "02:34:00",
    disposition: "Settlement",
  },
  {
    key: 57,
    client_name: "Moneyview",
    loan_account_no: "170318253835",
    agent_name: "Utkarsh",
    borrower_name: "Ashish Khushwaha",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/170318253835.mp3",
    call_time: "04:02:00",
    disposition: "Settlement",
  },
  {
    key: 58,
    client_name: "Moneyview",
    loan_account_no: "177508083718",
    agent_name: "Utkarsh",
    borrower_name: "Gandhar Nayak",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/177508083718.mp3",
    call_time: "04:44:00",
    disposition: "Settlement",
  },
  {
    key: 59,
    client_name: "Moneyview",
    loan_account_no: "186076963801",
    agent_name: "Utkarsh",
    borrower_name: "Shiv Bahar",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/186076963801.mp3",
    call_time: "02:54:00",
    disposition: "Settlement",
  },
  {
    key: 60,
    client_name: "Moneyview",
    loan_account_no: "186798926295",
    agent_name: "Utkarsh",
    borrower_name: "Komal Atul",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/186798926295.mp3",
    call_time: "05:23:00",
    disposition: "Settlement",
  },
  {
    key: 61,
    client_name: "Smartcoin",
    loan_account_no: "SL5408162935",
    agent_name: "Utkarsh",
    borrower_name: "Tanmay Singh",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/SL5408162935.mp3",
    call_time: "04:39:00",
    disposition: "Settlement",
  },
  {
    key: 62,
    client_name: "Smartcoin",
    loan_account_no: "SL7422670904",
    agent_name: "Utkarsh",
    borrower_name: "Sanjeev",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/SL7422670904.mp3",
    call_time: "04:27:00",
    disposition: "Settlement",
  },
  {
    key: 63,
    client_name: "Smartcoin",
    loan_account_no: "SL8422410382",
    agent_name: "Utkarsh",
    borrower_name: "Kadam Prashant",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/SL8422410382.mp3",
    call_time: "03:00:00",
    disposition: "Settlement",
  },
  {
    key: 64,
    client_name: "Poonawalla",
    loan_account_no: "PLUPUN0PYT000018588801",
    agent_name: "Dia",
    borrower_name: "Savaliya Mrugank Mansukhbhai",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/PLUPUN0PYT000018588801.mp3",
    call_time: "04:36:00",
    disposition: "P-Paid",
  },
  {
    key: 65,
    client_name: "Poonawalla",
    loan_account_no: "PLUPUN0PFL000017912567",
    agent_name: "Dia",
    borrower_name: "Shakuntala Bai",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/PLUPUN0PFL000017912567.mp3",
    call_time: "01:55:00",
    disposition: "PTP",
  },
  {
    key: 66,
    client_name: "Poonawalla",
    loan_account_no: "PLUPUN0PYT000018756730",
    agent_name: "Dia",
    borrower_name: "UMESH KUMAR",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/PLUPUN0PYT000018756730.mp3",
    call_time: "02:25:00",
    disposition: "PTP",
  },
  {
    key: 67,
    client_name: "Poonawalla",
    loan_account_no: "PLU0011PL_000016720162",
    agent_name: "Unknown",
    borrower_name: "LALMOHAN MANDAL",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/PLU0011PL_000016720162.mp3",
    call_time: "05:22:00",
    disposition: "will pay later",
  },
  {
    key: 68,
    client_name: "Poonawalla",
    loan_account_no: "USE0150001000005018646",
    agent_name: "Utkarsh",
    borrower_name: "RAJ KUMAR",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/USE0150001000005018646.mp3",
    call_time: "07:13:00",
    disposition: "PTP",
  },
  {
    key: 69,
    client_name: "Poonawalla",
    loan_account_no: "USE0297001000005018462",
    agent_name: "Utkarsh",
    borrower_name: "DHANNARAM CHOUTHARAM",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/USE0297001000005018462.mp3",
    call_time: "01:54:00",
    disposition: "PTP",
  },
  {
    key: 70,
    client_name: "Poonawalla",
    loan_account_no: "PLUPUN0PFL000015416273",
    agent_name: "Unknown",
    borrower_name: "VISHNU PRASAD NAGESH",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/PLUPUN0PFL000015416273.mp3",
    call_time: "01:57:00",
    disposition: "P-Paid",
  },
  {
    key: 71,
    client_name: "Poonawalla",
    loan_account_no: "USE0062001000005011065",
    agent_name: "Unknown",
    borrower_name: "MUKNA RAM",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/USE0062001000005011065.mp3",
    call_time: "01:57:00",
    disposition: "PTP",
  },
  {
    key: 72,
    client_name: "L&T",
    loan_account_no: "T03119251122101626",
    agent_name: "Utkarsh",
    borrower_name: "MUKESH SAHNI",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/T03119251122101626.mp3",
    call_time: "04:03:00",
    disposition: "P-Paid",
  },
  {
    key: 73,
    client_name: "L&T",
    loan_account_no: "T07292200724111841",
    agent_name: "Jyoti",
    borrower_name: "ABDUL KARIM",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/T07292200724111841.mp3",
    call_time: "01:34:00",
    disposition: "VEHICLE_ALREADY_SURRENDERED",
  },
  {
    key: 74,
    client_name: "L&T",
    loan_account_no: "TI2477061123051849",
    agent_name: "Unknown",
    borrower_name: "DILDAR HUSSAIN",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/TI2477061123051849.mp3",
    call_time: "00:58:00",
    disposition: "READY_TO_SURRENDER_VEHICLE",
  },
  {
    key: 75,
    client_name: "L&T",
    loan_account_no: "T13047030122033943",
    agent_name: "Hiral",
    borrower_name: "A.BHARATI .",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/T13047030122033943.mp3",
    call_time: "01:30:00",
    disposition: "P-Paid",
  },
  {
    key: 76,
    client_name: "L&T",
    loan_account_no: "T16606100922104805",
    agent_name: "Utkarsh",
    borrower_name: "SANTOSH TANTI",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/T16606100922104805.mp3",
    call_time: "05:25:00",
    disposition: "P-Paid",
  },
  {
    key: 77,
    client_name: "L&T",
    loan_account_no: "T03296281222015104",
    agent_name: "Unknown",
    borrower_name: "MANOJ KUMAR SHARMA",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/T03296281222015104.mp3",
    call_time: "01:57:00",
    disposition: "Settlement",
  },
  {
    key: 78,
    client_name: "L&T",
    loan_account_no: "T03292030724073743",
    agent_name: "Aru",
    borrower_name: "ARJUN SINGH",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/T03292030724073743.mp3",
    call_time: "01:17:00",
    disposition: "RTP",
  },
  {
    key: 79,
    client_name: "L&T",
    loan_account_no: "T00310260322101601",
    agent_name: "Anmol",
    borrower_name: "BIKASH KUMAR BHAKAT",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/T00310260322101601.mp3",
    call_time: "07:50:00",
    disposition: "PTP",
  },
  {
    key: 80,
    client_name: "L&T",
    loan_account_no: "T04081030224044836",
    agent_name: "Unknown",
    borrower_name: "MOHAMMAD AFROZ AFZAL",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/T04081030224044836.mp3",
    call_time: "11:20:00",
    disposition: "READY_TO_SURRENDER_VEHICLE",
  },
  {
    key: 81,
    client_name: "L&T",
    loan_account_no: "T16400210723035413",
    agent_name: "Mahendra",
    borrower_name: "BANTU",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/T16400210723035413.mp3",
    call_time: "03:17:00",
    disposition: "Settlement",
  },
  {
    key: 82,
    client_name: "L&T",
    loan_account_no: "T05139160324020915",
    agent_name: "Gautam",
    borrower_name: "LIYAKAT ALI MOLLA",
    audio_url: "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/T05139160324020915.mp3",
    call_time: "07:26:00",
    disposition: "will pay later",
  },
  {
    "key": 83,
    "client_name": "Wortgage",
    "loan_account_no": "1648702",
    "agent_name": "Utkarsh",
    "borrower_name": "Mridul Das",
    "audio_url": "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/1648702.mp3",
    "call_time": "02:22:00",
    "disposition": "Will pay later"
  },
  {
    "key": 84,
    "client_name": "Wortgage",
    "loan_account_no": "2387179",
    "agent_name": "Utkarsh",
    "borrower_name": "SHANKAR DHANJI VAGHELA",
    "audio_url": "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/2387179.mp3",
    "call_time": "02:09:00",
    "disposition": "DECEASED"
  },
  {
    "key": 85,
    "client_name": "Wortgage",
    "loan_account_no": "1576266",
    "agent_name": "NA",
    "borrower_name": "Tejas Tukaram Takale",
    "audio_url": "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/1576266.mp3",
    "call_time": "09:57:00",
    "disposition": "Settlement"
  },
  {
    "key": 86,
    "client_name": "Wortgage",
    "loan_account_no": "2347571",
    "agent_name": "Isha",
    "borrower_name": "GURMEET KAUR",
    "audio_url": "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/2347571.mp3",
    "call_time": "01:47:00",
    "disposition": "RTP"
  },
  {
    "key": 87,
    "client_name": "Wortgage",
    "loan_account_no": "2192990",
    "agent_name": "NA",
    "borrower_name": "ASHISH KUMAR",
    "audio_url": "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/2192990.mp3",
    "call_time": "01:27:00",
    "disposition": "RTP"
  },
  {
    "key": 88,
    "client_name": "Wortgage",
    "loan_account_no": "2217826",
    "agent_name": "Utkarsh",
    "borrower_name": "Arvind Kumar Yadav",
    "audio_url": "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/2217826.mp3",
    "call_time": "05:08:00",
    "disposition": "PTP"
  },
  {
    "key": 89,
    "client_name": "Wortgage",
    "loan_account_no": "2271695",
    "agent_name": "Utkarsh",
    "borrower_name": "Pintus kumar",
    "audio_url": "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/2271695.mp3",
    "call_time": "05:50:00",
    "disposition": "RTP"
  },
  {
    "key": 90,
    "client_name": "Wortgage",
    "loan_account_no": "2205108",
    "agent_name": "Innama",
    "borrower_name": "JAI PRAKASH SHARMA",
    "audio_url": "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/2205108.mp3",
    "call_time": "02:55:00",
    "disposition": "RTP"
  },
  {
    "key": 91,
    "client_name": "Wortgage",
    "loan_account_no": "2349496",
    "agent_name": "Isha",
    "borrower_name": "SACIN RAJENDRA JADHAV",
    "audio_url": "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/2349496.mp3",
    "call_time": "02:50:00",
    "disposition": "PTP"
  },
  {
    "key": 92,
    "client_name": "Wortgage",
    "loan_account_no": "2200499",
    "agent_name": "NA",
    "borrower_name": "JOGESH SITARAM YADAV",
    "audio_url": "https://contentmedius.s3.ap-south-1.amazonaws.com/rec/2200499.mp3",
    "call_time": "02:33:00",
    "disposition": "Wrong number"
  }
];

export const teleCollectioAsgmtAnswers = [
  {
    key: 1,
    call_opening: "good",
    fir: "good",
    identify_borrower_lender: "good",
    negotiation: "good",
    settlemnent: "good",
  },
  {
    key: 2,
    call_opening: "good",
    fir: "good",
    identify_borrower_lender: "good",
    negotiation: "good",
    settlemnent: "good",
  },
  {
    key: 3,
    call_opening: "good",
    fir: "good",
    identify_borrower_lender: "good",
    negotiation: "good",
    settlemnent: "good",
  },
  {
    key: 4,
    call_opening: "good",
    fir: "good",
    identify_borrower_lender: "good",
    negotiation: "good",
    settlemnent: "good",
  },
  {
    key: 5,
    call_opening: "good",
    fir: "good",
    identify_borrower_lender: "good",
    negotiation: "good",
    settlemnent: "good",
  },
  {
    key: 6,
    call_opening: "good",
    fir: "good",
    identify_borrower_lender: "good",
    negotiation: "good",
    settlemnent: "good",
  },
  {
    key: 7,
    call_opening: "good",
    fir: "good",
    identify_borrower_lender: "good",
    negotiation: "good",
    settlemnent: "good",
  },
  {
    key: 8,
    call_opening: "good",
    fir: "good",
    identify_borrower_lender: "good",
    negotiation: "good",
    settlemnent: "good",
  },
  {
    key: 9,
    call_opening: "good",
    fir: "good",
    identify_borrower_lender: "good",
    negotiation: "good",
    settlemnent: "good",
  },
  {
    key: 10,
    call_opening: "good",
    fir: "good",
    identify_borrower_lender: "good",
    negotiation: "good",
    settlemnent: "good",
  },
  {
    key: 11,
    call_opening: "good",
    fir: "good",
    identify_borrower_lender: "good",
    negotiation: "good",
    settlemnent: "good",
  },
];
