"use client"

import { useState, useEffect } from "react"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import DatePicker from "../../../ui/forms/DatePicker"
import SelectDropdown from "../../../ui/forms/SelectDropdown"

const filterDefaults = {
  search: "",
  batch_no: "",
  month: "",
  year: new Date().getFullYear().toString(), // Default to current year
  date_from: null,
  date_to: null,
  specific_date: null,
}

const monthOptions = [
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
]

// Generate year options for the last 5 years including current year
const generateYearOptions = () => {
  const currentYear = new Date().getFullYear()
  return Array.from({ length: 5 }, (_, i) => {
    const year = currentYear - i
    return { label: year.toString(), value: year.toString() }
  })
}

const yearOptions = generateYearOptions()

function BatchFilter({ show, close, prevFilter, setPrevFilter }) {
  const [filter, setFilter] = useState({ ...filterDefaults })

  // handler
  const handleFilter = (name, val) => setFilter((prev) => ({ ...prev, [name]: val }))

  const clearFilter = () => {
    setFilter({ ...filterDefaults })
    setPrevFilter({ ...filterDefaults })
  }

  const closeFilter = () => {
    close()
  }

  const applyFilter = () => {
    setPrevFilter({ ...filter })
    closeFilter()
  }

  useEffect(() => {
    if (!!show) {
      setFilter({ ...prevFilter })
    }
  }, [show])

  return (
    <Modal size="sm" show={show} onHide={close} backdrop="static">
      <Modal.Header>
        <Modal.Title>Filter</Modal.Title>
      </Modal.Header>
      <Modal.Body onKeyDown={(e) => e.key === "Enter" && applyFilter()}>
        {/* Search Filter */}
        <div className="mb-2 row modal-search">
          <div className="col-sm-12">
            <input
              type="text"
              className="form-control"
              placeholder="Search by file reference name"
              value={filter?.search}
              onChange={(e) => handleFilter("search", e.target.value)}
            />
          </div>
        </div>

        {/* Search by Batch Number */}
        <div className="grey-bg mb-2">
          <div className="mb-2 row">
            <label htmlFor="search_by_batch_number" className="col-md-6 col-form-label pt-2 mt-1">
              Search by Batch Number
            </label>
            <div className="col-md-6">
              <input
                id="search_by_batch_number"
                type="text"
                className="form-control"
                placeholder="Batch No."
                value={filter?.batch_no}
                onChange={(e) => handleFilter("batch_no", e.target.value)}
              />
            </div>
          </div>
          <div className="mb-2 row">
            <label htmlFor="search_by_month" className="col-md-6 col-form-label pt-2 mt-1">
              Month
            </label>
            <div className="col-md-6">
              <SelectDropdown
                placeholder="Select Month"
                options={monthOptions}
                value={filter?.month}
                onChange={(val) => handleFilter("month", val)}
              />
            </div>
          </div>
          <div className="mb-2 row">
            <label htmlFor="search_by_year" className="col-md-6 col-form-label pt-2 mt-1">
              Year
            </label>
            <div className="col-md-6">
              <SelectDropdown
                placeholder="Select Year"
                options={yearOptions}
                value={filter?.year}
                onChange={(val) => handleFilter("year", val)}
              />
            </div>
          </div>
        </div>

        {/* Search By Date */}
        <div className="grey-bg">
          <div className="mb-2 row">
            <label htmlFor="inputState" className="col-md-4 col-form-label">
              Search by Date
            </label>
            <div className="col-md-4 ps-0 datepicker datepicker-small datepicker-small-white">
              <DatePicker
                value={filter?.date_from}
                maxDate={filter?.date_to}
                onChange={(val) => handleFilter("date_from", val)}
                label="From"
              />
            </div>
            <div className="col-md-4 ps-0 datepicker datepicker-small datepicker-small-white">
              <DatePicker
                value={filter?.date_to}
                minDate={filter?.date_from}
                onChange={(val) => handleFilter("date_to", val)}
                label="To"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={clearFilter}>
          Clear Filter
        </Button>
        <Button variant="secondary" onClick={closeFilter}>
          Close
        </Button>
        <Button variant="primary" onClick={applyFilter}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default BatchFilter

