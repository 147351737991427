"use client"

import React, { useCallback, useEffect } from "react"
import PrelitigationLegalNoticesHeader from "../../../../Components/Headers/PrelitigationLegalNoticesHeader"
import { useParams } from "react-router-dom"
import {
  getPreLitiNoticeData,
  getTrackingStatus,
  fetchWhatsappData,
  fetchMailData,
  fetchEmailReply,
  fetchBatchDataReport,
  fetchSmsData,
  downloadMediaUrl,
} from "../../../../API"
import Dropdown from "react-bootstrap/Dropdown"
import { useState } from "react"
import Check from "../../../../Static/RawImages/check.png"
import Close from "../../../../Static/RawImages/close.png"
import Modal from "react-bootstrap/Modal"
import Nav from "react-bootstrap/Nav"
import Tab from "react-bootstrap/Tab"
import Button from "react-bootstrap/Button"
import Arrow from "../../../../Static/RawImages/arrow.png"
import ContentLoader from "../../../../Components/ContentLoader"
import NoRecord from "../../../../Components/NoRecord"
import { commaFormat } from "../../../../Functions/formatNumber"

import AccountsWhatsapp from "../../../../Components/AccountsHistory/AccountsWhatsapp"
import { IoCloseCircle } from "react-icons/io5"
import DeliveryStatusTable from "../../../../Components/Pre-Litigation/DeliveryStatusTable"
import { isEmpty } from "../../../../Functions/isEmpty"
import { MultiSelect } from "react-multi-select-component"
import { issueFir } from "../../../../API/panelApis"
import { toast } from "react-toastify"
import AccountsSms from "../../../../Components/AccountsHistory/AccountsSms"
import useAuthStore from "../../../../store/authStore"
import PostalDeliveryTracking from "../../../Components/Prelitigation/LegalNotices/PostalDeliveryTracking"
import useGeneralStore from "../../../../store/generalStore"
import WhatsappResponseModal from "../../../Components/Prelitigation/LegalNotices/WhatsappResponseModal"
import EmailHistory from "../../../../Components/EmailHistory"
import Pagination from "../../../../Components/Pagination"
import usePagination from "../../../../hooks/usePagination"
import WhatsappMsg from "../../../../Components/WhatsappMsg"
import SmsHistory from "../../../../Components/SmsHistory"

function PreLitigationNotices() {
  const userData = useAuthStore((state) => state.userData)
  const updateFetchedCount = useGeneralStore((state) => state.update_fetched_count)

  const { id } = useParams()
  const [lgShow, setLgShow] = useState(false)
  const [data, setData] = useState(null)
  const [SearchParameter, setSearchParameter] = useState({})
  const [StorePrevFilter, setStorePrevFilter] = useState(null)
  const [HitReload, setHitReload] = useState(false)
  const [ArrayIds, setArrayIds] = useState([])
  const [showIssueFirModal, setShowIssueFirModal] = useState(false)
  const [issueFirFields, setIssueFirFields] = useState({
    communication_mode: [],
    notice_lang: "",
    batch_id: "",
  })
  const [issueFirData, setIssueFirData] = useState("")
  const [allBatches, setAllBatches] = useState([])

  const [selectedIds, setSelectedIds] = useState([])
  const [IsPendingDocuments, setIsPendingDocuments] = useState(false)
  const [trackingDetails, setTrackingDetails] = useState({})
  const [trackingId, setTrackingId] = useState("")
  const [loading, setLoading] = useState(true)

  const [AccountWhatsappData, setAccountWhatsappData] = useState(null)
  const [AccountMailData, setAccountMailData] = useState(null)
  const [mailDataLoading, setMailDataLoading] = useState(true)
  const [IsAccountWhatsapp, setIsAccountWhatsapp] = useState(false)
  const [IsAccountSms, setIsAccountSms] = useState(false)
  const [AccountSmsData, setAccountSmsData] = useState([])
  const [emailShow, setEmailShow] = useState(false)
  const [emailReply, setEmailReply] = useState(null)
  const [reply, setReply] = useState(null)
  const [dataLoading, setDataLoading] = useState(true)
  const [whatsapResponseMobile, setWhatsappResponseMobile] = useState(null)

  const [showEmailHistory, setShowEmailHistory] = useState(false)
  const [showWhatsappHistory, setShowWhatsappHistory] = useState(false)
  const [showSmsHistory, setShowSmsHistory] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState(null)

  const { currentPage, handleTotal, paginationCallBack, paginationProps } = usePagination({ defaultLimit: 20 })

  const handleIssueFirFields = (name, val) => setIssueFirFields((prev) => ({ ...prev, [name]: val }))

  const getData = async () => {
    setDataLoading(true)
    let myParams
    setStorePrevFilter(SearchParameter)
    if (SearchParameter.batch_id) {
      myParams = {
        ...SearchParameter,
        page: currentPage,
        year: SearchParameter.year || new Date().getFullYear().toString(), // Add default year parameter
      }
    } else {
      id
        ? (myParams = {
            ...SearchParameter,
            batch_id: id,
            page: currentPage,
            year: SearchParameter.year || new Date().getFullYear().toString(), // Add default year parameter
          })
        : (myParams = {
            ...SearchParameter,
            page: currentPage,
            year: SearchParameter.year || new Date().getFullYear().toString(), // Add default year parameter
          })
    }
    const res = await getPreLitiNoticeData(myParams)
    if (res && res.status) {
      setData(res.data)
      setArrayIds(
        res.data.map((item, i) => {
          return i + 1
        }),
      )
      handleTotal(Number(res?.total))
      updateFetchedCount(res.total)
    }
    setDataLoading(false)
  }

  useEffect(() => {
    paginationCallBack(getData)
  }, [HitReload, SearchParameter, currentPage])

  const fetchTrackingStatus = useCallback(async () => {
    const res = await getTrackingStatus(trackingId)
    if (res) setTrackingDetails(res?.data)
    else setTrackingDetails({})
    setLoading(false)
  }, [trackingId])

  useEffect(() => {
    !!trackingId ? fetchTrackingStatus() : setTrackingDetails({})
    !!trackingId ? setLoading(true) : setLoading(false)
  }, [trackingId])

  const fetchEmailReplyStatus = useCallback(async () => {
    const res = await fetchEmailReply(emailReply)
    if (res.status) {
      setReply(res?.data?.data?.reply)
      setEmailShow(true)
    }
  }, [emailReply])

  useEffect(() => {
    !!emailReply ? fetchEmailReplyStatus() : setTrackingDetails({})
  }, [emailReply])

  const fetchAccountHistoryData = async (which, mobile_number) => {
    try {
      // if (which === 'call') {
      //     setIsAccountCall(true)
      //     const res = await fetchCallData(mobile_number)

      //     // res.push(
      //     //     { RecordingUrl: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3' }
      //     // )
      //     if (res) {
      //
      //         setAccountCallData(res)
      //     }
      // }

      if (which === "sms") {
        // setIsAccountSms(true);  //FIXME: for email temporary disabled
        const res = await fetchSmsData(mobile_number)
        if (res) {
          setAccountSmsData(res?.data)
        }
      }

      if (which === "whatsapp") {
        // setIsAccountWhatsapp(true); // FIXME: for whatsapp temporary disabled
        const res = await fetchWhatsappData(mobile_number)
        if (res) {
          setAccountWhatsappData(res?.data)
        }
      }

      if (which === "mail") {
        // setIsAccountMail(true); //FIXME: for email temporary disabled
        setMailDataLoading(true)
        const para = { customer_mobile_number: mobile_number }
        const res = await fetchMailData(para)

        if (res) {
          setAccountMailData(res?.data)
        }
        setMailDataLoading(false)
      }

      // if (which === 'dispositions') {
      //     setIsAccountDispositions(true)
      //     const res = await fetchDispositionData(mobile_number)
      //     if (res) {
      //         setAccountDispositionsData(res)
      //     }

      // }
    } catch (error) {
      // if (which === 'call') {
      //     setAccountSmsData([])
      // }

      if (which === "sms") {
        setAccountSmsData([])
      }

      if (which === "whatsapp") {
        setAccountWhatsappData([])
      }

      if (which === "mail") {
        setAccountMailData([])
      }

      // if (which === 'dispositions') {
      //     setAccountDispositionsData([])
      // }
      console.log(error)
    }
  }

  const fetchDataPop = async () => {
    const res2 = await fetchBatchDataReport(userData?.client_id, "fir")
    if (res2 && !!res2.batch_instances?.length) {
      setAllBatches(res2.batch_instances?.map((item) => item?.batch_id))
    } else setAllBatches([])
  }

  const handleIssueFir = async () => {
    const body = {
      client_id: userData?.client_id,
      notice_id: issueFirData?.notice_id,
      communication_mode: issueFirFields.communication_mode?.map(({ value }) => value),
      complaint_type: "",
      complaint_language: issueFirFields.notice_lang,
      fir_batch_id: issueFirFields.batch_id,
    }
    const res = await issueFir(body)

    if (res && res.status) toast.info(res.message)
    else toast.error(res.message)

    setIssueFirFields({
      communication_mode: [],
      notice_lang: "",
      batch_id: "",
    })
  }

  return (
    <>
      <PrelitigationLegalNoticesHeader
        ArrayIds={ArrayIds}
        setSelectedIds={setSelectedIds}
        setSearchParameter={setSearchParameter}
        StorePrevFilter={StorePrevFilter}
        HitReload={HitReload}
        setHitReload={setHitReload}
      />
      <hr className="mt-4 mb-4" />

      {dataLoading && <ContentLoader />}
      {!dataLoading && !data?.length && <NoRecord />}
      {!dataLoading && !!data?.length && (
        <>
          {data?.map((val, index) => (
            <div key={index} className="row d-flex justify-between legal-notices-listing">
              <div className="col-md-4">
                <div className="d-flex align-start">
                  <div>
                    <p className="text-black mb-2 tx-16">
                      <strong>{val?.customer_name + " (" + val?.lan_number + ")"}</strong>
                    </p>
                    <p>
                      Batch No. -{" "}
                      <span className="text-black">{val?.batch?.client_batch_id + " - " + val?.batch?.batch_name}</span>
                    </p>
                    <hr />
                    {!!val?.prd_grp && val?.prd_grp !== "None" && (
                      <p>
                        Product - <span className="text-black">{val?.prd_grp}</span>
                        <br />
                      </p>
                    )}
                    <p>
                      <>
                        Amount - <span className="text-black">{`Rs. ${commaFormat(val?.od_future_amount)}`}</span>
                      </>
                      <br />
                      {!!val?.bkt_grp && val?.bkt_grp !== "None" && (
                        <>
                          Due Since - <span className="text-black">{val?.bkt_grp} Days</span>
                        </>
                      )}
                    </p>
                    <hr />
                    {!!val?.batch.notice_type && val?.batch.notice_type !== "None" && (
                      <p>
                        Notice Type - <b>{val?.batch.notice_type}</b>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4 sample">
                <DeliveryStatusTable
                  data={val}
                  communicationModes={val?.batch?.communication_mode || []}
                  setTrackingId={setTrackingId}
                  setIsPendingDocuments={setIsPendingDocuments}
                  setEmailReply={setEmailReply}
                  setWhatsappResponseMobile={setWhatsappResponseMobile}
                />
              </div>
              <div className="col-md-4">
                <div className="action-box">
                  <label htmlFor="dropdown-basic" className="col-form-label mb-2">
                    Action
                  </label>
                  <Dropdown className="dropdown-s2">
                    <Dropdown.Toggle id="dropdown-basic" className="w-100 text-start select-style">
                      Select
                      {/* <span>
                                                        <FiChevronDown />
                                                    </span> */}
                      <img src={Arrow || "/placeholder.svg"} alt="Sort" />
                    </Dropdown.Toggle>
                    {(!isEmpty(val?.pod_file_url) || !!val?.notice_type?.length) && (
                      <Dropdown.Menu className="select-style-dropdown">
                        {!isEmpty(val?.pod_file_url) && (
                          <Dropdown.Item href={downloadMediaUrl(val.pod_file_url)} target="_blank">
                            Download POD
                          </Dropdown.Item>
                        )}

                        {!!val?.notice_type?.length &&
                          val?.notice_type?.map((noticeType, noticeTypeIndex) => (
                            <React.Fragment key={noticeTypeIndex}>
                              {!isEmpty(noticeType?.url) && (
                                <Dropdown.Item href={downloadMediaUrl(noticeType.url)} target="_blank">
                                  View {noticeType?.label}
                                </Dropdown.Item>
                              )}
                            </React.Fragment>
                          ))}

                        {/* {!isEmpty(val?.customer) && (
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                fetchDataPop();
                                                                setIssueFirData(val);
                                                                setShowIssueFirModal(true);
                                                            }}
                                                        >
                                                            Issue FIR
                                                        </Dropdown.Item>
                                                    )} */}
                      </Dropdown.Menu>
                    )}
                  </Dropdown>

                  {/* Delivery Status Hidden for INDUSIND */}
                  {userData?.client_id !== 56 &&
                    (!!val?.batch?.communication_mode?.includes("whatsapp") ||
                      !!val?.batch?.communication_mode?.includes("sms") ||
                      !!val?.batch?.communication_mode?.includes("email")) && (
                      <>
                        <hr className="mt-4 mb-4" />
                        <p>Delivery Status</p>
                        <ul className="d-flex align-items-end history-box">
                          {!!val?.batch?.communication_mode?.includes("sms") && (
                            <li>
                              <p
                                className="al-popup-button cursor-pointer"
                                onClick={() => {
                                  fetchAccountHistoryData("sms", val?.mobile)
                                  setSelectedCustomer(val)
                                  setShowSmsHistory(true)
                                }}
                              >
                                <div>
                                  <svg fill="#0A7AFF" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M11.52 0A11.52 11.52 0 0 0 1.44 17.074L0 23.04l5.822-1.507A11.52 11.52 0 1 0 11.52 0zm0 22.08a10.56 10.56 0 0 1-5.38-1.474l-.174-.105-4.608 1.18 1.1-4.742-.101-.173a10.56 10.56 0 1 1 9.163 5.314z"
                                      fillRule="nonzero"
                                    />
                                  </svg>

                                  {!!val?.sms_delivered && (
                                    <>
                                      {val?.sms_delivered_status === "Delivered" ? (
                                        <div className="action-status status-check">
                                          <img src={Check || "/placeholder.svg"} alt="Check" />
                                        </div>
                                      ) : (
                                        <div className="action-status status-uncheck">
                                          <img src={Close || "/placeholder.svg"} alt="Sort" />
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                                SMS
                              </p>
                            </li>
                          )}
                          {!!val?.batch?.communication_mode?.includes("whatsapp") && (
                            <li>
                              <p
                                className="al-popup-button cursor-pointer"
                                id="whatsapp"
                                onClick={() => {
                                  setSelectedCustomer(val)
                                  setShowWhatsappHistory(true)
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <div>
                                  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="#0A7AFF" fillRule="nonzero">
                                      <path d="M11.52 0A11.52 11.52 0 0 0 1.44 17.074L0 23.04l5.822-1.507A11.52 11.52 0 1 0 11.52 0zm0 22.08a10.56 10.56 0 0 1-5.38-1.474l-.174-.105-4.608 1.18 1.1-4.742-.101-.173a10.56 10.56 0 1 1 9.163 5.314z" />
                                      <path d="M7.632 13.239a14.745 14.745 0 0 0 4.905 3.842c.717.34 1.676.743 2.744.812.067.003.13.006.196.006.717 0 1.293-.248 1.763-.758.003-.002.008-.008.011-.014.167-.202.357-.383.556-.576.136-.13.274-.265.406-.403.614-.64.614-1.452-.005-2.07l-1.731-1.732c-.294-.305-.645-.466-1.014-.466s-.723.161-1.025.463l-1.031 1.031a5.48 5.48 0 0 0-.286-.15 3.561 3.561 0 0 1-.316-.172c-.94-.596-1.792-1.374-2.607-2.373-.412-.522-.688-.96-.881-1.406.27-.245.524-.5.769-.751.086-.09.176-.179.265-.268.31-.311.478-.671.478-1.037 0-.366-.164-.726-.478-1.037l-.858-.858c-.101-.1-.196-.199-.294-.3-.19-.195-.389-.397-.585-.578C8.318 4.153 7.97 4 7.6 4c-.365 0-.717.153-1.025.446L5.498 5.524a2.213 2.213 0 0 0-.66 1.416c-.054.689.073 1.42.4 2.304.505 1.368 1.265 2.639 2.394 3.995zm-2.09-6.238c.034-.383.18-.703.457-.98l1.072-1.07c.167-.162.35-.245.53-.245.175 0 .354.083.518.25.193.179.374.366.57.565l.3.305.858.858c.178.179.27.36.27.539 0 .178-.092.36-.27.538l-.268.271c-.268.27-.518.527-.795.772l-.014.014c-.24.24-.202.467-.144.64.003.008.006.014.008.023.222.532.53 1.04 1.011 1.644.864 1.066 1.774 1.892 2.777 2.529.123.08.256.144.38.207.115.058.222.112.317.173l.031.017a.602.602 0 0 0 .28.072.61.61 0 0 0 .429-.196l1.077-1.077c.167-.167.348-.256.527-.256.219 0 .397.135.51.256l1.736 1.734c.346.346.343.72-.008 1.086-.121.13-.248.253-.383.383-.202.196-.412.397-.602.625-.332.357-.726.524-1.236.524-.049 0-.1-.003-.15-.006-.944-.06-1.823-.429-2.482-.743a14.008 14.008 0 0 1-4.669-3.657c-1.074-1.294-1.797-2.497-2.275-3.788-.296-.792-.409-1.428-.363-2.007z" />
                                    </g>
                                  </svg>

                                  {!!val?.whatsapp_delivered && (
                                    <>
                                      {val?.whatsapp_delivered_status === "Invalid Phone Number" ? (
                                        <div className="action-status status-uncheck">
                                          <img src={Close || "/placeholder.svg"} alt="Sort" />
                                        </div>
                                      ) : val?.whatsapp_delivered_status === "SENT" ? (
                                        <div
                                          className="action-status status-sent"
                                          style={{ backgroundColor: "#556c86" }}
                                        >
                                          <img src={Check || "/placeholder.svg"} alt="Sent" />
                                        </div>
                                      ) : val?.whatsapp_delivered_status === "DELIVERED" ? (
                                        <div
                                          className="action-status status-delivered"
                                          style={{ backgroundColor: "#0081fe" }}
                                        >
                                          <img src={Check || "/placeholder.svg"} alt="Delivered" />
                                        </div>
                                      ) : val?.whatsapp_delivered_status === "REPLIED" ? (
                                        <div className="action-status status-check">
                                          <img src={Check || "/placeholder.svg"} alt="Replied" />
                                        </div>
                                      ) : (
                                        <div className="action-status status-check">
                                          <img src={Check || "/placeholder.svg"} alt="Check" />
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                                WhatsApp
                              </p>
                            </li>
                          )}
                          {!!val?.batch?.communication_mode?.includes("email") && (
                            <li>
                              <p
                                className="al-popup-button cursor-pointer"
                                onClick={() => {
                                  setSelectedCustomer(val)
                                  setShowEmailHistory(true)
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <div>
                                  <svg width="24" height="18" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="#0A7AFF" fillRule="nonzero">
                                      <path d="M0 0v12.96a4.32 4.32 0 0 0 4.32 4.32h14.4a4.32 4.32 0 0 0 4.32-4.32V0H0zm22.08 12.96a3.36 3.36 0 0 1-3.36 3.36H4.32a3.36 3.36 0 0 1-3.36-3.36v-12h21.12v12z" />
                                      <path d="m20.39 3.682-.643-.71-8.227 7.42-8.18-7.411-.642.71 8.822 7.997z" />
                                    </g>
                                  </svg>

                                  {!!val?.email_delivered && (
                                    <>
                                      {["Invalid Email ID", "No Email ID", "Bounce"]?.includes(
                                        val.email_delivered_status,
                                      ) ? (
                                        <div className="action-status status-uncheck">
                                          <img src={Close || "/placeholder.svg"} alt="close" />
                                        </div>
                                      ) : (
                                        <div className="action-status status-check">
                                          <img src={Check || "/placeholder.svg"} alt="Check" />
                                        </div>
                                      )}
                                      {/* {val.email_delivered_status === "Delivered" ||
                                                                        val.email_delivered_status === "Opened" ||
                                                                        val.email_delivered_status === "Replied" ||
                                                                        val.email_delivered_status === "Responded" ? (
                                                                            <div className="action-status status-check">
                                                                                <img src={Check || "/placeholder.svg"} alt="Check" />
                                                                            </div>
                                                                        ) : (
                                                                            <div className="action-status status-uncheck">
                                                                                <img src={Close || "/placeholder.svg"} alt="close" />
                                                                            </div>
                                                                        )} */}
                                    </>
                                  )}
                                </div>
                                Email
                              </p>
                            </li>
                          )}
                        </ul>
                      </>
                    )}
                </div>
              </div>
              <hr className="mt-4 mb-4" />
            </div>
          ))}

          <Pagination {...paginationProps} />

          <div
            className="al-popup"
            //Whatsapp
            style={
              IsAccountWhatsapp
                ? {
                    transform: "translateX(0)",
                    boxShadow: "0 2px 11px 7px rgba(0, 0, 0, 0.21)",
                  }
                : {
                    transform: "translateX(100%)",
                    boxShadow: "none",
                  }
            }
          >
            <div
              className="al-close"
              onClick={() => {
                setIsAccountWhatsapp(false)
                setAccountWhatsappData(null)
              }}
            >
              <IoCloseCircle fill="#999999" size={50} />
            </div>
            <div className="al-head">
              <h2>WhatsApp ({AccountWhatsappData ? AccountWhatsappData.length : 0})</h2>
            </div>
            <AccountsWhatsapp AccountWhatsappData={AccountWhatsappData} />
          </div>

          <div
            className="al-popup"
            //sms
            style={
              IsAccountSms
                ? {
                    transform: "translateX(0)",
                    boxShadow: "0 2px 11px 7px rgba(0, 0, 0, 0.21)",
                  }
                : {
                    transform: "translateX(100%)",
                    boxShadow: "none",
                  }
            }
          >
            <div
              className="al-close"
              onClick={() => {
                setIsAccountSms(false)
                setAccountSmsData(null)
              }}
            >
              <IoCloseCircle fill="#999999" size={50} />
            </div>
            <div className="al-head">
              <h2>SMS Records ({AccountSmsData ? AccountSmsData?.length : 0})</h2>
            </div>
            <AccountsSms AccountSmsData={AccountSmsData} />
          </div>
        </>
      )}

      {/**/}
      <Modal
        show={lgShow}
        onHide={() => setLgShow(false)}
        dialogClassName="modal-large"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">Reply Received from Rituraj Srivastava</Modal.Title>
        </Modal.Header>
        <Modal.Body className="tab-m-body">
          <div className="upload-batch-popup">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <div className="row">
                <div className="col-lg-3 com-left ps-0 pe-0">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first" className="d-flex align-center">
                        <div className="comm-icon me-4 ps-2">
                          <svg width="29" height="29" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M22.512 18.186a3.726 3.726 0 0 0-2.304.27l-2.586 1.116a25.428 25.428 0 0 1-4.584-3.636 24.84 24.84 0 0 1-3.6-4.65l.846-2.328a3.78 3.78 0 0 0 .222-2.238L8.784 0 0 1.266l.036.534a28.974 28.974 0 0 0 8.322 18.6 28.566 28.566 0 0 0 18.936 8.4h.57l.936-9.144-6.288-1.47zm4.278 9.36a27.33 27.33 0 0 1-17.58-7.95A27.78 27.78 0 0 1 1.266 2.292l6.6-.954 1.428 5.646c.102.52.043 1.057-.168 1.542L8.1 11.4l.15.246a26.4 26.4 0 0 0 3.936 5.154 27.132 27.132 0 0 0 5.058 3.972l.27.174 3.192-1.374a2.49 2.49 0 0 1 1.554-.192l5.25 1.2-.72 6.966z"
                              fill="#505050"
                              fillRule="nonzero"
                            />
                          </svg>
                        </div>
                        Postal
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second" className="d-flex align-center">
                        <div className="comm-icon me-4 ps-2">
                          <svg width="29" height="29" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M14.4 0A14.4 14.4 0 0 0 1.8 21.342L0 28.8l7.278-1.884A14.4 14.4 0 1 0 14.4 0zm0 27.6a13.2 13.2 0 0 1-6.726-1.842l-.216-.132-5.76 1.476 1.374-5.928-.126-.216A13.2 13.2 0 1 1 14.4 27.6z"
                              fill="#505050"
                              fillRule="nonzero"
                            />
                          </svg>
                        </div>
                        SMS
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third" className="d-flex align-center">
                        <div className="comm-icon me-4 ps-2">
                          <svg width="29" height="22" xmlns="http://www.w3.org/2000/svg">
                            <g fill="#505050" fillRule="nonzero">
                              <path d="M0 0v16.2a5.4 5.4 0 0 0 5.4 5.4h18a5.4 5.4 0 0 0 5.4-5.4V0H0zm27.6 16.2a4.2 4.2 0 0 1-4.2 4.2h-18a4.2 4.2 0 0 1-4.2-4.2v-15h26.4v15z" />
                              <path d="m25.488 4.602-.804-.888L14.4 12.99 4.176 3.726l-.804.888L14.4 14.61z" />
                            </g>
                          </svg>
                        </div>
                        Email
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fourth" className="d-flex align-center">
                        <div className="comm-icon me-4 ps-2">
                          <svg width="29" height="29" xmlns="http://www.w3.org/2000/svg">
                            <g fill="#505050" fillRule="nonzero">
                              <path d="M14.4 0A14.4 14.4 0 0 0 1.8 21.342L0 28.8l7.278-1.884A14.4 14.4 0 1 0 14.4 0zm0 27.6a13.2 13.2 0 0 1-6.726-1.842l-.216-.132-5.76 1.476 1.374-5.928-.126-.216A13.2 13.2 0 1 1 14.4 27.6z" />
                              <path d="M9.54 16.549a18.432 18.432 0 0 0 6.131 4.802c.896.425 2.095.929 3.43 1.015.084.004.163.008.246.008.896 0 1.616-.31 2.203-.947a.079.079 0 0 0 .014-.018c.209-.252.447-.479.695-.72.17-.162.342-.331.508-.504.766-.8.766-1.815-.008-2.589l-2.163-2.163c-.367-.382-.807-.583-1.267-.583-.461 0-.904.201-1.282.58l-1.289 1.288c-.119-.068-.241-.13-.356-.187a4.452 4.452 0 0 1-.396-.216c-1.174-.745-2.24-1.717-3.258-2.967-.515-.651-.86-1.198-1.102-1.756.339-.306.655-.627.961-.94.108-.112.22-.223.332-.335.388-.389.597-.839.597-1.296 0-.457-.205-.907-.597-1.296l-1.073-1.073c-.126-.126-.245-.248-.367-.374a19.746 19.746 0 0 0-.731-.724C10.397 5.191 9.96 5 9.5 5c-.458 0-.897.19-1.282.558L6.873 6.904a2.766 2.766 0 0 0-.825 1.772c-.068.86.09 1.774.5 2.88.63 1.71 1.581 3.297 2.992 4.993zM6.927 8.75c.043-.479.226-.878.572-1.224l1.34-1.339c.208-.202.438-.306.662-.306.22 0 .442.104.648.313.24.223.468.457.712.706l.375.381 1.073 1.073c.223.223.338.45.338.673 0 .224-.115.45-.338.674-.112.111-.224.226-.335.338-.335.338-.648.659-.994.965l-.018.018c-.299.299-.252.583-.18.799l.011.029c.277.666.662 1.3 1.264 2.055 1.08 1.332 2.217 2.366 3.47 3.161.155.101.32.18.475.26.144.071.277.14.396.215.015.008.025.015.04.022.119.061.234.09.349.09.288 0 .475-.184.536-.245l1.347-1.346c.209-.209.435-.32.659-.32.273 0 .496.169.637.32l2.17 2.167c.433.432.429.9-.01 1.357-.151.162-.31.317-.479.479-.252.245-.515.497-.752.781-.414.447-.908.655-1.545.655-.061 0-.126-.003-.187-.007-1.18-.075-2.279-.536-3.103-.929a17.51 17.51 0 0 1-5.836-4.572c-1.343-1.616-2.246-3.12-2.844-4.734-.37-.99-.511-1.785-.453-2.509z" />
                            </g>
                          </svg>
                        </div>
                        WhatsApp
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="col-lg-9">
                  <Tab.Content className="text-start py-4 px-4">
                    <Tab.Pane eventKey="first">
                      <form>
                        <div className="mb-2 row">
                          <div className="col-md-12">
                            <label htmlFor="Agent" className="col-md-5 col-form-label"></label>
                            {/* <img src={Jsa || "/placeholder.svg"} alt="JSA" /> */}
                            <object
                              data="http://www.africau.edu/images/default/sample.pdf"
                              type="application/pdf"
                              width="100%"
                              height="600"
                            >
                              alt : <a href="http://www.africau.edu/images/default/sample.pdf">test.pdf</a>
                            </object>
                          </div>
                        </div>
                      </form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <form>
                        <div className="mb-2 row">
                          <div className="col-md-12">
                            <label htmlFor="Agent" className="col-md-5 col-form-label">
                              SMS
                            </label>
                          </div>
                        </div>
                      </form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <form>
                        <div className="mb-2 row">
                          <div className="col-md-12">
                            <label htmlFor="Agent" className="col-md-5 col-form-label">
                              Email
                            </label>
                          </div>
                        </div>
                      </form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                      <form>
                        <div className="mb-2 row">
                          <div className="col-md-12">
                            <label htmlFor="Agent" className="col-md-5 col-form-label">
                              WhatsApp
                            </label>
                          </div>
                        </div>
                      </form>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </Modal.Body>
        <Modal.Footer className="custom-footer">
          <div className="row">
            <div className="col-lg-6">
              <Button variant="outline-primary">Download</Button>
              <Button variant="outline-primary">Email</Button>
            </div>
            <div className="col-lg-6 text-end">
              <Button variant="primary">Add Notes For Reply</Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setLgShow(false)
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {/**/}

      <Modal
        show={emailShow}
        dialogClassName="modal-large"
        onHide={() => {
          setEmailShow(false)
          setEmailReply(null)
        }}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title>Email Reply</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="my-4 mx-1 row">
              <div className="col-md-12 text-center">
                <div dangerouslySetInnerHTML={{ __html: reply }}></div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setEmailShow(false)
              setEmailReply(null)
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <PostalDeliveryTracking
        show={IsPendingDocuments}
        setShow={setIsPendingDocuments}
        loading={loading}
        data={trackingDetails}
      />

      {/* Issue FIR Modal */}
      <Modal show={showIssueFirModal} size="sm" backdrop="static" onHide={() => setShowIssueFirModal(false)}>
        <Modal.Header>
          <Modal.Title>Issue Legal Fir</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="mt-2">
            <div className=" mb-2 row"></div>
            <div className="row mb-2 ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="Batch" className="col-form-label">
                      Communication Mode
                    </label>
                  </div>
                  <div className="col-md-6">
                    <MultiSelect
                      options={[
                        { label: "Postal", value: "postal" },
                        { label: "Whatsapp", value: "whatsapp" },
                        { label: "Email", value: "email" },
                        { label: "SMS", value: "sms" },
                      ]}
                      value={issueFirFields.communication_mode}
                      onChange={(val) => handleIssueFirFields("communication_mode", val)}
                      labelledBy="Select"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-2 row">
              <div className="col-md-6">
                <label htmlFor="Batch" className="col-form-label">
                  Notice Language
                </label>
              </div>
              <div className="col-md-6">
                <select
                  className="form-control form-select"
                  value={issueFirFields.notice_lang}
                  onChange={(e) => handleIssueFirFields("notice_lang", e.target.value)}
                >
                  <option value="">Select Language</option>
                  <option value="english">English</option>
                  <option value="hindi">Hindi</option>
                  <option value="marathi">Marathi</option>
                  <option value="tamil">Tamil</option>
                </select>
              </div>
            </div>
            <div className=" mb-2 row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="select_batch_ids" className="col-form-label">
                      Batch Id
                    </label>
                  </div>
                  <div className="col-md-6">
                    <select
                      id="select_batch_ids"
                      className="form-select"
                      value={issueFirFields.batch_id}
                      onChange={(e) => handleIssueFirFields("batch_id", e.target.value)}
                    >
                      <option value="">Select</option>
                      {!!allBatches?.length &&
                        allBatches?.map((val, index) => (
                          <option value={val} key={index}>
                            {val}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setIssueFirFields({
                communication_mode: [],
                notice_lang: "",
                batch_id: "",
              })
              setShowIssueFirModal(false)
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleIssueFir()
              setShowIssueFirModal(false)
            }}
          >
            SEND
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Whatsapp response modal */}
      <WhatsappResponseModal mobile={whatsapResponseMobile} setMobile={setWhatsappResponseMobile} />

      {/* New Whatsapp Reponse Modal */}
      <div style={{ display: "none" }} className="whatsapp-popup">
        <div className="popup-row">
          <div className="popup-head">
            <h4 className="popup-title">WhatsApp Response from Priya Ranjan</h4>
          </div>
          <div className="popup-content">
            <div className="content-scroll">
              <div className="chat-msg-box">
                <div className="box-row">
                  <div className="chatbox">
                    <p>Hi Vijay, please tell me</p>
                  </div>
                  <span className="chattime">01:18 PM</span>
                </div>
              </div>
              <div className="chat-msg-box">
                <div className="box-row">
                  <div className="chatbox">
                    <p>Hi Vijay, please tell me</p>
                  </div>
                  <span className="chattime">01:18 PM</span>
                </div>
              </div>
              <div className="chat-msg-box">
                <div className="box-row">
                  <div className="chatbox">
                    <p>
                      Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a
                      typeface without relying on meaningful content. Lorem ipsum is a placeholder text commonly used to
                      demonstrate the visual form of a document or a typeface without relying on meaningful content.
                    </p>
                    <p>
                      Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a
                      typeface without relying on meaningful content.
                    </p>
                  </div>
                  <span className="chattime">01:18 PM</span>
                </div>
              </div>
              <div className="chat-msg-box">
                <div className="box-row">
                  <div className="chatbox">
                    <p>
                      Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a
                      typeface without relying on meaningful content. Lorem ipsum is a placeholder text commonly used to
                      demonstrate the visual form of a document or a typeface without relying on meaningful content.
                    </p>
                    <p>
                      Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a
                      typeface without relying on meaningful content.
                    </p>
                  </div>
                  <span className="chattime">01:18 PM</span>
                </div>
              </div>
              <div className="chat-msg-box">
                <div className="box-row">
                  <div className="chatbox">
                    <p>
                      Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a
                      typeface without relying on meaningful content. Lorem ipsum is a placeholder text commonly used to
                      demonstrate the visual form of a document or a typeface without relying on meaningful content.
                    </p>
                    <p>
                      Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a
                      typeface without relying on meaningful content.
                    </p>
                  </div>
                  <span className="chattime">01:18 PM</span>
                </div>
              </div>
            </div>
          </div>
          <div className="popup-footer">
            <div className="popup-btn">
              <a className="close-btn site-btn" href="">
                Close
              </a>
              <a className="primary-btn site-btn" href="">
                View Full Conversation
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Email History */}
      <EmailHistory show={showEmailHistory} setShow={setShowEmailHistory} customerId={selectedCustomer?.customer_id} />

      {/* Whatsapp History */}
      <WhatsappMsg
        show={showWhatsappHistory}
        setShow={setShowWhatsappHistory}
        name={selectedCustomer?.customer_name}
        mobileNo={selectedCustomer?.mobile}
        isCandidate={false}
        withSendMessage={false}
      />

      {/* Sms History */}
      <SmsHistory show={showSmsHistory} setShow={setShowSmsHistory} customerMobileNo={selectedCustomer?.mobile} />
    </>
  )
}
export default PreLitigationNotices