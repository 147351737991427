import useBorrowerInfo from "./store";
import useGeneralStore from "../../../store/generalStore";
import { BsChevronLeft } from "react-icons/bs";
import Profile from "./components/profile";
import LegalAction from "./components/legal-action";
import Addresses from "./components/addresses";
import { maskString } from "../../../Functions/mask";
import useAuthStore from "../../../store/authStore";
import { isEmpty } from "../../../Functions/isEmpty";
import CallButton from "../../Components/CallButton";
import AddDisposition from "./components/add-disposition";
import OtherActions from "./components/other-actions";
import Analysis from "./components/analysis";
import LoanInfo from "./components/loan-info";
import AmountPaid from "../../Components/CustomerDetails/AmountPaid";
import { downloadMediaUrl } from "../../../API";
import WhatsappResponse from "./components/analysis/whatsapp-response";
import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { FaInfoCircle } from "react-icons/fa";
import AccordionSeeFullScript from "./components/see-full-script";
import { fetchAccountData } from "../../../API/agentApis";
export default function BorrowerInfo() {
  const [showWhatsappResponse, setshowWhatsappResponse] = useState(false);
  const isOpen = useBorrowerInfo((state) => state.isOpen);
  const isSidebarOpen = useGeneralStore((state) => state.is_sidebar_open);
  const userData = useAuthStore((state) => state.userData);
  const customerId = useBorrowerInfo((state) => state.customerId);

  const close = useBorrowerInfo((state) => state.closeBorrowerInfo);
  const data = useBorrowerInfo((state) => state.data);
  const loading = useBorrowerInfo((state) => state.isLoading);
  const [showFullScript, setShowFullScript] = useState(false);
  const [customData, setCustomData] = useState(null);
  const borrowerDetails = data?.data;

  const fetchData = async () => {
    if (!customerId) {
      return;
    }
    const res = await fetchAccountData({ customer_id: customerId });

    if (res) {
      setCustomData(res);
    }
  };

  useEffect(() => {
    if (!!customerId) {
      fetchData();
    }
  }, [customerId]);

  const accordionData = [
    {
      title: "Call Opening",
      subItems: [
        {
          title: "Identify Borrower & State Purpose",
          content: `Am I Speaking to ${data?.data?.customer_name}? This is ${userData?.full_name} from KS Legal. You have defaulted on your loan with ${data?.client_name}. Your case has now been assigned to us for legal action.`,
        },
      ],
    },
    {
      title: "No Grace Period",
      subItems: [
        {
          title: "Pay in 24 Hours",
          content: `You have not paid since the last ___ months. You have to pay today before 5 pm to stop further legal action. No further grace period will be given.`,
        },
        {
          title: "No Further Extension",
          content: `We understand you had financial issues, which is why the bank cooperated for so long. Now the matter has gone into legal. No more time will be given. You have to pay now.`,
        },
        {
          title: "Arrange from Family & Friends",
          content: `You have to arrange funds from family & friends and pay immediately. Delaying payment is no more an option for you. Pay before 5 pm today.`,
        },
      ],
    },
    {
      title: "Legal Consequences",
      subItems: [
        {
          title: "Appoint Lawyer - Reply to Notice",
          content: `You have not responded to any of our legal notices. Have you appointed a lawyer to handle your case?`,
        },
        {
          title: "Conciliation Hearing - ₹30K Penalty",
          content: `A conciliation legal hearing is scheduled against you this Saturday. Attend it with your lawyer. If you fail to attend, a ₹30,000 legal penalty will be imposed & an order will be passed against you.`,
        },
        {
          title: "CIBIL Score - Will Never Get Loan",
          content: `Your CIBIL score will be severely impacted, making it impossible for you to get any loan ever, especially when you or your family will need it, like a home loan or medical emergency loan. This will also affect your reputation.`,
        },
      ],
    },
    {
      title: "Home & Office Visit",
      subItems: [
        {
          title: "Home Visit & Legal Notice",
          content: `Collection executives will visit your home with a legal notice. This is your address, right?: ${
            customData?.all_addresses?.current_address || ""
          } .`,
        },
        {
          title: "Office Visit & Legal Notice",
          content: `Collection executives will also visit your office, and legal notices will be sent to your office address.`,
        },
      ],
    },
    {
      title: "Willful Defaulter Consequences",
      subItems: [
        {
          title: "All Assets Known to Government",
          content: `The government has records of all your assets — stocks, mutual funds, vehicles, bank accounts, properties — which can be seized under willful default proceedings.`,
        },
        {
          title: "Reporting to RBI - Willful Defaulter",
          content: `If you fail to pay, we will report this to the RBI as a willful defaulter case. This will result in credit blacklisting, a total ban on all future loans & an inquiry will be initiated into your assets`,
        },
        {
          title: "If Salaried - Garnishee Order",
          content: `A garnishee order will be passed against you & it will be sent to your employer, which will result in your salary being put on hold.`,
        },
        {
          title: "If Business - Bank A/c Freeze",
          content: `We will also intimate your banks to freeze all bank accounts associated with you, as this is now a case of willful default.`,
        },
      ],
    },
    ...(data?.data?.product_name?.toLowerCase() === "tw" || data?.data?.product_name?.toLowerCase() === "two wheeler"
      ? [
          {
            title: "Vehicle Repossession",
            subItems: [
              {
                title: "Surrender Vehicle – If Unable to Repay",
                content: `If you’re unable to repay, you are instructed to surrender the vehicle immediately. Non-compliance will result in forced seizure and escalation under the SARFAESI Act.`,
              },
              {
                title: "VPN – Vehicle Repossession Notice",
                content: `I’m taking on record that a Vehicle Repossession Notice (VPN) has been issued under the SARFAESI Act. If payment is not made, repossession will proceed from anytime anywhere without further notice.`,
              },
              {
                title: "SARFAESI Act - Section 13(4)",
                content: `Under SARFAESI Act, Section 13(4), the bank can seize your vehicle without court approval if you fail to repay after the notice period, as it is a secured asset for loan recovery.`,
              },
              {
                title: "Open Repossession",
                content: `If you continue to default, collection executives can stop you on any road & seize your vehicle anytime, causing you huge public embarrassment, even in front of your family.`,
              },
              {
                title: "If Vehicle is Surrendered",
                content: `I’m taking on record that you claim the vehicle has been surrendered. Please confirm the exact date of surrender and whether it was surrendered to a showroom or an authorized agency.`,
              },
              {
                title: "If Vehicle is Lost",
                content: `I’m taking on record that you claim the vehicle is lost. Please confirm whether you had valid insurance at the time and whether a police complaint for the loss was filed. Without both, legal action will proceed.`,
              },
            ],
          },
        ]
      : []),
    ...(data?.client_id !== 51
      ? [
          {
            title: "Police Action Consequences",
            subItems: [
              { title: "FIR & Arrest Warrant", content: `Failure to cooperate will lead to an FIR and arrest warrant.` },
              { title: "NACH Bounce - Section 25", content: `Non-payment under NACH can lead to an arrest warrant under PASA Act, 2007.` },
              { title: "New Law - Bharatiya Nyaya Sanhita", content: `New strict laws allow banks to seize assets of loan defaulters.` },
              {
                title: "Action Under Section 316",
                content: `Under Section 316, legal action can be taken against you for misappropriation of funds, which is punishable by 10 years of imprisonment.`,
              },
              {
                title: "Action Under Section 318",
                content: `Section 318 allows the government to act against defaulters involved in fraudulent practices or fund mismanagement, punishable by 7 years of imprisonment and a fine`,
              },
              {
                title: "Action Under Section 323",
                content: `If you continue to hide your assets, Section 323 allows for legal prosecution and imprisonment up to 3 years.`,
              },
            ],
          },
        ]
      : []),
    ...(data?.data?.actual_disposition === "SETL"
      ? [
          {
            title: "Settlement Final Chance",
            subItems: [
              {
                title: "Final OTS Offer",
                content: `This is your last chance for a One-Time Settlement (OTS). If you miss this opportunity, the offer will be withdrawn permanently & you will have to pay the total amount with legal penalties.`,
              },
              {
                title: "No Waiver Negotiation",
                content: `The bank is already giving 100% waiver on interest, penalties, and a good part of the principal. There will be no further negotiation. If you don't cooperate now, we will pass a conciliation order of willful default.`,
              },
              {
                title: "Payment in 24 Hours",
                content: `Do you have the arrangement of funds? The OTS offer is valid for 24 hours only. After acceptance, if you fail to pay in 24 hours, the offer will be withdrawn permanently & you will be prosecuted as a willful defaulter.`,
              },
            ],
          },
        ]
      : []),
    ...(data?.data?.actual_disposition === "BPTP"
      ? [
          {
            title: "Broken Promise To Pay / Settlement Letter Issued, NOT Paid ",
            subItems: [
              {
                title: "Broken PTP Is Taken on Record",
                content: `During the legal proceedings, you had previously agreed to pay Rs. ___ on {DATE}. You've made several false promises to pay and later did not pay. All of this is on record.`,
              },
              {
                title: "Settlement Letter Issued as requested",
                content: `During the legal proceedings, you agreed on record to settle. You requested a settlement, you got the settlement letter. Pay now or lose this option permanently, and legal action will proceed.`,
              },
              {
                title: "Settlement Letter will expire tomorrow",
                content: `The payment deadline has passed. The settlement offer expires in 24 hours. After that, the settlement expires permanently, and there will be no further chance to resolve this.`,
              },
              {
                title: "Willful Defaulter Consequences",
                content: `If you do not pay within 24 hours, we will report this to the RBI & this will also go to court. You will be prosecuted as a willful defaulter, and your assets will be seized.`,
              },
              {
                title: "Police Action Consequences",
                content: `If you don't cooperate & pay within 24 hours, we will file an FIR. Arrest warrant & prosecution orders will be issued under BNS Section 316, 318, 323 and PASA Section 25.`,
              },
            ],
          },
        ]
      : []),
          {
            title: "Refuse To Pay - Responses",
            subItems: [
              {
                title: "Medical / Job Loss / Business Failure",
                content: `Your medical reason, job loss, or business failure does not exempt you from loan repayment. You still manage your daily expenses — the government knows every rupee you spend. Prioritize loan repayment and focus on life without legal worries.`,
              },
              {
                title: "Fraud / Dispute – FIR Proof Required",
                content: `If this is a fraud or dispute, show the police complaint you have filed. Without an FIR, stop using it as an excuse for loan default. No FIR, no defense.`,
              },
              {
                title: "Loan Not Taken – Borrower on Record",
                content: `Even if you claim someone else took the loan, the records show you as the borrower. This default stays in your name, and you must cooperate immediately.`,
              },
              {
                title: "Wrong Number – ID Proof Required",
                content: `Claiming it’s a wrong number isn’t enough. Since this is a legal matter now, provide ID proof to confirm your identity. You can mask important details if you want.`,
              },
              {
                title: " Speak to Lawyer",
                content: `Ask your lawyer to respond immediately to the legal notice sent to you. Even your lawyer will advise you to repay your loan. You can’t hide behind a lawyer anymore. Pay now or face legal escalation.`,
              },
              {
                title: "Court Case Ongoing",
                content: `Legal proceedings don’t stop banks from using all other means to recover. The bank has full rights to seize assets, pursue repossession, and escalate legally with additional cases while your current case continues.`,
              },
              {
                title: "Wants Statement – Contact Company",
                content: `Contact the company for loan statements or queries. This is not customer support. Our role is to talk to you about the legal case, not account servicing. Also, you have not appointed us as your lawyer.`,
              },
              {
                title: "Deceased – Death Certificate Required",
                content: `We extend our condolences for your loss. Since this is a legal matter, we need the death certificate for records. We will close this matter.`,
              },
              {
                title: "Fake or Real Notice – It’s Genuine",
                content: `No one has time for fake notices. You know you’re a loan defaulter. This is a genuine legal notice. Appoint a lawyer and have it responded to. Ignore it, and legal action proceeds.`,
              },
              {
                title: "Speak to Bank  – Legal Case Assigned",
                content: `The bank has already declared you as a defaulter and handed this case for legal action. Pay now or provide a written NOC from the bank within 24 hours to stop the legal action.`,
              },
              {
                title: "Harassment Claim – Loan Still Due",
                content: `If you’re facing harassment, file a police complaint. But this doesn’t erase your loan obligation. Settle now to end collections visits and avoid further collection executives interaction.`,
              },
            ],
          },
    {
      title: "Call Closing",
      subItems: [
        {
          title: "Promise To Pay",
          content: `How will you make the payment? Okay, I’m taking on record that you will pay Rs. ___ on {DATE} before 5 PM. Failing this, we will initiate all legal actions without further notice.`,
        },
        {
          title: "Refuse To Pay",
          content: `I’m taking on record your outright refusal to pay. This will now lead to immediate legal action, including asset seizure and court proceedings. You will now face severe legal consequences with no further warnings`,
        },
        {
          title: "Settlement Amount Accepted",
          content: `I’m taking on record your acceptance of the settlement for Rs. _____, payable on {DATE}. Failure to adhere will result in withdrawal of the offer and continuation of legal action.`,
        },
        {
          title: "Settlement Amount Renegotiation",
          content: `I’m taking on record your request for renegotiation. We will try, but if you don't receive confirmation within 24 hours, consider your request rejected and legal action will proceed.`,
        },
        {
          title: "Part or Full Paid",
          content: `I’m taking on record your payment of Rs. _____ on {DATE}. Share payment proof or screenshot via WhatsApp or email where you received the KS Legal notice. Without proof, legal action will continue.`,
        },
      ],
    },
  ];

  let CONSEQUENCES = 1;
  return (
    <div
      style={{
        inset: "0",
        zIndex: "50",
        paddingTop: "72px",
        paddingLeft: isSidebarOpen ? "178px" : "62px",
        transition: "all ease 0.5s",
        ...(isOpen
          ? { transform: "translateX(0)", opacity: "1", pointerEvents: "auto" }
          : { transform: "translateX(100%)", opacity: "0", pointerEvents: "none" }),
      }}
      className="bg-white position-fixed overflow-x-hidden overflow-y-auto"
    >
      {showWhatsappResponse && <WhatsappResponse show={true} />}
      <div className="position-sticky top-0 py-2 px-3 bg-white">
        <button onClick={close} className="btn btn-primary ps-2 pe-4 d-flex align-items-center">
          <BsChevronLeft /> Back
        </button>
      </div>

      {loading && <p className="text-center p-5 text-muted">Loading...</p>}

      {!loading && (
        <div className="row p-3">
          <div className="col-8 row">
            <div className="col-6 pe-4">
              <div className="d-flex gap-3 mb-2">
                <div className="d-flex flex-column align-items-center gap-1">
                  <div
                    style={{
                      width: "3rem",
                      height: "3rem",
                      background: "#eee",
                    }}
                    className="rounded-circle border overflow-hidden"
                  >
                    {!!data?.client_logo && <img className="w-100 h-100 object-fit-cover" src={data?.client_logo} alt={data?.client_name ?? "Bank"} />}
                  </div>
                  <p style={{ width: "120px", whiteSpace: "wrap", overflow: "hidden", textOverflow: "ellipsis" }} className="m-0 tx-12 fw-bold">
                    {data?.client_name ?? ""}
                  </p>
                </div>
                <div>
                  <p className="mb-0 fw-bold text-black tx-16">{borrowerDetails?.customer_name ?? ""}</p>
                  <p className="mb-0"> {!!userData?.remove_masking ? borrowerDetails?.loan_account_no : maskString(borrowerDetails?.loan_account_no)}</p>
                </div>
              </div>

              <div>
                {/* {!isEmpty(borrowerDetails?.current_bucket) && <p className="mb-0">Bucket : {borrowerDetails?.current_bucket}</p>} */}
                {!isEmpty(borrowerDetails?.case_allocated_date) && <p className="mb-0">Case Allocated Date - {borrowerDetails?.case_allocated_date}</p>}
                {!isEmpty(borrowerDetails?.co_applicant_name) && <p className="mb-0">Co Applicant Name - {borrowerDetails?.co_applicant_name}</p>}
                {!isEmpty(borrowerDetails?.co_applicant_name2) && <p className="mb-0">Co Applicant Name 2 - {borrowerDetails?.co_applicant_name2}</p>}
                <AmountPaid data={borrowerDetails} type={false} />
                {!isEmpty(borrowerDetails?.settlement_letter_url) && (
                  <p className="mb-0">
                    Settlement Letter -{" "}
                    <a target="_blank" rel="noreferrer" href={downloadMediaUrl(borrowerDetails?.settlement_letter_url)}>
                      Download
                    </a>
                  </p>
                )}
                {!isEmpty(borrowerDetails?.letter_issue_date) && <p className="mb-0">Setl Letter Issued on - {borrowerDetails?.letter_issue_date}</p>}
                {/* {!isEmpty(borrowerDetails?.email) && <p className="mb-0">Email - {borrowerDetails?.email}</p>} */}
              </div>

              <div
                style={{ borderTop: "1px dashed #ddd", borderBottom: "1px dashed #ddd" }}
                className="d-flex align-items-center justify-content-between gap-3 my-3 py-1"
              >
                <Profile />
                {/* <LegalAction /> */}
                <Addresses />
              </div>

              {/* <div className="d-flex align-items-center justify-content-between gap-2 my-3 py-1 col-md-12">
                <div className="gap-3 mb-3  col-md-6">
                  <AmountPaid data={borrowerDetails} />
                </div>
                {isEmpty(borrowerDetails?.settlement_letter_url) && (
                  <div className="gap-3 mb-3 col-md-6">
                    <span className="item-cl-left text-16 cl-gray-01">Settlement Letter</span>
                    <span className="item-cl-right text-16 cl-black">
                      <a target="_blank" rel="noreferrer" href={downloadMediaUrl(borrowerDetails?.settlement_letter_url)}>
                        Download
                      </a>
                    </span>
                  </div>
                )}

                {!isEmpty(borrowerDetails?.letter_issue_date) && (
                  <div className="gap-3 mb-3  col-md-6">
                    <span className="item-cl-left text-16 cl-gray-01">Setl Letter Issued on</span>
                    <span className="item-cl-right text-16 cl-black"> {borrowerDetails?.letter_issue_date}</span>
                  </div>
                )}
              </div> */}

              <div className="row">
                <div className="col-6 d-flex gap-1 align-items-center flex-column">
                  <CallButton customer_mobile_number={borrowerDetails?.customer_mobile_number ?? ""} styles={{ width: "100%" }} />
                  <em className="tx-12">{data?.last_call_disposition ?? ""}</em>
                </div>
                <div className="col-6 d-flex gap-1 align-items-center flex-column">
                  <button className="w-100 btn btn-primary" onClick={() => setshowWhatsappResponse(!showWhatsappResponse)}>
                    Whatsapp
                  </button>
                  <em className="tx-12">{data?.last_whatsapp_status ?? ""}</em>
                </div>
              </div>
              <div className="mt-3">
                <span className="font-bold" style={{ fontSize: "medium" }}>
                  CONSEQUENCES
                </span>
                <div className="d-flex gap-1 align-items-start flex-column w-full pt-1">
                  {/* Consequence Item with Tooltip */}
                  <div style={{ color: "green", display: "flex", justifyContent: "space-between", width: "75%" }}>
                    <span>
                      {CONSEQUENCES} - Legal Consequences <FaInfoCircle data-tip data-for="legalConsequences" style={{ cursor: "pointer" }} />
                    </span>
                    <span>✅</span>
                  </div>

                  <div style={{ color: "green", display: "flex", justifyContent: "space-between", width: "75%" }}>
                    <span>
                      {(CONSEQUENCES += 1)} - Home & Office Visit <FaInfoCircle data-tip data-for="homeOfficeVisit" style={{ cursor: "pointer" }} />
                    </span>
                    <span>✅</span>
                  </div>

                  <div style={{ color: "green", display: "flex", justifyContent: "space-between", width: "75%" }}>
                    <span>
                      {(CONSEQUENCES += 1)} - Willful Defaulter <FaInfoCircle data-tip data-for="willfulDefaulter" style={{ cursor: "pointer" }} />
                    </span>
                    <span>✅</span>
                  </div>

                  <div
                    style={{
                      color: data?.client_id === 51 || data?.client_id === 63 ? "red" : "green",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "75%",
                    }}
                  >
                    <span>
                      {(CONSEQUENCES += 1)} - Police Action <FaInfoCircle data-tip data-for="policeAction" style={{ cursor: "pointer" }} />
                    </span>
                    {data?.client_id === 51 || data?.client_id === 63 ? <span>❌</span> : <span>✅</span>}
                  </div>

                  {data?.data.pre_approved_sattlement_amount > 0 && (
                    <div style={{ color: "green", display: "flex", justifyContent: "space-between", width: "75%" }}>
                      <span>
                        {(CONSEQUENCES += 1)} - Settlement Final Chance <FaInfoCircle data-tip data-for="settlementFinalChance" style={{ cursor: "pointer" }} />
                      </span>
                      <span>✅</span>
                    </div>
                  )}
                  {data?.data?.settlement_letter_url && data?.data?.paid === false && (
                    <div style={{ color: "green", display: "flex", justifyContent: "space-between", width: "75%" }}>
                      <span>
                        {(CONSEQUENCES += 1)} - Letter Issued, Not Paid <FaInfoCircle data-tip data-for="letterIssued" style={{ cursor: "pointer" }} />
                      </span>
                      <span>✅</span>
                    </div>
                  )}

                  {data?.data?.actual_disposition === "BPTP" && (
                    <div style={{ color: "green", display: "flex", justifyContent: "space-between", width: "75%" }}>
                      <span>
                        {(CONSEQUENCES += 1)} - Broken PTP <FaInfoCircle data-tip data-for="brokenPTP" style={{ cursor: "pointer" }} />
                      </span>
                      <span>✅</span>
                    </div>
                  )}
                  {data?.data?.product_name?.toLowerCase() === "tw" ||
                    (data?.data?.product_name?.toLowerCase() === "two wheeler" && (
                      <div style={{ color: "green", display: "flex", justifyContent: "space-between", width: "75%" }}>
                        <span>
                          {(CONSEQUENCES += 1)} - Vehicle Repossession <FaInfoCircle data-tip data-for="bikerepossession" style={{ cursor: "pointer" }} />
                        </span>
                        <span>✅</span>
                      </div>
                    ))}

                  {/* Tooltips */}
                  <ReactTooltip effect="solid" place="right" className="py-2 px-3" id="legalConsequences">
                    <div style={{ fontWeight: "normal" }}>
                      <strong>Legal Consequences</strong>
                      <ul>
                        <li>Pay today - No Grace Period</li>
                        <li>Appoint Lawyer - Reply to Notice</li>
                        <li>Conciliation Hearing - 30k Penalty</li>
                        <li>CIBIL Score - Will Never Get Loan</li>
                      </ul>
                    </div>
                  </ReactTooltip>
                  <ReactTooltip effect="solid" place="right" className="py-2 px-3" id="homeOfficeVisit">
                    <div style={{ fontWeight: "normal" }}>
                      <strong>Home & Office Visit</strong>
                      <ul>
                        <li>Home Visit & Legal Notice</li>
                        <li>Office Visit & Legal Notice</li>
                      </ul>
                    </div>
                  </ReactTooltip>
                  <ReactTooltip effect="solid" place="right" className="py-2 px-3" id="willfulDefaulter">
                    <div style={{ fontWeight: "normal" }}>
                      <strong>Willful Defaulter</strong>
                      <ul>
                        <li>All Assets Known to Government</li>
                        <li>Reporting to RBI - Willful Defaulter</li>
                        <li>If Salaried - Garnishee Order</li>
                        <li>If Business - Bank A/c Freeze</li>
                      </ul>
                    </div>
                  </ReactTooltip>
                  <ReactTooltip effect="solid" place="right" className="py-2 px-3" id="policeAction">
                    <div style={{ fontWeight: "normal" }}>
                      <strong>Police Action</strong>
                      <ul>
                        {data?.client_id === 51 ? (
                          <li>Do not speak about Police Action</li>
                        ) : (
                          <>
                            <li>FIR & Arrest Warrant</li>
                            <li>NACH Bounce - Section 25</li>
                            <li>New Law - Bharatiya Nyaya Sanhita</li>
                            <li>Action Under Section 316</li>
                            <li>Action Under Section 318</li>
                            <li>Action Under Section 323</li>
                          </>
                        )}
                      </ul>
                    </div>
                  </ReactTooltip>
                  <ReactTooltip effect="solid" place="right" className="py-2 px-3" id="settlementFinalChance">
                    <div style={{ fontWeight: "normal" }}>
                      <strong>Settlement Final Chance</strong>
                      <ul>
                        <li>OTS - One Time Settlement Offer</li>
                        <li>No Waiver Negotiation</li>
                        <li>Pay settlement in 24 Hours</li>
                        <li>Else pay full amount</li>
                      </ul>
                    </div>
                  </ReactTooltip>
                  <ReactTooltip effect="solid" place="right" className="py-2 px-3" id="letterIssued">
                    <div style={{ fontWeight: "normal" }}>
                      <strong>Letter Issued, Not Paid</strong>
                      <ul>
                        <li>Everything Is Taken on Record</li>
                        <li>Whatsapp Read & IP Address Tracked</li>
                        <li>Deadline for Payment Has Crossed</li>
                        <li>Offer Will Be Withdrawn Permanently</li>
                      </ul>
                    </div>
                  </ReactTooltip>
                  <ReactTooltip effect="solid" place="right" className="py-2 px-3" id="brokenPTP">
                    <div style={{ fontWeight: "normal" }}>
                      <strong>Broken PTP</strong>
                      <ul>
                        <li>Everything Is Taken on Record</li>
                        <li>Deadline for Payment Has Crossed</li>
                        <li>Whatsapp Read & IP Address Tracked</li>
                      </ul>
                    </div>
                  </ReactTooltip>
                  <ReactTooltip effect="solid" place="right" className="py-2 px-3" id="bikerepossession">
                    <div style={{ fontWeight: "normal" }}>
                      <strong>Vehicle Repossession</strong>
                      <ul>
                        <li>Surrender Vehicle, if unable to repay.</li>
                        <li>Vehicle Repossession Notice issued.</li>
                        <li>SARFAESI Act, Sections 13 & 14.</li>
                        <li>If already surrendered, to whom & when.</li>
                      </ul>
                    </div>
                  </ReactTooltip>
                </div>
                <div
                  className="script-faq"
                  onClick={() => setShowFullScript(!showFullScript)}
                  style={{
                    fontSize: "medium",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    height: "100px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <a href="" target="_blank" rel="noreferrer" onClick={(e) => e.preventDefault()}>
                    SEE FULL SCRIPT
                  </a>
                </div>
              </div>
            </div>
            <div style={{ borderLeft: "1px dashed #ddd" }} className="col-6 pl-3">
              <LoanInfo />
            </div>

            <div className="col-12 py-4" />

            <Analysis info={data} />
          </div>

          <div className="col-4 bg-light rounded p-3">
            <div className="bg-white h-100 rounded p-3 d-flex flex-column">
              <AddDisposition />
              <div className="h-0 w-full my-4" style={{ borderTop: "1px dashed #ddd" }} />
              <OtherActions />
            </div>
          </div>
          {showFullScript && <AccordionSeeFullScript accordionData={accordionData} onClose={() => setShowFullScript(false)} show={showFullScript} />}
        </div>
      )}
    </div>
  );
}
