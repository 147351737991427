import { lazy } from "react";

const pages = [
  // CAMPAGIN
  "/campaigns/manage",
  "/campaigns/summary",
  "/campaigns/campaign-status",
  "/campaigns/digital-report",
  "/campaigns/ivr-restart-summary",
  "/campaigns/notice-campaign-report",

  // SETTLEMENTS
  "/settlements/manage-settlements",
  "/settlements/online-settlements",
  "/settlements/daily-report",
  "/settlements/report",
  "/settlements/settlement-analysis",
  "/settlements/setl-stage-analysis",
  "/settlements/ACM-RCM",

  // CALL QUALITY
  "/call-quality/month-report",
  "/call-quality/day-report",
  "/call-quality/audit-calls",
  "/call-quality/auditor-report",
  "/call-quality/agent-report",

  // DAILY OPS
  "/daily-ops/call-quality",
  "/daily-ops/dnd-list",
  "/daily-ops/admin-apis",
  "/daily-ops/exophone-management",
  "/daily-ops/exophone-change-report",
  "/daily-ops/file-upload-report",
  "/daily-ops/gupshup-mappings",
  "/daily-ops/gupshup-mapping-summary",
  "/daily-ops/meta-mappings",
  "/daily-ops/search-fir",
  "/daily-ops/payment-receipts",
  "/daily-ops/settlement-report",
  "/daily-ops/chat-panel-report",

  //Chat Panel
  "/chat-panel/chat",
  "/chat-panel/alt-chat",

  // AGENTS
  "/agents/manage-agents",
  "/agents/agent-report",
  "/agents/call-disposition-analysis",
  "/agents/summary_report",
  "/agents/details_report",

  // HR
  "/hr/candidates",
  "/hr/applicants",
  "/hr/manage-jobs",
  "/hr/generate-offer-letter",
  "/hr/manage-offer-letter",

  // ANALYTICS
  "/analytics/perfomance-summary",
  "/analytics/alt-number-report",

  // TEMPLATES
  "/templates/legal-notices",
  "/templates/sms",
  "/templates/whatsapp",
  "/templates/email",
  "/templates/ivr",
  "/templates/manage-category",
  "/templates/add-variable",
  "/templates/wati-accounts-management",
  "/templates/gupshup-templates",
  "/templates/meta-templates",

  // CLIENTS
  "/clients/manage-clients",
  "/clients/process",
  "/clients/features-settings",
  "/clients/borrower-info-mapping",
  "/clients/client-poc",

  // COLLECTION PERFORMANCE
  "/collection-perfomance/leads",
  "/collection-perfomance/collection-report",
  "/collection-perfomance/recovery-rate",
  "/collection-perfomance/batch-report",
  "/collection-perfomance/legal-calling-mis",

  // OTHERS
  "/others/openai-prompts",
  "/others/openai-analysis",
  "/others/generate-retention-data",
  "/others/auto-campaign-mapping",
  "/others/dashboard",
  "/others/user-module",
  "/others/cron-management",

  // WORK IN PROGRESS
  "/wip/helpline-number-report",
  "/wip/borrower-report",
  "/wip/manage-mindue",
  "/wip/conciliation-joining",
  "/wip/payment-review",
  "/wip/response-analysis",
  "/wip/letter-aging-summary",
  "/wip/settlement-letter-analysis",
  "/wip/allocation-analysis",
  "/wip/excluded-list",
  "/wip/exotel-campaign-report",
  "/wip/fir-working-state",
  "/wip/monthly-allocation-analysis",
  "/wip/todays-activity",
  "/wip/monthly-collector-report",
  "/wip/urgent-daily-campaign",
  "/wip/axis-data-count",
  "/wip/outgoing-call-summary",
  "/wip/client-email",

  // INVOICE
  "/invoice/generate-invoice",
  "/invoice/manage-invoice",
  "/invoice/client-setting",
  "/invoice/email-template",
  "/invoice/invoice-formats",
];

const routes = pages.map((path) => ({ path: `/admin${path}`, component: lazy(() => import(`./Pages${path}`)) }));

export default routes;
