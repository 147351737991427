import React from "react";
import { useNetworkStatus } from "../Hooks/useNetworkStatus";
import useGeneralStore from "../../store/generalStore";

const NetworkStatus = () => {
  const { isOffline, isSlow } = useNetworkStatus();
  const showAlert = useGeneralStore((state) => state.open_alert);

  if (!isOffline && !isSlow) return null; // Hide notification when internet is stable

  return (
    <div>
      {isOffline
        ? showAlert({ variant: "danger", msg: "⚠️ You are offline. Please check your internet connection!" })
        : showAlert({ variant: "warning", msg: "⚠️ Your internet speed is slow. Some features may not work properly." })}
    </div>
  );
};

export default NetworkStatus;
