import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle } from "react-icons/io";
import { BsChevronDown } from "react-icons/bs";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsInfoCircle } from "react-icons/bs";

const AccordionSeeFullScript = ({ onClose, accordionData, show }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const [openSubIndex, setOpenSubIndex] = useState(null);

  const toggleItem = (index) => {
    setOpenIndex(openIndex === index ? null : index);
    setOpenSubIndex(null); // Close sub-items when main item changes
  };

  const toggleSubItem = (index) => {
    setOpenSubIndex(openSubIndex === index ? null : index);
  };

  return (
    <>
      <Modal show={show} onHide={() => onClose()} top>
        <Modal.Header
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Modal.Title>📜 Full Call Script</Modal.Title>
          <button
            type="button"
            style={{
              opacity: "0.4",
              color: "#000",
              fontSize: "24px",
              lineHeight: "0",
            }}
            onClick={() => onClose()}
            className="btn p-0"
            aria-label="Close"
          >
            <IoMdCloseCircle />
          </button>
        </Modal.Header>

        <Modal.Body
          style={{ maxHeight: "800px", overflowY: "scroll", padding: "10px" }}
        >
          {accordionData.map((item, index) => (
            <div key={index}>
              {/* Main Accordion Items */}
              <div
                style={{
                  padding: "15px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  borderBottom: "1px solid #ddd",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
                onClick={() => toggleItem(index)}
              >
                {item.title}
                <BsChevronDown
                  style={{
                    transform:
                      openIndex === index ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "0.3s",
                  }}
                />
              </div>

              {/* Sub-Items */}
              {openIndex === index && item.subItems && (
                <div
                  style={{ backgroundColor: "#f9f9f9", padding: "10px 20px" }}
                >
                  {item.subItems.map((subItem, subIndex) => (
                    <div key={subIndex}>
                      {/* Sub-Accordion Header */}
                      <div
                        style={{
                          padding: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                          borderBottom: "1px solid #ddd",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        {subItem.title}
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip
                              className="mytooltip"
                              id={`tooltip-${index}-${subIndex}`}
                              style={{
                                maxWidth: "400px",
                                whiteSpace: "normal",
                                textAlign: "left",
                              }}
                            >
                              {subItem.content}
                            </Tooltip>
                          }
                        >
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                              color: "#007bff",
                            }}
                          >
                            <BsInfoCircle
                              style={{
                                fontSize: "18px",
                                color: "#007bff",
                                cursor: "pointer",
                              }}
                            />
                          </span>
                        </OverlayTrigger>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AccordionSeeFullScript;
