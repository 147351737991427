import React, { useEffect, useState } from 'react';
import Kreditbee from './ClientSteps/Kreditbee';
import Moneyview from './ClientSteps/Moneyview';
import Poonawalla from './ClientSteps/Poonawalla';
import Smartcoin from './ClientSteps/Smartcoin';
import Werize from './ClientSteps/Werize';
import useGeneralStore from '../../../store/generalStore';
import { getchangeCall_AuditStatus } from '../../../API/agentApis';
import ContentLoader from './../../../Components/ContentLoader';
import LT from './ClientSteps/L&T';
import ThankYou from './ClientSteps/ThankYou';

// Simple logger utility
const logger = {
  info: (message, data = {}) => console.log(`[INFO] ${message}`, data),
  error: (message, error = {}) => console.error(`[ERROR] ${message}`, error),
  warn: (message, data = {}) => console.warn(`[WARN] ${message}`, data)
};

// Simple Loader Component
const Loader = () => (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    flexDirection: 'column'
  }}>
    <div style={{
      border: '4px solid #f3f3f3',
      borderTop: '4px solid #3498db',
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      animation: 'spin 1s linear infinite'
    }}></div>
    <p style={{ marginTop: '10px' }}>Loading...</p>
    <style>{`
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `}</style>
  </div>
);

const CallAudit_RedirectPage = () => {
  const audit_Call_step_count = useGeneralStore((state) => state.audit_Call_step_count);
  const setAuditCallStepCount = useGeneralStore((state) => state.setAuditCallStepCount);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch audit status with error handling
  const fetchAuditStatus = async () => {
    try {
      setIsLoading(true);
      setError(null);
      logger.info('Fetching audit call status...');
      const data = await getchangeCall_AuditStatus();
      
      if (!data) {
        throw new Error('No data received from audit status API');
      }

      if (data.result === undefined) {
        throw new Error('Invalid response format: result field missing');
      }

      logger.info('Successfully fetched audit status', { stepCount: data.result });
      setAuditCallStepCount(data.result);
    } catch (error) {
      logger.error('Failed to fetch audit call status', {
        message: error.message,
        stack: error.stack
      });
      setError('Failed to load audit status. Please try again.');
      setAuditCallStepCount(1); // Fallback to step 1
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    logger.info('Component mounted, initiating audit status fetch');
    fetchAuditStatus();
    
    return () => {
      logger.info('Component unmounted');
    };
  }, []); // Empty dependency array as it only runs on mount

  // Render component based on step count
  const renderStepContent = () => {
    if (isLoading) {
      return <ContentLoader/>;
    }

    if (error) {
      return (
        <div style={{ textAlign: 'center', padding: '20px', color: 'red' }}>
          {error}
          <button 
            onClick={fetchAuditStatus}
            style={{ marginTop: '10px', padding: '5px 10px' }}
          >
            Retry
          </button>
        </div>
      );
    }

    try {
      switch (audit_Call_step_count) {
        case 1:
          logger.info('Rendering Kreditbee component');
          return <Kreditbee />;
        case 2:
          logger.info('Rendering Moneyview component');
          return <Moneyview />;
        case 3:
          logger.info('Rendering Poonawalla component');
          return <Poonawalla />;
        case 4:
          logger.info('Rendering Smartcoin component');
          return <Smartcoin />;
        case 5:
          logger.info('Rendering Werize component');
          return <Werize />;
          case 6:
            logger.info('Rendering L&T component');
            return <LT />;
          case 7:
            logger.info('Rendering Thankyou component');
            return <ThankYou />;
        default:
          logger.warn('Invalid audit step count, falling back to Kreditbee', {
            audit_Call_step_count
          });
          return <Kreditbee />;
      }
    } catch (error) {
      logger.error('Error rendering step content', {
        message: error.message,
        stepCount: audit_Call_step_count
      });
      return (
        <div style={{ textAlign: 'center', padding: '20px', color: 'red' }}>
          An error occurred while loading the content.
          <button 
            onClick={fetchAuditStatus}
            style={{ marginTop: '10px', padding: '5px 10px' }}
          >
            Retry
          </button>
        </div>
      );
    }
  };

  return (
    <>
      {renderStepContent()}
    </>
  );
};

export default CallAudit_RedirectPage;