import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { agentLogout, logout } from "../../../API/commonApis";
import NotificationModal from "./NotificationModal";
import useAuthStore from "../../../store/authStore";
import useGeneralStore from "../../../store/generalStore";
import UserImg from "../../../Static/RawImages/user.png";
import ScriptFaq from "../ScriptFaq";
import GlobalSearch from "./GlobalSearch";

function AgentHeader() {
    const removeUser = useAuthStore((state) => state.removeUser);
    const isSidebarOpen = useGeneralStore((state) => state.is_sidebar_open);

    const [urlPointer, setUrlPointer] = useState("");

    const location = useLocation();

    function getUrlPointer() {
        let { pathname } = location;
        if (pathname === "/agent/calls/imp-leads") return "IMP Leads";
        else if (pathname === "/agent/calls/ptp-leads") return "PTP Leads";
        else if (pathname === "/agent/calls/additional-leads") return "Additional Leads";
        else if (pathname === "/agent/calls/pending-dispositions") return <span style={{ fontSize: "1.5rem" }}>Pending Dispositions</span>;
        else if (pathname === "/agent/whatsapp-login") return "Whatsapp Login";
        else if (pathname === "/agent/calls/new/imp-leads") return "NEW IMP Leads";
        else if (pathname === "/agent/calls/new/additional") return "NEW Additional Leads";
        else if (pathname === "/agent/calls/new/ptp-leads") return "PTP Leads";
        else if (pathname === "/agent/calls/new/broken-ptp") return "Broken-PTP Leads";
        else if (pathname === "/agent/calls/new/pending-disp") return "Pending Disposition";
        else if (pathname === "/agent/calls/settlement-leads") return "Settlement";
        else if (pathname === "/agent/my-perfomance") return "My Performance";
        else if (pathname?.indexOf("/agent/calls/customer_details") > -1) return "Customer Details";
        else return "Agent Panel";
    }

    useEffect(() => {
        setUrlPointer(getUrlPointer());
    }, [location]);

    const logoutfxn = async () => {
        await agentLogout();
        removeUser();
    };

    return (
        <>
            <div style={{ width: "100%", paddingTop: "72px", position: "relative", zIndex: "100" }}>
                <header
                    className="d-flex align-center header justify-between"
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        paddingLeft: isSidebarOpen ? "178px" : "62px",
                        width: "100%",
                        transition: "all ease 0.5s",
                        zIndex: "99",
                    }}
                >
                    <div className="d-flex align-items-center header-left mb-lg-0 mb-2">
                        {urlPointer && (
                            <>
                                <h1> {urlPointer} </h1>
                            </>
                        )}
                    </div>

                    <div className="d-flex align-center header-right">
                        {/* <ScriptFaq /> */}

                        <span className="right-hmenu">
                            <GlobalSearch />
                        </span>

                        <div className="right-hmenu">
                            <NotificationModal />
                        </div>

                        <div className="d-flex align-items-end justify-content-center flex-column gap-1">
                            <Dropdown>
                                <Dropdown.Toggle>
                                    <img src={UserImg} alt="User" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => logoutfxn()}>Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            {/* <Dropdown>
                                <Dropdown.Toggle>
                                    <span
                                        style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            marginRight: "0",
                                        }}
                                    >
                                        {userData?.full_name ? userData?.full_name : ""}
                                        <RiArrowDropDownLine></RiArrowDropDownLine>
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => logoutfxn()}>Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown> */}

                            {/* Panel Time */}
                            {/* <p className="m-0 d-flex align-items-center pe-4" style={{ fontSize: "13px" }}>
                                <svg width="24" height="23" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="#1A1A1A" fillRule="evenodd" opacity=".627">
                                        <path d="M12.206 18.902a7.703 7.703 0 0 1-7.704-7.7c0-4.254 3.45-7.702 7.704-7.702a7.703 7.703 0 0 1 7.705 7.701c0 4.253-3.45 7.701-7.705 7.701zm0-1.4a6.302 6.302 0 0 0 6.304-6.3c0-3.48-2.822-6.301-6.304-6.301a6.302 6.302 0 0 0-6.303 6.3c0 3.48 2.822 6.301 6.303 6.301z" />
                                        <path d="M14.803 12.807a.7.7 0 1 1-.99.99l-2.102-2.1a.7.7 0 0 1-.205-.496v-4.2a.7.7 0 0 1 1.4 0v3.91l1.897 1.896z" />
                                    </g>
                                </svg>
                                <span>
                                    {parseInt(panelTime / (60 * 60)) || 0} hrs {parseInt(panelTime / 60) % 60 || 0} m
                                </span>
                            </p> */}
                        </div>
                    </div>
                </header>
            </div>
        </>
    );
}

export default AgentHeader;
