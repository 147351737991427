"use client"

import { useState, useCallback, useEffect } from "react"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import { fetchProducts } from "../../../API"
import DatePicker from "../../../ui/forms/DatePicker"
import SelectDropdown from "../../../ui/forms/SelectDropdown"
import useAuthStore from "../../../store/authStore"

const searchByModes = { DATE_BETWEEN: "date_between", SPECIFIC_DATE: "specific" }

const filterDefaults = {
  query: "",
  batch_no: "",
  product: "",
  tracking_status: "",
  date_from: null,
  date_to: null,
  specific_date: null,
  batch_status: "",
  communication_mode: "",
  search_type: "notice_id",
  last_four_digit: "",
  year: new Date().getFullYear().toString(),
}

const searchTypeOptions = [
  { label: "Name", value: "name" },
  { label: "Email", value: "email" },
  { label: "Mobile", value: "mobile" },
  { label: "Notice ID", value: "notice_id" },
  { label: "Tracking No.", value: "tracking_no" },
  { label: "Loan Acc. No.", value: "lan" },
]

const getSearchLabel = (value) => searchTypeOptions.find((val) => val.value === value)?.label ?? ""

const trackingStatusOptions = [
  { label: "RTO Delivered", value: "RTO Delivered" },
  { label: "Delivered", value: "Delivered" },
  { label: "RECEIVED RECEPTACLE FROM ABROAD", value: "RECEIVED RECEPTACLE FROM ABROAD" },
  { label: "In Transit", value: "In Transit" },
  { label: "No Information Yet", value: "No Information Yet" },
  { label: "On Hold", value: "On Hold" },
  { label: "Pending - Undelivered", value: "Pending - Undelivered" },
]

function BatchFilter2({ show, close, prevFilter, setPrevFilter, searchByPrev, setSearchByPrev }) {
  const userData = useAuthStore((state) => state.userData)

  const [filter, setFilter] = useState({ ...filterDefaults })
  const [searchBy, setSearchBy] = useState("year") // Default to year

  // handler
  const handleFilter = (name, val) => setFilter((prev) => ({ ...prev, [name]: val }))
  const updateSearchBy = (type) => {
    setSearchBy(type)
    setFilter((prev) => ({ ...prev, date_from: null, date_to: null, specific_date: null }))
  }

  const clearFilter = () => {
    setFilter({ ...filterDefaults })
    setPrevFilter({ ...filterDefaults })
    setSearchBy("year") // Reset to year
    close()
  }

  const closeFilter = () => {
    setFilter({ ...filterDefaults })
    close()
  }

  const applyFilter = () => {
    setPrevFilter({ ...filter })
    setSearchByPrev(searchBy)
    closeFilter()
    console.log(filter, "filter")
  }

  useEffect(() => {
    if (!!show) {
      setFilter({ ...prevFilter })
      setSearchBy(searchByPrev)
    }
  }, [show])

  // ------------------------

  const [products, setProducts] = useState([])

  const getProducts = useCallback(async () => {
    const res = await fetchProducts(userData?.client_id, "notice")
    if (res) setProducts(res)
  }, [])

  useEffect(() => {
    getProducts()
  }, [])

  return (
    <Modal size="sm" show={show} onHide={close} backdrop="static">
      <Modal.Header>
        <Modal.Title>Filter</Modal.Title>
      </Modal.Header>
      <Modal.Body onKeyDown={(e) => e.key === "Enter" && applyFilter()}>
        {/* Search Filter */}
        <div className="mb-2 py-3 row align-items-center modal-search">
          <div className="col-md-4 pe-0">
            <select
              style={{ fontSize: "0.8rem" }}
              value={filter?.search_type}
              onChange={(e) => handleFilter("search_type", e.target.value)}
              className="form-select"
            >
              {searchTypeOptions.map(({ label, value }, index) => (
                <option key={index} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-8">
            <input
              type="text"
              className="form-control"
              disabled={!filter?.search_type}
              placeholder={`Search by ${getSearchLabel(filter?.search_type)}`}
              value={filter?.query}
              onChange={(e) => handleFilter("query", e.target.value)}
            />
          </div>
        </div>

        <div className="grey-bg mb-2">
          {/* Search by Batch Number */}
          <div className="mb-2 row">
            <label htmlFor="search_by_batch_number" className="col-sm-5 col-form-label pt-2 mt-1">
              Search by Batch Number
            </label>
            <div className="col-sm-7">
              <input
                id="search_by_batch_number"
                type="text"
                className="form-control"
                placeholder="Batch No."
                value={filter?.batch_no}
                onChange={(e) => handleFilter("batch_no", e.target.value)}
              />
            </div>
          </div>
          {/* Search by Last Four Digit Number */}
          <div className="mb-2 row">
            <label htmlFor="search_by_batch_number" className="col-sm-5 col-form-label pt-2 mt-1">
              Search by Last Four Digit
            </label>
            <div className="col-sm-7">
              <input
                id="search_by_batch_number"
                type="text"
                className="form-control"
                placeholder="Last Four Digit Number."
                value={filter?.last_four_digit}
                onChange={(e) => handleFilter("last_four_digit", e.target.value)}
              />
            </div>
          </div>

          {/* Tracking status */}
          <div className="mb-2 row" style={{ zIndex: "2" }}>
            <label className="col-sm-5 col-form-label">Tracking status</label>
            <div className="col-sm-7">
              <SelectDropdown
                options={trackingStatusOptions}
                value={filter?.tracking_status}
                onChange={(val) => handleFilter("tracking_status", val)}
                placeholder="Select"
              />
            </div>
          </div>
        </div>

        {/* Search By Date */}
        <div className="grey-bg" style={{ zIndex: "0", position: "relative" }}>
          <div className="mb-2 row">
            <label className="col-sm-5 col-form-label">Search by Date</label>
          </div>
          <div className="mb-2 row">
            <label htmlFor="date_between" className="col-md-4 col-form-label">
              <input
                className="form-check-input ml-0"
                type="radio"
                name="search_by_date_type"
                id="date_between"
                checked={searchBy === searchByModes?.DATE_BETWEEN}
                onChange={() => updateSearchBy(searchByModes?.DATE_BETWEEN)}
              />{" "}
              Date Between
            </label>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-6 ps-0 datepicker datepicker-small datepicker-small-white">
                  <DatePicker
                    disabled={searchBy !== searchByModes?.DATE_BETWEEN}
                    value={filter?.date_from}
                    maxDate={filter?.date_to}
                    onChange={(val) => handleFilter("date_from", val)}
                    label="From"
                  />
                </div>
                <div className="col-md-6 ps-0 datepicker datepicker-small datepicker-small-white">
                  <DatePicker
                    disabled={searchBy !== searchByModes?.DATE_BETWEEN}
                    value={filter?.date_to}
                    minDate={filter?.date_from}
                    onChange={(val) => handleFilter("date_to", val)}
                    label="To"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-2 row">
            <label htmlFor="specific_date" className="col-md-6 col-form-label">
              <input
                className="form-check-input ml-0"
                type="radio"
                name="search_by_date_type"
                id="specific_date"
                checked={searchBy === searchByModes?.SPECIFIC_DATE}
                onChange={() => updateSearchBy(searchByModes?.SPECIFIC_DATE)}
              />{" "}
              Specific Date
            </label>
            <div className="col-md-6 datepicker">
              <div className="col-md-12 datepicker datepicker-small datepicker-small-white w-100">
                <DatePicker
                  disabled={searchBy !== searchByModes?.SPECIFIC_DATE}
                  value={filter?.specific_date}
                  onChange={(val) => handleFilter("specific_date", val)}
                  label="Specific date"
                />
              </div>
            </div>
          </div>
          <div className="mb-2 row">
            <label htmlFor="year" className="col-md-4 col-form-label">
              <input
                className="form-check-input ml-0"
                type="radio"
                name="search_by_date_type"
                id="year"
                checked={searchBy === "year"}
                onChange={() => {
                  setSearchBy("year")
                  setFilter((prev) => ({ ...prev, date_from: null, date_to: null, specific_date: null }))
                }}
              />{" "}
              Year
            </label>
            <div className="col-md-8 d-flex justify-content-end">
              <div
                className="col-md-12 datepicker datepicker-small datepicker-small-white w-65"
                style={{ zIndex: "2" }}
              >
                <SelectDropdown
                  disabled={searchBy !== "year"}
                  options={Array.from({ length: 5 }, (_, i) => {
                    const year = new Date().getFullYear() - i
                    return { label: year.toString(), value: year.toString() }
                  })}
                  value={filter?.year}
                  onChange={(val) => handleFilter("year", val)}
                  placeholder="Select Year"
                  className="select-dropdown"
                  style={
                    searchBy !== "year"
                      ? {
                          backgroundColor: "#f1f1f1",
                          color: "#a0a0a0",
                          cursor: "not-allowed",
                          borderColor: "#d3d3d3",
                          opacity: 0.7,
                          pointerEvents: "none",
                        }
                      : {}
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={clearFilter}>
          Clear Filter
        </Button>
        <Button variant="secondary" onClick={closeFilter}>
          Close
        </Button>
        <Button variant="primary" onClick={applyFilter}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default BatchFilter2

