"use client"

import { useEffect, useState, useRef } from "react"
import { Link } from "react-router-dom"
import PrelitigationBatchHeader from "../../../../Components/Headers/PrelitigationBatchHeader"
import Dropdown from "react-bootstrap/Dropdown"
import Arrow from "../../../../Static/RawImages/arrow.png"
import {
  fetchConciliationCamp,
  fetchDownloadTrackingReport,
  getPreLitiBatchData,
  fetchDispatchList,
  downloadMediaUrl,
} from "../../../../API"
import ContentLoader from "../../../../Components/ContentLoader"
import dateFormat from "../../../../Functions/getCorrentDateFormat"
import CircularChart from "../../../../Components/Charts/CircularChart"
import NoRecord from "../../../../Components/NoRecord"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import ReactTooltip from "react-tooltip"
import { isEmpty } from "../../../../Functions/isEmpty"
import { format, parse } from "date-fns"
import useGeneralStore from "../../../../store/generalStore"
import useAuthStore from "../../../../store/authStore"
import GenerateNoticeModal from "../../../Components/Prelitigation/LegalNotices/GenerateNoticeModal"
import {
  approveSampleNotice,
  createNoticeMasterCampaign,
  deleteNoticeBatch,
  generateSampleNotice,
  getAxisDeliveryReport,
  getDailyNoticeRespReportCron,
} from "../../../../API/panelApis"
import { MdSmsFailed } from "react-icons/md"
import usePagination from "../../../../hooks/usePagination"
import Pagination from "../../../../Components/Pagination"
import GenerateMissingNotices from "../../../Components/Prelitigation/LegalNotices/Batch/GenerateMissingNotices"

function PreLitigationBatch() {
  const updateFetchedCount = useGeneralStore((state) => state.update_fetched_count)
  const userData = useAuthStore((state) => state.userData)
  const showAlert = useGeneralStore((state) => state.open_alert)

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  const [SearchParameter, setSearchParameter] = useState({
    year: new Date().getFullYear().toString(), // Default to current year
  })
  const [swTest, setswTest] = useState(false)
  const [email, setEmail] = useState("")
  const [batId, setBatId] = useState(null)
  const [clientId, setClientId] = useState(null)

  const [showGenerateNoticeModal, setShowGenerateNoticeModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const { currentPage, handleTotal, paginationCallBack, paginationProps } = usePagination({ defaultLimit: 20 })

  // Add a ref to track if the initial fetch has been done
  const initialFetchDone = useRef(false)
  // Add a ref to track if the component is mounted
  const isMounted = useRef(true)

  const fetchData = async () => {
    // Prevent fetching if already loading
    if (loading) return

    setLoading(true)

    try {
      // Ensure year parameter is included
      const params = {
        ...SearchParameter,
        page: currentPage,
        year: SearchParameter.year || new Date().getFullYear().toString(), // Default to current year if not set
      }

      const res = await getPreLitiBatchData(params)
      // Only update state if component is still mounted
      if (isMounted.current && res) {
        setData(res?.data)
        updateFetchedCount(res.total)
        handleTotal(Number(res?.total))
      }
    } catch (error) {
      console.log(error)
      // Only show alert if component is still mounted
      if (isMounted.current) {
        showAlert({ variant: "danger", msg: "Failed to fetch data" })
      }
    } finally {
      // Only update loading state if component is still mounted
      if (isMounted.current) {
        setLoading(false)
      }
    }
  }

  const getData = () => {
    // Don't call paginationCallBack directly, use our controlled fetch
    fetchData()
  }

  useEffect(() => {
    // Set up cleanup function to prevent state updates after unmount
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    // Only fetch data if this is not the initial render or if SearchParameter/currentPage has changed after initial load
    if (!initialFetchDone.current) {
      fetchData()
      initialFetchDone.current = true
    } else if (Object.keys(SearchParameter).length > 0 || currentPage > 1) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchParameter, currentPage])

  function download(dataurl, filename) {
    const link = document.createElement("a")
    link.href = downloadMediaUrl(dataurl)
    link.target = "_blank"
    link.rel = "noreferer"
    if (!!filename) link.download = filename
    link.click()
    link.remove()
  }

  const downloadTrackingReport1 = async (clientId, batchId) => {
    try {
      const params1 = {
        client_id: clientId,
        batch_id: batchId,
      }
      const res = await fetchDownloadTrackingReport(params1)

      if (res) {
        download(res?.data?.file_url, res?.data?.file_url.split("/").at(-1))
        showAlert({ variant: "info", msg: res?.message })
      }
    } catch (error) {
      console.log(error)
      showAlert({ variant: "danger", msg: error.toString() })
    }
  }

  const downloadTrackingReport2 = async () => {
    try {
      const params2 = {
        client: clientId,
        batch_id: batId,
        email: email,
      }
      const res = await fetchConciliationCamp(params2)

      if (res) {
        setEmail("")
        showAlert({ variant: "info", msg: res?.message || "Report will be sent on email" })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const downloadTrackingReport2_new = async ({ client, batch_id, email }) => {
    try {
      const res = await fetchConciliationCamp({
        client,
        batch_id,
        email,
      })

      if (res) {
        setEmail("")
        showAlert({ variant: "info", msg: res?.message || "Report will be sent on email" })
        window.open("https://panel.themedius.ai/#/all-files/all-downloads", "_blank")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const downloadDispatchList = async (clientId, batchId) => {
    try {
      const params2 = {
        client: clientId,
        batch_id: batchId,
      }
      const res = await fetchDispatchList(params2)

      if (res) {
        if (!!res?.data?.file_urls?.length) {
          res?.data?.file_urls?.forEach((el) => {
            download(el, el.split("/").at(-1))
          })
        }
        showAlert({ variant: "info", msg: res?.message })
      }
    } catch (error) {
      console.log(error)
      showAlert({ variant: "danger", msg: error.toString() })
    }
  }

  const downloadResponseReport = async (client_id, batch_id) => {
    const res = await getDailyNoticeRespReportCron({
      client_id,
      batch_id,
    })

    if (res) {
      if (!!res?.data) download(res?.data, res?.data?.split("/").at(-1))
      showAlert({ variant: "success", msg: res?.message })
    } else showAlert({ variant: "danger", msg: "Failed to download" })
  }

  const generateSampleNoticeAction = async ({ batch_id, client_id }) => {
    const res = await generateSampleNotice({ batch_id, client_id })
    if (res) {
      showAlert({ variant: "success", msg: res?.message })

      Object.values(res?.data).map((el) => {
        download(el, el?.split("/").at(-1))
      })
    } else showAlert({ variant: "danger", msg: "Action Failed" })
  }

  const approveSampleNoticeAction = async ({ batch_id, client_id }) => {
    const res = await approveSampleNotice({ batch_id, client_id })
    if (res) {
      showAlert({ variant: "success", msg: res?.message })
    } else showAlert({ variant: "danger", msg: "Action Failed" })
  }
  const downloadAxisDeliveryReport = async ({ batch_id, client_id }) => {
    const res = await getAxisDeliveryReport({ batch_id, client_id })
    if (!res?.error) {
      showAlert({ variant: "success", msg: res?.message })
    } else showAlert({ variant: "danger", msg: res?.error?.message })
  }

  const startCampaign = async ({ batch_id, client_id }) => {
    const res = await createNoticeMasterCampaign({ batch_id, client_id })
    if (res) {
      showAlert({ variant: "success", msg: res?.message })
    } else showAlert({ variant: "danger", msg: "Action Failed" })
  }

  const deleteNoticeBatchData = async (client_id, batch_id) => {
    const res = await deleteNoticeBatch({ client_id, batch_id })
    if (res) {
      showAlert({ variant: "success", msg: res?.message })
    } else showAlert({ variant: "danger", msg: "Action Failed" })
  }

  const downloadUploadedData = (url) => {
    if (!url) return
    download(url)
    showAlert({
      variant: "info",
      msg: "Report created successfully.",
    })
  }

  // Validate Email
  const isValidEmail = (email_ID) => !!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email_ID)

  return (
    <>
      <PrelitigationBatchHeader setSearchParameter={setSearchParameter} getData={getData} />
      <hr className="mt-4 mb-4" />
      {!!data?.length && !loading && (
        <>
          {data?.map((val, i) => {
            const commModeLowerCase = !!val?.communication_mode?.length
              ? val?.communication_mode?.map((el) => el?.toString()?.toLowerCase())
              : []
            const circularChartData = {
              data: {
                name: "Delivered",
                value: commModeLowerCase?.includes("postal") ? val?.postal_delivered_total?.toString() || "0" : 0,
                color: "#359aba",
              },
              title: "Postal",
              tooltipText: `Delivered - ${Number(val.total_postal)}`,
            }
            const circularChartData2 = {
              data: {
                name: "Delivered",
                value: commModeLowerCase?.includes("whatsapp") ? val?.whatspp_delivered_total?.toString() || "0" : 0,
                color: "#359aba",
              },
              title: "WhatsApp",
              tooltipText: `Delivered - ${Number(val.total_whatsapp)}`,
            }
            const circularChartData3 = {
              data: {
                name: "Delivered",
                value: commModeLowerCase?.includes("email") ? val?.email_delivered_total?.toString() || "0" : 0,
                color: "#359aba",
              },
              title: "Email",
              tooltipText: `Delivered - ${Number(val.total_email)}`,
            }
            const circularChartData4 = {
              data: {
                name: "Delivered",
                // name: val?.sms_delivered_percent?.toString() !== "0" ? "Delivered" : "",
                value: commModeLowerCase?.includes("sms") ? val?.sms_delivered_total?.toString() || "0" : 0,
                color: "#359aba",
              },
              title: "SMS",
              tooltipText: `Delivered - ${Number(val.total_sms)}`,
            }

            return (
              <div className="row d-flex justify-between batch-listing" key={i}>
                <div className="col-md-12 col-lg-2 mb-lg-0 mb-3">
                  <div className="d-flex align-start">
                    <div className="w-100 overflow-hidden">
                      <Link to={`/prelitigation/legal/notices/${val?.batch_id}`} className="text-primary">
                        <p className="mb-2 text-blue">
                          <strong>Batch No. {val?.client_batch_id}</strong>
                        </p>
                      </Link>
                      <p className="mb-2">
                        Month :{" "}
                        <span className="text-primary">
                          {!isEmpty(val?.uploaded_date)
                            ? format(new Date(parse(val?.uploaded_date, "yyyy-MM-dd", new Date())), "MMMM")
                            : ""}
                        </span>
                      </p>

                      <ReactTooltip id={`batch_name_tooltip_${i + 1}`} effect="solid" place="right">
                        <span>{val?.batch_name}</span>
                      </ReactTooltip>
                      <a data-tip data-for={`batch_name_tooltip_${i + 1}`}>
                        <p className="mb-2 text-black d-block">
                          <span className="batch-name">Ref - {val?.batch_name}</span>
                        </p>
                      </a>
                      <p className="mb-2">Date - {dateFormat(val?.uploaded_date)}</p>
                      <p className="mb-2">
                        Total Notices - <span className="text-black">{val.total_notice ? val.total_notice : "-"}</span>
                      </p>
                      <p className="mb-2">
                        Pdf Urls - <span className="text-black">{val.pdf_urls ?? ""}</span>
                      </p>
                    </div>
                  </div>

                  <div>
                    {!!val?.batch_status && (
                      <>
                        Status :{" "}
                        <strong style={{ textTransform: "capitalize" }}>
                          <span
                            style={{
                              color:
                                val?.batch_status?.toLowerCase() === "completed"
                                  ? "green"
                                  : val?.batch_status?.toLowerCase() === "in progress"
                                    ? "orange"
                                    : val?.batch_status?.toLowerCase() === "error"
                                      ? "red"
                                      : "blue",
                            }}
                          >
                            {val?.batch_status}
                          </span>
                        </strong>
                      </>
                    )}
                  </div>

                  {!!val?.upload_failure_reason && (
                    <>
                      <div className="alert alert-danger py-1 px-2 my-1 d-flex align-items-start gap-2 me-1">
                        <MdSmsFailed style={{ fontSize: "18px", marginTop: "0.2rem" }} />
                        {val?.upload_failure_reason}
                      </div>
                    </>
                  )}
                </div>

                <div className="col-md-12 col-lg-2 mb-lg-0 mb-3 border-right-dash">
                  <div className="status-progress">
                    <div className="chart-box text-end">
                      <CircularChart data={circularChartData} scale={1} />
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-lg-5 mb-lg-0 mb-3">
                  <div className="status-progress status-progress-prelet">
                    <div className="chart-box text-center">
                      <div className="row">
                        <div className="col-md-12 col-lg-4">
                          <div className="status-progress">
                            <div className="chart-box text-end">
                              <CircularChart data={circularChartData2} scale={1} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-4">
                          <div className="status-progress">
                            <div className="chart-box text-end">
                              <CircularChart data={circularChartData3} scale={1} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-4">
                          <div className="status-progress">
                            <div className="chart-box text-end">
                              <CircularChart data={circularChartData4} scale={1} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3 mb-lg-0 mb-3">
                  <div className="action-box">
                    <label htmlFor="dropdown-basic" className="col-form-label pb-2">
                      Action
                    </label>
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic" className="w-100 text-start select-style">
                        Select <img src={Arrow || "/placeholder.svg"} alt="Sort" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="select-style-dropdown">
                        <Dropdown.Item onClick={() => downloadUploadedData(val?.raw_file)}>
                          Download Uploaded Data
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => downloadTrackingReport1(val?.client?.client_id, val?.batch_id)}>
                          Download Client Report
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={() => {
                            // setBatId(val?.batch_id);
                            // setClientId(val?.client?.client_id);
                            // setswTest(true);
                            downloadTrackingReport2_new({
                              client: val?.client?.client_id,
                              batch_id: val?.batch_id,
                              email: "",
                            })
                          }}
                        >
                          Download Tracking Report
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => downloadDispatchList(val?.client?.client_id, val?.batch_id)}>
                          Dispatch List
                        </Dropdown.Item>

                        {userData?.client_id === 1 && (
                          <Dropdown.Item
                            onClick={() => {
                              setSelectedItem(val)
                              setShowGenerateNoticeModal(true)
                            }}
                          >
                            Generate Notices
                          </Dropdown.Item>
                        )}
                        {!val?.notice_sample_approved && (
                          <>
                            <Dropdown.Item
                              onClick={() =>
                                generateSampleNoticeAction({ batch_id: val?.batch_id, client_id: userData?.client_id })
                              }
                            >
                              Generate Sample Notice
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                approveSampleNoticeAction({ batch_id: val?.batch_id, client_id: userData?.client_id })
                              }
                            >
                              Approve Sample Notice
                            </Dropdown.Item>
                          </>
                        )}
                        {!!val?.notice_sample_approved &&
                          val?.batch_status !== "completed" &&
                          !commModeLowerCase?.includes("whatsapp") && (
                            <Dropdown.Item
                              onClick={() => startCampaign({ batch_id: val?.batch_id, client_id: userData?.client_id })}
                            >
                              Start Campaign
                            </Dropdown.Item>
                          )}

                        <Dropdown.Item
                          onClick={() =>
                            downloadAxisDeliveryReport({ batch_id: val?.batch_id, client_id: userData?.client_id })
                          }
                        >
                          Download Digital Report
                        </Dropdown.Item>

                        <Dropdown.Item onClick={() => downloadResponseReport(val?.client?.client_id, val?.batch_id)}>
                          Download Notice Response
                        </Dropdown.Item>

                        <Dropdown.Item onClick={() => deleteNoticeBatchData(val?.client?.client_id, val?.batch_id)}>
                          Delete Notice batch
                        </Dropdown.Item>

                        {val?.pdf_urls < val?.total_notice && <GenerateMissingNotices details={val} />}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="col-md-12">
                  <hr className="mt-4 mb-4" />
                </div>
              </div>
            )
          })}

          <Pagination {...paginationProps} />
        </>
      )}

      {!data?.length && !loading && <NoRecord />}
      {loading && <ContentLoader />}

      <GenerateNoticeModal
        show={showGenerateNoticeModal}
        setShow={setShowGenerateNoticeModal}
        item={selectedItem}
        setItem={setSelectedItem}
      />

      <Modal size="sm" show={swTest} onHide={() => setswTest(false)}>
        <Modal.Header>
          <Modal.Title>Download Tracking Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="py-3 text-left">
            <label htmlFor="download_tracking_report_email_id">Enter Email ID</label>
            <input
              value={email}
              name="eamil"
              onChange={(e) => setEmail(e.target.value)}
              id="download_tracking_report_email_id"
              type="email"
              className="form-control mt-2"
              placeholder="eg: example@email.com"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setswTest(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={!isValidEmail(email)}
            onClick={() => {
              downloadTrackingReport2()
              setswTest(false)
            }}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PreLitigationBatch

