import React, { useMemo } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import useGeneralStore from "../../../store/generalStore";
import useAgentStore from "../../../store/agent/agentStore";
import { agentLogout } from "../../../API/commonApis";
import Logo from "../../../Static/RawImages/logo.png";
import Hamburger from "../../../Static/RawImages/hamburger.png";
import { Button } from "react-bootstrap";
import useAuthStore from "../../../store/authStore";
import { BsCheckCircleFill } from "react-icons/bs";
import "./audit_call_sidebar.css";

function AgentSidebarCallAudit() {
  const isSidebarOpen = useGeneralStore((state) => state.is_sidebar_open);
  const audit_Call_step_count = useGeneralStore((state) => state.audit_Call_step_count);
  const toggleSidebar = useGeneralStore((state) => state.toggle_sidebar);
  const removeUser = useAuthStore((state) => state.removeUser);
  const history = useHistory();
  const leadCounts = useAgentStore((state) => state.lead_counts);
  const location = useLocation();

  const steps = useMemo(() => {
    const baseSteps = [
      { id: 1, name: "Kreditbee" },
      { id: 2, name: "Moneyview" },
      { id: 3, name: "Poonawalla" },
      { id: 4, name: "Smartcoin" },
      { id: 5, name: "Werize" },
      { id: 6, name: "L&T Finance" },

    ];

    return baseSteps.map((step) => {
      let status = "pending";
      if (step.id < audit_Call_step_count) {
        status = "completed";
      } else if (step.id === audit_Call_step_count) {
        status = "in-progress";
      }
      return { ...step, status };
    });
  }, [audit_Call_step_count]);

  const LogoutFunc = async () => {
    await agentLogout();
    removeUser();
    history.push("/login");
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%",width:"200px"}}>
      <div>
        <div className="logo">
          <a href="/">
            <img src={Logo} alt="logo" />
          </a>
          <div className="admin-tag mt-1">Agent</div>
        </div>
        <div className="menu-toggle" onClick={() => toggleSidebar()}>
          <img src={Hamburger} alt="Hamburger" />
        </div>

        <div className="menu">
          <ul className="nav flex-column">
            <li className="nav-item">
              <div className="accordion" id="accordionMenu">
                <div className="accordion-item">
                  <SiderBarItem
                    target="/agent/call_audited"
                    label="Call Quality Training"
                    active={location.pathname === "/agent/call_audited"}
                    showLabel={isSidebarOpen}
                    icon={
                      <svg width="20" height="17" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 0H1.5V22.75H28.75V24.25H0V0ZM28.35 2.95L18.8857 15.569L11.3801 9.56454L5.34577 17.4556L4.15423 16.5444L11.1199 7.43546L18.6143 13.431L27.15 2.05L28.35 2.95Z"
                          fill="black"
                        />
                      </svg>
                    }
                  />
                </div>
              </div>
            </li>
          </ul>
          <div className="stepper-container">
            <div className="stepper">
              {steps.map((step, index) => (
                <div key={step.id} className={`step-item ${step.status}`}>
                  <div className="step-indicator">
                    {step.status === "completed" ? (
                      <BsCheckCircleFill className="check-icon" />
                    ) : (
                      <div className={`circle ${step.status === "in-progress" ? "active" : ""}`}>
                        {step.status === "in-progress" ? <div className="inner-circle" /> : null}
                      </div>
                    )}
                    {index < steps.length - 1 ? (
                      <div
                        className="line"
                        style={{
                          background: step.status === "completed"
                            ? "#28a745"
                            : step.status === "in-progress"
                            ? "linear-gradient(to bottom, #28a745 50%, #ccc 50%)"
                            : "#ccc"
                        }}
                      />
                    ) : null}
                  </div>
                  <div className="step-content" style={{ display: isSidebarOpen ? "block" : "none" }}>
                    <div className="step-label">
                      STEP {step.id}
                      <span className={`status-label ${step.status}`}>
                        {step.status === "completed"
                          ? "Completed"
                          : step.status === "in-progress"
                          ? "In Process"
                          : "Pending"}
                      </span>
                    </div>
                    <div className="step-title">{step.name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Button style={{ marginBottom: "50px", marginLeft: "20px" }} onClick={LogoutFunc}>
        Logout User
      </Button>
    </div>
  );
}

export default AgentSidebarCallAudit;

function SiderBarItem({ target = "/", active = false, icon = <></>, label = "", showLabel = true }) {
  return (
    <div className="nav-item">
      <Link
        className="nav-link d-flex align-items-start justify-content-start w-100 gap-1"
        to={target}
        style={active ? { fontWeight: "bold", color: "#000000" } : { fontWeight: "normal", color: "#505050" }}
      >
        <div className="m-icon" style={{ minWidth: "20px", height: "23px" }}>
          {icon}
        </div>
        <span
          style={{
            lineHeight: "1.3rem",
            whiteSpace: "normal",
            display: showLabel ? "inline-block" : "none",
          }}
        >
          {label}
        </span>
      </Link>
    </div>
  );
}