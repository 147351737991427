import { apiGET, apiPOST } from "../utils/apiHandler";

export const fetchAccountData = (params) => apiGET("/dashboard/api/account/", params);

export const fetchCommonActivity = (params = { user_id: null, page: 1 }, cancelToken = null) =>
  apiGET("/dashboard/api/get_common_activity/", params, cancelToken);

export const getDispMapping = () => apiGET("/dashboard/api/get_product_mappings/");

export const postDispositionData = (body) => apiPOST("/dashboard/api/add_disposition_data/", body);
export const postCallRecorsAdminDisp = (body) => apiPOST("/call/api/call_records_admin_disposition/", body);
export const postUpdateCallAdminDisp = (body) => apiPOST("/call/api/update_call_disposition/", body);

export const postManualCallInitiate = (body = { to_number: null, call_type: "automatic", contact_type: null }) =>
  apiPOST("/call/api/manual_call_initiate/", body);

export const callCustomerNow = (body = { customer_id: null, to_number: null }) => apiPOST("/dashboard/api/call_now/", body);

export const searchCustomer = (params = { search: null }) => apiGET("/dashboard/api/search_customer/", params);

export const updateAgentPanelTime = (body = { panel_time: null, idle_time: null }) => apiPOST("/dashboard/api/agent_panel_time/", body);

export const sendPaymentLinkApi = (body) => apiPOST("/dashboard/api/agent-payment-link/", body);

export const fetchAgentCallDone = (params = { date_from: null, date_to: null, user_id: null, page: "2" }) => apiGET("/client_panel/api/agent_status/", params);

export const fetchWhatsappChats = (params = { number: null }) => apiGET("/dashboard/api/get_wati_conversation/", params);

export const fetchCustomerContactibility = (params = { customer_number: null }) => apiGET("/dashboard/api/get_customer_contactibility_data/", params);

export const fetchAgentWisePtp = (params = { user_id: null, page: null }) =>
  apiGET("/dashboard/api/get_agent_ptp_customer_data_by_ownership/?ownership=mine", params);

export const fetchAdditionalLeads = (params = { user_id: null, page: null }) => apiGET("/dashboard/api/get_additional_leads_common_activity/", params);

export const fetchMissedCalls = (params = { user_id: null, page: null }) => apiGET("/dashboard/api/get_missed_call_common_activity/", params);

export const fetchEmailOpenedList = (params = { user_id: null, page: null }) => apiGET("/dashboard/api/get_email_opened/", params);

export const fetchLegalNoticeLinkClickedList = (params = { user_id: null, page: null }) => apiGET("/dashboard/api/get_legal_notice_link_clicked/", params);

export const fetchPaymentLinkClickedList = (params = { user_id: null, page: null }) => apiGET("/dashboard/api/get_payment_link_clicked/", params);

export const fetchWhatsappLoginStatus = (params = { user_id: null }) => apiGET("/scrapping/api/get_manual_whatsapp_status/", params);

export const getNoticeTypes = (params) => apiGET("/pre_litigation/api/notice-types/", params);

export const getFirStates = (params) => apiGET("/fir/api/get_states/", params);

export const getRequiredFirData = (params) => apiGET("/fir/api/get_required_fir_data/", params);

export const fileInstantSingleFir = (params) => apiGET("/fir/api/file_instant_single_fir/", params);

export const sendFirCopyToBorrower = (params) => apiGET("/fir/api/send_fir_copy_to_borrower/", params);

export const getAgentBrokenPtpLeads = (params) => apiGET("/dashboard/api/get_agent_ptp_customer_data_by_ownership/?ownership=others", params);

export const getAgentSettlementLeads = (params) => apiGET("/dashboard/api/get_agent_setl_customer_data/", params);

export const getAgentMySettlementLeads = (params) => apiGET("/dashboard/api/get_agent_setl_customer_data_by_ownership/?ownership=mine", params);

export const getAgentOtherSettlementLeads = (params) => apiGET("/dashboard/api/get_agent_setl_customer_data_by_ownership/?ownership=others", params);

export const getAgentAcceptedAmountsSettlementLeads = (params) => apiGET("/dashboard/api/get_agent_setl_accepted_renego_amount_data/", params);

export const getAgentLetterIssuedSettlementLeads = (params) => apiGET("/dashboard/api/get_agent_letter_issued_customer_data/", params);

export const getAgentPerformance = (params) => apiGET("/dashboard/api/agent_performance/", params);

// communication history - DISPOSITION
export const fetchDispositionData = (params = { customer_mobile_number: null }) => apiGET("/dashboard/api/disposition_history/", params);

// communication history - CALL
export const getCallHistory = (params = { to_number: null }) => apiGET("/call/api/get_call_records/", params);

// communication history - EMAIL
export const getMailHistory = (params = { customer_mobile_number: null }) => apiGET("/freshdesk/api/email_webhook/", params);

export const getAlternateCustomerContacts = (params = { customer_id: null }) => apiGET("/dashboard/api/get_alt_contacts/", params);

export const changeCall_AuditStatus = (params) => apiPOST("/dashboard/api/update_call_audit_status/", params);
export const getchangeCall_AuditStatus = () => apiGET("/dashboard/api/update_call_audit_status/");
