import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import axios from "axios";
import TakeActionButton from "../TakeAction";
import Pagination from "../../../../Components/Pagination/index";
import { changeCall_AuditStatus } from "../../../../API/agentApis";
import useAuthStore from "../../../../store/authStore";
import { teleCollectioAsgmt } from "../data";
import { Alert } from "antd";
import { FaCheckCircle, FaInfo, FaThumbsDown, FaThumbsUp, FaTimesCircle } from "react-icons/fa";
import useGeneralStore from "../../../../store/generalStore";

const ITEMS_PER_PAGE = 10;

const LT = () => {
  const [auditStatus, setAuditStatus] = useState({}); // Tracks API-verified status for each loan_account_no
  const [currentPage, setCurrentPage] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const setAuditCallStepCount = useGeneralStore((state) => state.setAuditCallStepCount);
  const [submittedItems, setSubmittedItems] = useState({});
  const userData = useAuthStore((state) => state.userData);
  const setCallAudited = useAuthStore((state) => state.setCallAudited);
  const history = useHistory();

  // Fetch audit status for all LT loan_account_no on mount
  useEffect(() => {
    const fetchAuditStatus = async () => {
      const LTItems = teleCollectioAsgmt.filter((item) => item.client_name === "L&T");
      const statusMap = {};

      try {
        const auditPromises = LTItems.map(async (item) => {
          const loanAccountNo = item.loan_account_no;
          const response = await axios.get(
            `https://api.themedius.ai/call/api/agent_call_audit_assignment/?loan_account_no=${loanAccountNo}`,
            { headers: { "Content-Type": "application/json" } }
          );
          // Check if the loan_account_no matches the response  
          const isVerified = response.data[0]?.loan_account_no === loanAccountNo;
          statusMap[loanAccountNo] = isVerified;
        });

        await Promise.all(auditPromises);
        setAuditStatus(statusMap);
      } catch (error) {
        console.error("Error fetching audit status:", error);
      }
    };

    fetchAuditStatus();
  }, [userData]);

  // Handle individual item submission (if still needed)
  const handleSubmission = (itemId) => {
    // If you still need local submission tracking, uncomment below
    setSubmittedItems((prev) => ({
      ...prev,
      [itemId]: true,
    }));
  };

  // Check if all LT items are verified via API
  const allVerified = teleCollectioAsgmt
    .filter((item) => item.client_name === "L&T")
    .every((item) => auditStatus[item.loan_account_no] === true || submittedItems[item.key]);

  // Trigger final submission
  // const triggerSubmission = async () => {
  //   try {
  //     const data = await changeCall_AuditStatus({ type: "step_count", step_count: 6 });
  //     if (data) {
  //       setAuditCallStepCount(6);
  //     }
  //   } catch (error) {
  //     console.error("Error submitting audit status:", error);
  //     alert("Submission failed. Please try again.");
  //   }
  // };
  const triggerSubmission = async () => {
    try {
      const data = await changeCall_AuditStatus({ type: "step_count", step_count: 7 });
      if (data) {
        setAuditCallStepCount(7);
        setShowPopup(true); 
      }
    } catch (error) {
      console.error("Error submitting audit status:", error);
      alert("Submission failed. Please try again.");
    }  };


  // const handleClosePopup = async () => {
  //   try {
  //     const data = await changeCall_AuditStatus({ type: "call_audit" });
  //     if (data) {
  //       setShowPopup(false);
  //       history.push("/agent/calls/new/imp-leads");
  //       setCallAudited(true);
  //       // setAuditCallStepCount(6); // Uncomment if needed
  //     }
  //   } catch (error) {
  //     console.error("Error submitting audit status:", error);
  //     alert("Submission failed. Please try again.");
  //   }
  // };

  const totalPages = Math.ceil(
    teleCollectioAsgmt.filter((item) => item.client_name === "L&T").length / ITEMS_PER_PAGE
  );
  const currentData = teleCollectioAsgmt
    .filter((item) => item.client_name === "L&T")
    .slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  return (
    <>
      <div style={{ overflow: "scroll", padding: "50px" }}>
        <div className="alert alert-primary py-2 mt-1 d-flex align-item-center mx-2" role="alert">
          <span>
            <FaInfo />
          </span>
          <span>
            Following are the 11 criterias that a tele-collection agent must follow on each call.
            <a
              href="https://drive.google.com/file/d/1SPfmaL0fPRIbSWZyTSg88HfufpJWs8I4/view?usp=drivesdk"
              target="_blank"
              rel="noreferrer"
            >
              Click here to view
            </a>
          </span>
        </div>

        <table className="table table-bordered border table-sm">
          <thead className="thead-light">
            <tr>
              <th className="p-3 text-left align-top">Call Time</th>
              <th className="p-3 text-left align-top">Client Name</th>
              <th className="p-3 text-center align-top">Disposition</th>
              <th className="p-3 text-center align-top" style={{ width: "30%" }}>
                Call Recording
              </th>
              <th className="p-3 text-center align-top">Take Action</th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((item, index) => (
              <tr key={index} style={{ background: index % 2 === 0 ? "transparent" : "#fbfbfb" }}>
                <td className="p-3 text-center">{item.call_time}</td>
                <td className="p-3 text-left">{item.client_name}</td>
                <td className="p-3 text-left">{item.disposition}</td>
                <td className="p-3 text-center" style={{ width: "30%" }}>
                  <ReactAudioPlayer
                    src={item.audio_url}
                    controls
                    style={{ width: "100%" }}
                    controlsList="nodownload noplaybackrate"
                  />
                </td>
                <td className="p-3 text-center">
                  <TakeActionButton
                    key={item.key}
                    itemId={item.key}
                    loanAccountNo={item.loan_account_no}
                    onSubmit={handleSubmission}
                    isActionTaken={auditStatus[item.loan_account_no] === true} // Reflect API-verified status
                    data={item}
                    disposition={item.disposition}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Pagination
          currentPage={currentPage}
          pageTotal={totalPages}
          handlePagination={setCurrentPage}
          limitPerPage={ITEMS_PER_PAGE}
        />

        <Button onClick={triggerSubmission} variant="primary" className="mt-3">
          Submit
        </Button>
      </div>
  {/* Popup Modal */}
  {/* <Modal show={showPopup} onHide={() => setShowPopup(false)} contentClassName="overflow-hidden text-black">
        <Modal.Header closeButton>
          <Modal.Title>Training Completed!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>You’ve successfully completed the Call Quality Training. You’re now ready to start live calls.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClosePopup}>
            Go to Agent Panel
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default LT;