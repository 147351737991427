import Lottie from "react-lottie-player";
import successLottie from "./success.json";
import { Button } from "react-bootstrap";
import { changeCall_AuditStatus } from "../../../../API/agentApis";
import useGeneralStore from "../../../../store/generalStore";
import useAuthStore from "../../../../store/authStore";
import { useHistory } from "react-router-dom";

const ThankYou = () => {
  const history = useHistory();

  const setCallAudited = useAuthStore((state) => state.setCallAudited);
  const handleGoToAgentPaenl = async () => {
    try {
      const data = await changeCall_AuditStatus({ type: "call_audit" });
      if (data) {
        history.push("/agent/calls/new/imp-leads");
        setCallAudited(true);
      }
    } catch (error) {
      console.error("Error submitting audit status:", error);
      alert("Submission failed. Please try again.");
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="d-flex flex-column align-items-center text-center pt-5">
            <Lottie loop={false} animationData={successLottie} play style={{ width: 300, height: 300 }} />

            <div className="mt-0 mb-1">
              <>
                <p className="fw-bold mb-1">
                  <span className="fw-bold" style={{fontSize:"30px"}}> Certified Agent</span>
                </p>
                <p className="fw-semibold mb-3">
                  You’ve successfully completed the Call Quality Training.
                  <br /> You’re now ready to handle live calls..
                  <br />
                  Key criteria and full scripts will be easily accessible during live calls
                </p>
                <Button variant="primary" onClick={handleGoToAgentPaenl}>
                  Go to Agent Panel
                </Button>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
