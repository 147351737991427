"use client"

import { useState, useEffect } from "react"
import Dropdown from "react-bootstrap/Dropdown"
import BatchFilter2 from "./precomps/BatchFilter2"
import FilterButton from "../../ui/buttons/FilterButton"
import SortButton from "../../ui/buttons/SortButton"
import { format, parse } from "date-fns"
import { AiOutlineFileAdd } from "react-icons/ai"
import { Button, Modal } from "react-bootstrap"
import NoRecord from "../NoRecord"
import ContentLoader from "../ContentLoader"
import DatePicker from "../../ui/forms/DatePicker"
import { updateChequeDetails } from "../../API/panelApis"
import { getPreLitiNoticeData } from "../../API"
import useGeneralStore from "../../store/generalStore"
import useAuthStore from "../../store/authStore"

function PrelitigationLegalNoticesHeader({
  HitReload,
  setHitReload,
  setSearchParameter,
  StorePrevFilter,
  setSelectedIds,
  ArrayIds,
}) {
  const features = useAuthStore((state) => state.features)
  const showAlert = useGeneralStore((state) => state.open_alert)

  // filter
  const [showFilter, setShowFilter] = useState(false)
  const [searchBy, setSearchBy] = useState("year") // Default to year
  const [filterData, setFilterData] = useState({
    query: "",
    batch_no: "",
    product: "",
    tracking_status: "",
    date_from: null,
    date_to: null,
    specific_date: null,
    batch_status: "",
    communication_mode: "",
    search_type: "notice_id",
    last_four_digit: "",
    year: new Date().getFullYear().toString(), // Set default year to current year
  })
  const [SortAsc, setSortAsc] = useState(true)
  const [showUpdateChequeDetailsModal, setShowUpdateChequeDetailsModal] = useState(false)
  const [chequeDetails, setChequeDetails] = useState([])
  const [chequeDetailsLoading, setChequeDetailsLoading] = useState(true)
  const [updateChequeInfoLoading, setUpdateChequeInfoLoading] = useState(false)
  const [updateCheckDetails, setUpdateCheckDetails] = useState({})

  const handleUpdateCheckDetails = (notice_id, name, val) =>
    setUpdateCheckDetails((prev) => ({ ...prev, [notice_id]: { ...prev?.[notice_id], [name]: val } }))

  async function updateCheckInfo() {
    setUpdateChequeInfoLoading(true)

    setTimeout(() => {
      const obj = Object.keys(updateCheckDetails)?.map((item) => ({ notice_id: item, ...updateCheckDetails?.[item] }))
      setUpdateChequeInfoLoading(false)
      setUpdateCheckDetails({})
      setShowUpdateChequeDetailsModal(false)
      showAlert({ variant: "warning", msg: "Post API Action Pending..." })
    }, 1000)

    try {
      return
      await updateChequeDetails()
      setUpdateCheckDetails({})
      setShowUpdateChequeDetailsModal(false)
      showAlert({ variant: "success", msg: "Cheque Details Updated Successfully" })
    } catch (error) {
      showAlert({ variant: "danger", msg: "Cheque Details Updation Failed" })
      console.log("Error Occured...", error)
    }

    setUpdateChequeInfoLoading(false)
  }

  useEffect(async () => {
    if (!!showUpdateChequeDetailsModal) {
      setChequeDetailsLoading(true)
      setChequeDetails([])
      try {
        const res = await getPreLitiNoticeData()
        if (!!res?.data) setChequeDetails(res?.data)
      } catch (error) {
        console.log("Error Occured...", error)
      }
      setChequeDetailsLoading(false)
    }
  }, [showUpdateChequeDetailsModal])

  const filterMapping = () => ({
    communication_mode: filterData?.communication_mode || "",
    query: filterData?.query || "",
    search_type: filterData?.search_type || "",
    client_batch_id: filterData?.batch_no || "",
    // last_disposition: '',
    // overdue: '',
    product: filterData?.product || "",
    tracking_status: filterData?.tracking_status || "",
    last_four_digit: filterData?.last_four_digit || "",
    year: filterData?.year || new Date().getFullYear().toString(),
    // amount_from: '',
    // amount_to: '',
    from_date: !!filterData?.date_from ? format(new Date(filterData?.date_from), "yyyy-MM-dd") : "",
    to_date: !!filterData?.date_to ? format(new Date(filterData?.date_to), "yyyy-MM-dd") : "",
    specific_date: !!filterData?.specific_date ? format(new Date(filterData?.specific_date), "yyyy-MM-dd") : "",
  })

  useEffect(() => {
    // apply filter actions
    setSearchParameter({ ...filterMapping() })
  }, [filterData])

  return (
    <>
      <div className="row d-flex d-wrap justify-between filter-section">
        <div className="col-md-12 col-lg-6 d-flex align-center">
          <FilterButton
            onClick={() => setShowFilter(true)}
            active={!!filterData && !!Object.values(filterData)?.some((el) => !!el)}
          />
          <SortButton
            onClick={() => {
              setSortAsc(!SortAsc)
              const sort = SortAsc ? "asc" : "dsc"
              setSearchParameter({ ...filterMapping(), sort })
            }}
            label="Sort By Batch"
            active={!SortAsc}
          />
        </div>

        {!!features?.includes("hide_notice_bulk_action") && (
          <div className="col-md-12 col-lg-6 pt-3 pt-lg-0 text-end">
            <Dropdown className="bulk-action">
              <Dropdown.Toggle variant="primary" className="w-100 text-start select-style-two">
                Select Option for Action
              </Dropdown.Toggle>
              <Dropdown.Menu className="select-style-dropdown-two">
                <Dropdown.Item
                  onClick={() => setShowUpdateChequeDetailsModal(true)}
                  className="d-flex align-items-center"
                >
                  <span style={{ fontSize: "1.3rem", lineHeight: "0" }}>
                    <AiOutlineFileAdd />
                  </span>
                  Update Cheque Details
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}

        <BatchFilter2
          show={showFilter}
          close={() => setShowFilter(false)}
          prevFilter={filterData}
          setPrevFilter={setFilterData}
          searchByPrev={searchBy}
          setSearchByPrev={setSearchBy}
        />
      </div>

      {/* Update Check Details Modal */}
      <Modal
        show={showUpdateChequeDetailsModal}
        size="xl"
        backdrop="static"
        onHide={() => setShowUpdateChequeDetailsModal(false)}
      >
        <Modal.Header>
          <Modal.Title>Update Cheque Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="my-4" style={{ overflow: "scroll", height: "450px" }}>
            {!chequeDetailsLoading && !!chequeDetails?.length && (
              <table className="table table-hover table-small mb-5">
                <thead className="thead-light">
                  <tr>
                    <th className="px-2 align-top">Sl.No.</th>
                    <th className="px-2 align-top">Notice Id</th>
                    <th className="px-2 align-top">Cheque No.</th>
                    <th className="px-2 align-top">Issued Date</th>
                    <th className="px-2 align-top">Bank Name</th>
                    <th className="px-2 align-top">Bank Address</th>
                    <th className="px-2 align-top">Amount</th>
                    <th className="px-2 align-top">Submitted Date</th>
                    <th className="px-2 align-top">Submitted Bank</th>
                    <th className="px-2 align-top">Submitted Bank Address</th>
                    <th className="px-2 align-top">Bounce Reason</th>
                    <th className="px-2 align-top">Account No.</th>
                    <th className="px-2 align-top">Customer Name</th>
                    <th className="px-2 align-top">Returned Date</th>
                  </tr>
                </thead>
                <tbody>
                  {chequeDetails?.map((item, index) => (
                    <tr key={index}>
                      <td className="px-2 align-middle text-center">{index + 1}</td>
                      <td className="px-2 align-middle" style={{ minWidth: "120px" }}>
                        {item?.notice_id}
                      </td>
                      {/* Cheque No. */}
                      <td className="px-2" style={{ minWidth: "160px" }}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Cheque No."
                          value={updateCheckDetails?.[item?.notice_id]?.cheque_no}
                          onChange={(e) => handleUpdateCheckDetails(item?.notice_id, "cheque_no", e.target.value)}
                        />
                      </td>
                      {/* Cheque Issued Date */}
                      <td className="px-2" style={{ minWidth: "160px" }}>
                        <DatePicker
                          label="Select Date"
                          value={
                            !!updateCheckDetails?.[item?.notice_id]?.cheque_issued_date
                              ? parse(
                                  updateCheckDetails?.[item?.notice_id]?.cheque_issued_date,
                                  "yyyy-MM-dd",
                                  new Date(),
                                )
                              : ""
                          }
                          onChange={(val) =>
                            !!val
                              ? handleUpdateCheckDetails(
                                  item?.notice_id,
                                  "cheque_issued_date",
                                  format(new Date(val), "yyyy-MM-dd"),
                                )
                              : {}
                          }
                        />
                      </td>
                      {/* Cheque Bank Name */}
                      <td className="px-2" style={{ minWidth: "160px" }}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Bank Name"
                          value={updateCheckDetails?.[item?.notice_id]?.cheque_bank}
                          onChange={(e) => handleUpdateCheckDetails(item?.notice_id, "cheque_bank", e.target.value)}
                        />
                      </td>
                      {/* Cheque Bank Address */}
                      <td className="px-2" style={{ minWidth: "160px" }}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Bank Address"
                          value={updateCheckDetails?.[item?.notice_id]?.cheque_bank_address}
                          onChange={(e) =>
                            handleUpdateCheckDetails(item?.notice_id, "cheque_bank_address", e.target.value)
                          }
                        />
                      </td>
                      {/* Cheque Amount */}
                      <td className="px-2" style={{ minWidth: "160px" }}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Amount"
                          value={updateCheckDetails?.[item?.notice_id]?.cheque_amount}
                          onChange={(e) => handleUpdateCheckDetails(item?.notice_id, "cheque_amount", e.target.value)}
                        />
                      </td>
                      {/* Cheque Submitted Date */}
                      <td className="px-2" style={{ minWidth: "160px" }}>
                        <DatePicker
                          label="Select Date"
                          value={
                            !!updateCheckDetails?.[item?.notice_id]?.cheque_submitted_date
                              ? parse(
                                  updateCheckDetails?.[item?.notice_id]?.cheque_submitted_date,
                                  "yyyy-MM-dd",
                                  new Date(),
                                )
                              : ""
                          }
                          onChange={(val) =>
                            !!val
                              ? handleUpdateCheckDetails(
                                  item?.notice_id,
                                  "cheque_submitted_date",
                                  format(new Date(val), "yyyy-MM-dd"),
                                )
                              : {}
                          }
                        />
                      </td>
                      {/* Cheque Submitted Bank */}
                      <td className="px-2" style={{ minWidth: "160px" }}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Submitted Bank"
                          value={updateCheckDetails?.[item?.notice_id]?.cheque_submitted_bank}
                          onChange={(e) =>
                            handleUpdateCheckDetails(item?.notice_id, "cheque_submitted_bank", e.target.value)
                          }
                        />
                      </td>
                      {/* Cheque Submitted Bank Address */}
                      <td className="px-2" style={{ minWidth: "160px" }}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Type Address"
                          value={updateCheckDetails?.[item?.notice_id]?.cheque_submitted_bank_address}
                          onChange={(e) =>
                            handleUpdateCheckDetails(item?.notice_id, "cheque_submitted_bank_address", e.target.value)
                          }
                        />
                      </td>
                      {/* Cheque Bounce Reason */}
                      <td className="px-2" style={{ minWidth: "160px" }}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Bounce Reason"
                          value={updateCheckDetails?.[item?.notice_id]?.cheque_bounce_reason}
                          onChange={(e) =>
                            handleUpdateCheckDetails(item?.notice_id, "cheque_bounce_reason", e.target.value)
                          }
                        />
                      </td>
                      {/* Cheque Account No. */}
                      <td className="px-2" style={{ minWidth: "160px" }}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Account No."
                          value={updateCheckDetails?.[item?.notice_id]?.cheque_account_no}
                          onChange={(e) =>
                            handleUpdateCheckDetails(item?.notice_id, "cheque_account_no", e.target.value)
                          }
                        />
                      </td>
                      {/* Cheque Customer Name */}
                      <td className="px-2" style={{ minWidth: "160px" }}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Customer Name"
                          value={updateCheckDetails?.[item?.notice_id]?.cheque_customer_name}
                          onChange={(e) =>
                            handleUpdateCheckDetails(item?.notice_id, "cheque_customer_name", e.target.value)
                          }
                        />
                      </td>
                      {/* Cheque Returned Date */}
                      <td className="px-2" style={{ minWidth: "160px" }}>
                        <DatePicker
                          label="Select Date"
                          value={
                            !!updateCheckDetails?.[item?.notice_id]?.cheque_returned_date
                              ? parse(
                                  updateCheckDetails?.[item?.notice_id]?.cheque_returned_date,
                                  "yyyy-MM-dd",
                                  new Date(),
                                )
                              : ""
                          }
                          onChange={(val) =>
                            !!val
                              ? handleUpdateCheckDetails(
                                  item?.notice_id,
                                  "cheque_returned_date",
                                  format(new Date(val), "yyyy-MM-dd"),
                                )
                              : {}
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {!chequeDetailsLoading && !chequeDetails?.length && <NoRecord />}
            {!!chequeDetailsLoading && <ContentLoader />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setUpdateCheckDetails({})
              setShowUpdateChequeDetailsModal(false)
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!chequeDetails?.length || chequeDetailsLoading || updateChequeInfoLoading}
            onClick={updateCheckInfo}
          >
            {updateChequeInfoLoading ? "updating..." : "Update Details"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PrelitigationLegalNoticesHeader

