"use client"

import { useState, useEffect } from "react"
import Excel from "../../Static/Images/excel.svg"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import { VscLoading } from "react-icons/vsc"
import { MultiSelect } from "react-multi-select-component"
import {
  // downloadPreBatchReport,
  postPreLitiBatchData,
  // downloadTrackFile,
  getAgentLanguages,
} from "../../API"
import { labelMapping, mappings } from "../../utils/file_mapping_constants/preletigation_legal_batch_mapping"
import BatchFilter from "./precomps/BatchFilter"
import { useBatchUploadHandler } from "../../hooks/batchUploadHandler"
import UploadProgressBar from "../../ui/UploadProgressBar"
import BatchUploadHandler from "../../ui/BatchUploadHandler"
import { toast } from "react-toastify"
import FilterButton from "../../ui/buttons/FilterButton"
import SortButton from "../../ui/buttons/SortButton"
import { format } from "date-fns"
import BatchOnlyUploadModal from "../../Panel/Components/Prelitigation/LegalNotices/BatchOnlyUploadModal"
import { getNoticeTypes, getNoticeUpfrontMappingFields } from "../../API/panelApis"
import SelectDropdown from "../../ui/forms/SelectDropdown"
import useAuthStore from "../../store/authStore"
import useClientStore from "../../store/clientStore"
import MappingRepeatedCols from "../../Panel/Components/MappingRepeatedCols"
import { productOptions } from "../../utils/constants/products"

const commModeOptions = [
  { label: "Postal", value: "postal" },
  { label: "Whatsapp", value: "whatsapp" },
  { label: "Email", value: "email" },
  { label: "SMS", value: "sms" },
]

const monthOptions = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
]

// Generate year options for the last 5 years including current year
const generateYearOptions = () => {
  const currentYear = new Date().getFullYear()
  return Array.from({ length: 5 }, (_, i) => {
    const year = currentYear - i
    return { label: year.toString(), value: year }
  })
}

const yearOptions = generateYearOptions()

function PrelitigationFirBatchHeader({ setSearchParameter, getData }) {
  const userData = useAuthStore((state) => state.userData)
  const features = useAuthStore((state) => state.features)

  // mapping
  const removeMapping = useClientStore((state) => state.removeMapping)
  const addMapping = useClientStore((state) => state.addMapping)

  const [importPage, setImportPage] = useState(false)
  const [IsUploading, setIsUploading] = useState(false)
  const [downloadReportLink, setDownloadReportLink] = useState()
  const [noticeType, setNoticeType] = useState("")
  const [amountType, setAmountType] = useState("")
  const [commicationMode, setCommicationMode] = useState([])
  const [noticeLanguage, setNoticeLanguage] = useState("")
  const [productGroup, setProductGroup] = useState("")
  const [batchMonth, setBatchMonth] = useState(new Date().getMonth() + 1)
  const [batchYear, setBatchYear] = useState(new Date().getFullYear())
  const [noticeLanguageOptions, setNoticeLanguageOptions] = useState([])
  const [SortAsc, setSortAsc] = useState(true)
  const [showUploadBatchOnlyModal, setShowUploadBatchOnlyModal] = useState(false)
  const [noticeTypeOptions, setNoticeTypeOptions] = useState([])
  const [toShowParams, setToShowParams] = useState([])
  const [repeatedCols, setRepeatedCols] = useState({})

  // filter
  const [showFilter, setShowFilter] = useState(false)
  const [filterData, setFilterData] = useState({
    search: "",
    batch_no: "",
    month: "",
    year: new Date().getFullYear().toString(),
    date_from: null,
    date_to: null,
    specific_date: null,
  })

  const filterMapping = () => ({
    customer_name: filterData?.search?.trim() ?? "",
    client_batch_id: filterData?.batch_no || "",
    last_disposition: null,
    overdue: null,
    month: filterData?.month || "",
    year: filterData?.year || new Date().getFullYear().toString(), // Include year in filter mapping
    product: null,
    status: null,
    amount_from: null,
    amount_to: null,
    from_date: !!filterData?.date_from ? format(new Date(filterData?.date_from), "yyyy-MM-dd") : "",
    to_date: !!filterData?.date_to ? format(new Date(filterData?.date_to), "yyyy-MM-dd") : "",
    specific_date: !!filterData?.specific_date ? format(new Date(filterData?.specific_date), "yyyy-MM-dd") : "",
  })

  useEffect(() => {
    // apply filter actiins
    setSearchParameter({ ...filterMapping() })
  }, [filterData])

  //Modal
  const [lgShow, setLgShow] = useState(false)
  const [pause, setPause] = useState(false)

  useEffect(() => {
    if (!!lgShow) addMapping(mappings)
  }, [lgShow])

  // batch upload handler hook
  const {
    progressValue,
    importDisabled,
    fileName,
    batchFile,
    matchedHeaderNames,
    handleHeaderChange,
    availableHeaderNames,
    handleFileValidation,
    getUploadHeaders,
    resetBatchUpload,
    SelectSheetModal,
  } = useBatchUploadHandler({ showProgress: true })

  const fetchNoticeUpfrontParams = async () => {
    const res = await getNoticeUpfrontMappingFields({
      client_id: userData?.client_id,
    })
    if (res) setToShowParams(res?.data?.fields)
  }

  useEffect(() => {
    fetchNoticeUpfrontParams()
  }, [userData])

  const exportColumns = async () => {
    setIsUploading(true)
    try {
      const columnData = getUploadHeaders()
      const column_rename_data = { ...columnData, ...repeatedCols }

      const formData = new FormData()

      formData.append("batch_file", batchFile)
      formData.append("batch_name", fileName)
      formData.append("notice_type", noticeType)
      formData.append("amount_type", amountType)
      formData.append("notice_language", noticeLanguage)
      formData.append("product_group", productGroup)
      formData.append("batch_month", batchMonth)
      formData.append("batch_year", batchYear)
      formData.append("communication_mode", JSON.stringify(commicationMode))
      formData.append("column_rename_data", JSON.stringify(column_rename_data))

      const res = await postPreLitiBatchData(formData)
      if (res) {
        removeMapping()

        toast.success("imported successfully")
        setImportPage(false)
        resetBatchUpload()
        setCommicationMode([])
        setNoticeLanguage("")
        setNoticeType("")
        setAmountType("")
        setBatchMonth(new Date().getMonth() + 1)
        setBatchYear(new Date().getFullYear())
        setIsUploading(false)
        setLgShow(false)
        getData()
        setRepeatedCols({})
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchNoticeTypes = async () => {
    const res = await getNoticeTypes({ client_id: userData?.client_id })
    console.log(res)
    if (res) setNoticeTypeOptions(res?.data?.map((el) => ({ label: el, value: el })))
  }

  const fetchLanguages = async () => {
    const res = await getAgentLanguages()
    if (res)
      setNoticeLanguageOptions(
        res?.map(({ language_name }) => ({
          label: language_name?.charAt(0)?.toUpperCase() + language_name?.slice(1),
          value: language_name,
        })),
      )
  }

  useEffect(() => {
    fetchNoticeTypes()
    fetchLanguages()
  }, [])

  return (
    <div className="row d-flex d-wrap justify-between filter-section">
      <>
        <div className="col-md-12 col-lg-6 d-flex align-center">
          {/* <input type="checkbox" color="primary" className="me-2" /> */}
          <FilterButton
            onClick={() => setShowFilter(true)}
            active={!!filterData && !!Object.values(filterData)?.some((el) => !!el)}
          />
          <SortButton
            onClick={() => {
              setSortAsc(!SortAsc)
              const sort = SortAsc ? "asc" : "dsc"
              setSearchParameter({ ...filterMapping(), sort })
            }}
            label="Sort By Batch"
            active={!SortAsc}
          />
        </div>

        {features?.includes("show_legal_notice_batch_upload_only") && (
          // this does not contain any batch mappings
          <>
            <div className="col-md-12 col-lg-6 pt-3 pt-lg-0 text-end">
              <button className="btn btn-primary btn-lg ms-2" onClick={() => setShowUploadBatchOnlyModal(true)}>
                <svg width="14" height="17" xmlns="http://www.w3.org/2000/svg">
                  <g fill="#ffffff" fillRule="evenodd">
                    <path d="M.903 8.201a.7.7 0 0 1 1.4 0v5.601a.7.7 0 0 0 .701.7h8.405a.7.7 0 0 0 .7-.7v-5.6a.7.7 0 0 1 1.4 0v5.6a2.1 2.1 0 0 1-2.1 2.1H3.004a2.1 2.1 0 0 1-2.101-2.1v-5.6zM7.206 2.19 4.9 4.497a.7.7 0 0 1-.99-.99L6.71.706a.7.7 0 0 1 .99 0l2.802 2.8a.7.7 0 1 1-.99.99L7.206 2.19z" />
                    <path d="M6.506 1.2a.7.7 0 0 1 1.4 0v9.102a.7.7 0 0 1-1.4 0V1.2z" />
                  </g>
                </svg>
                Upload New Batch File
              </button>
            </div>
            <BatchOnlyUploadModal
              show={showUploadBatchOnlyModal}
              setShow={setShowUploadBatchOnlyModal}
              getData={getData}
            />
          </>
        )}

        {!features?.includes("hide_legal_notice_batch_upload") && (
          <div className="col-md-12 col-lg-6 pt-3 pt-lg-0 text-end">
            <button className="btn btn-primary btn-lg ms-2" onClick={() => setLgShow(true)}>
              <svg width="14" height="17" xmlns="http://www.w3.org/2000/svg">
                <g fill="#ffffff" fillRule="evenodd">
                  <path d="M.903 8.201a.7.7 0 0 1 1.4 0v5.601a.7.7 0 0 0 .701.7h8.405a.7.7 0 0 0 .7-.7v-5.6a.7.7 0 0 1 1.4 0v5.6a2.1 2.1 0 0 1-2.1 2.1H3.004a2.1 2.1 0 0 1-2.101-2.1v-5.6zM7.206 2.19 4.9 4.497a.7.7 0 0 1-.99-.99L6.71.706a.7.7 0 0 1 .99 0l2.802 2.8a.7.7 0 1 1-.99.99L7.206 2.19z" />
                  <path d="M6.506 1.2a.7.7 0 0 1 1.4 0v9.102a.7.7 0 0 1-1.4 0V1.2z" />
                </g>
              </svg>
              Upload New Batch File
            </button>
          </div>
        )}
      </>

      {downloadReportLink ? <a href={downloadReportLink} className="d-none"></a> : ""}
      {<a className="d-none" id="trackDowload" href=""></a>}
      <BatchFilter
        show={showFilter}
        close={() => setShowFilter(false)}
        prevFilter={filterData}
        setPrevFilter={setFilterData}
      />

      {/**/}
      <Modal
        show={lgShow}
        onHide={() => setLgShow(false)}
        dialogClassName="modal-large"
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">Upload New Batch File</Modal.Title>
        </Modal.Header>
        <Modal.Body className="tab-m-body">
          <div className="pt-0 pb-5 text-center upload-batch-popup">
            {IsUploading ? (
              <Modal.Title id="example-custom-modal-styling-title">
                <h1 style={{ height: "300px", display: "grid", placeItems: "center" }}>
                  {" "}
                  <span>
                    <VscLoading className="uploader" /> Uploading
                  </span>{" "}
                </h1>
              </Modal.Title>
            ) : (
              <>
                <div className="d-flex import-step align-center content-center mt-3">
                  <div
                    className={`import-step content-center ${progressValue < 50 ? "step-active" : "step-completed"}`}
                  >
                    {progressValue < 100 ? (
                      "01"
                    ) : (
                      <svg width="34" height="34" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17 0C7.626 0 0 7.626 0 17c0 9.374 7.626 17 17 17 9.374 0 17-7.626 17-17 0-9.374-7.626-17-17-17zm8.135 13.995-8.757 8.757a2.118 2.118 0 0 1-1.503.623 2.118 2.118 0 0 1-1.502-.623l-4.508-4.507a2.124 2.124 0 1 1 3.005-3.005l3.005 3.005 7.255-7.255a2.124 2.124 0 1 1 3.005 3.005z"
                          fill="#409E7B"
                          fillRule="nonzero"
                        />
                      </svg>
                    )}

                    <span>Import File</span>
                  </div>
                  <div className="import-step-sep"></div>
                  <div className={`import-step content-center ${progressValue >= 100 && "step-active"}`}>
                    02
                    <span>Map Columns</span>
                  </div>
                </div>

                {importPage ? (
                  progressValue < 100 ? (
                    <UploadProgressBar progressValue={progressValue} />
                  ) : (
                    <>
                      <BatchUploadHandler
                        availableHeaderNames={availableHeaderNames}
                        matchedHeaderNames={matchedHeaderNames}
                        labelMapping={labelMapping}
                        upfrontColumns={toShowParams}
                        handleHeaderChange={handleHeaderChange}
                        automapping={false}
                      />

                      <MappingRepeatedCols columns={availableHeaderNames} onChange={setRepeatedCols} />
                    </>
                  )
                ) : (
                  <div className="w-75 m-auto">
                    <div className="row">
                      <div className="col-lg-4">
                        <form className="mt-2 text-start">
                          {/* <p className="mb-4 text-danger fs-7">
                                                        <strong>**All Are Fields Mandatory </strong>
                                                    </p> */}

                          <div className="mb-3">
                            <p className="mb-2">
                              Type of Notice to be Issued <span className="text-danger">*</span>
                            </p>
                            <SelectDropdown
                              placeholder="Select Notice Type"
                              options={noticeTypeOptions}
                              value={noticeType}
                              onChange={setNoticeType}
                            />
                          </div>

                          <div className="mb-3">
                            <p className="mb-2">Amount Type</p>
                            <SelectDropdown
                              placeholder="Amount Type"
                              options={[
                                { label: "POS", value: "pos" },
                                { label: "Pursuable", value: "pursuable" },
                                { label: "TOS", value: "tos" },
                                { label: "Lein Amount", value: "lein_amount" },
                                { label: "Loan Amount", value: "loan_amount" },
                              ]}
                              value={amountType}
                              onChange={setAmountType}
                            />
                          </div>

                          <div className="mb-3">
                            <p className="mb-2">
                              Communication Mode <span className="text-danger">*</span>
                            </p>
                            <MultiSelect
                              className="bg-light-grey text-dark"
                              options={commModeOptions}
                              value={commModeOptions?.filter((el) => commicationMode?.includes(el?.value))}
                              onChange={(val) => setCommicationMode(val?.map((el) => el?.value))}
                              labelledBy="Select Communication Mode"
                            />
                          </div>

                          <div className="mb-3">
                            <p className="mb-2">
                              Notice Language <span className="text-danger">*</span>
                            </p>
                            <SelectDropdown
                              options={noticeLanguageOptions}
                              value={noticeLanguage}
                              onChange={setNoticeLanguage}
                              placeholder="Select Languages"
                            />
                          </div>

                          <div className="mb-3">
                            <p className="mb-2">
                              Product Group <span className="text-danger">*</span>
                            </p>
                            <SelectDropdown
                              options={productOptions}
                              value={productGroup}
                              onChange={setProductGroup}
                              placeholder="Select Product Group"
                            />
                          </div>

                          <div className="mb-3">
                            <p className="mb-2">
                              Batch Month <span className="text-danger">*</span>
                            </p>
                            <SelectDropdown
                              options={monthOptions}
                              value={batchMonth}
                              onChange={setBatchMonth}
                              placeholder="Select Batch Month"
                            />
                          </div>
                          <div className="mb-3">
                            <p className="mb-2">
                              Batch Year <span className="text-danger">*</span>
                            </p>
                            <SelectDropdown
                              options={yearOptions}
                              value={batchYear}
                              onChange={setBatchYear}
                              placeholder="Select Batch Year"
                            />
                          </div>
                        </form>
                      </div>
                      <div className="col-lg-8">
                        <div className="import-excel-img mb-2">
                          <img src={Excel || "/placeholder.svg"} alt="Excel" />
                        </div>
                        <input
                          type="file"
                          name="uploadFile"
                          id="uploadFile"
                          accept=".xlse,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xls,.xlsb,.xml,.xla,.xlw,.xlr"
                          style={{ opacity: "0" }}
                          disabled={!noticeType || !noticeLanguage || !commicationMode.length}
                          onChange={(e) => {
                            setImportPage(true)
                            handleFileValidation(e)
                            // handleFileValidation(e, mappings);
                          }}
                        />
                        <label
                          htmlFor="uploadFile"
                          style={
                            noticeType && !!noticeLanguage && !!commicationMode.length
                              ? {}
                              : { background: "#F1F8E9", border: "#607D8B", color: "#607D8B" }
                          }
                          className="btn btn-primary btn-lg mb-2"
                        >
                          Select excel file from your computer
                        </label>
                        <p>You can upload CSV or XLS or Binary file.</p>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {importPage && (
            <Button
              variant="primary"
              disabled={importDisabled || progressValue < 100}
              className="collection_batch_upload_page_footer_import"
              onClick={exportColumns}
            >
              Import
            </Button>
          )}
          <Button variant="secondary" onClick={() => setPause(true)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/**/}

      <SelectSheetModal />

      <Modal
        show={pause}
        onHide={() => setPause(false)}
        backdrop="static"
        dialogClassName="modal-small resetModal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title text-center">Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="tab-m-body language-popup">
          <div className="pt-4 pb-4 ps-3  text-center">Your will lost you filled data. Are you sure?</div>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setImportPage(false)
                setIsUploading(false)
                resetBatchUpload()
                setCommicationMode([])
                setNoticeLanguage("")
                setNoticeType("")
                setAmountType("")
                setPause(false)
                setLgShow(false)
                setBatchMonth(new Date().getMonth() + 1)
                setBatchYear(new Date().getFullYear())
              }}
            >
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setPause(false)
              }}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default PrelitigationFirBatchHeader

