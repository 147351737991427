import React, { useState, useEffect } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { FaInfoCircle } from "react-icons/fa";
import { showToast } from "../../../utils/handleToast";
import axios from "axios";

function TakeActionButton({ itemId, loanAccountNo, onSubmit, data, disposition }) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isActionTaken, setIsActionTaken] = useState(false);
  const [callQuality, setCallQuality] = useState("");
  const [apiResponse, setApiResponse] = useState(null);

  const initialCriteria = {
    "Call Opening": "",
    "No Grace Period": "",
    "Legal Consequences": "",
    "Home & Office Visit": "",
    "Willful Defaulter": "",
    ...(data?.client_name !== "Moneyview" && { "Police Action Consequences": "" }),
    ...(data?.client_name === "Moneyview" && { "No Police Action": "" }),
    ...(disposition === "RTP" && { "Refuse to Pay Responses": "" }),
    ...(disposition === "Settlement" && { "Settlement Final Chance": "" }),
    ...((disposition === "BPTP" || disposition === "later_issued") && { "Broken PTP / Letter Issued, Not Paid": "" }),
    ...(data?.client_name === "L&T" && { "Vehicle Repossession": "" }),
    "Call Closing": "",
  };


  const [selectedCriteria, setSelectedCriteria] = useState(initialCriteria);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const statusResponse = await axios.get(`https://api.themedius.ai/call/api/agent_call_audit_assignment/?loan_account_no=${loanAccountNo}`);
        if (statusResponse.data[0]?.loan_account_no === loanAccountNo) {
          setIsActionTaken(true);
        }

        const apiResp = await axios.get(`https://api.themedius.ai/call/api/get_call_audit_answer/?loan_account_no=${loanAccountNo}`);
        if (apiResp.data.status) {
          setApiResponse(apiResp.data.data);
          console.log("API Response:", apiResp.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        showToast({
          variant: "danger",
          message: "Failed to fetch data. Please try again.",
        });
      }
    };
    if (loanAccountNo) {
      fetchData();
    }
  }, [loanAccountNo]);

  useEffect(() => {
    if (show) {
      ReactTooltip.rebuild();
    }
  }, [show]);

  const handleRadioChange = (criteria, value) => {
    setSelectedCriteria((prev) => ({
      ...prev,
      [criteria]: value,
    }));
    if (errors[criteria]) {
      setErrors((prev) => ({ ...prev, [criteria]: "" }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;
    const errorMessages = [];

    console.log("Selected Criteria:", selectedCriteria);
    console.log("Call Quality:", callQuality);

    // Check for empty selections
    Object.keys(selectedCriteria).forEach((criteria) => {
      if (!selectedCriteria[criteria]) {
        newErrors[criteria] = "Please select Pass or Fail";
        errorMessages.push(`${criteria}: Please select Pass or Fail`);
        isValid = false;
      }
    });

    if (!callQuality) {
      newErrors.callQuality = "Please select call quality";
      errorMessages.push("Call Quality: Please select Good or Bad");
      isValid = false;
    }

    // Compare with API response if available
    if (apiResponse && isValid) {
      const criteriaMapping = {
        "Call Opening": "call_opening",
        "No Grace Period": "no_grace_period",
        "Legal Consequences": "legal_consequences",
        "Home & Office Visit": "home_and_office_visit",
        "Willful Defaulter": "willful_defaulter",
        "Police Action Consequences": "police_action_consequences",
        "Broken PTP / Letter Issued, Not Paid": "broken_ptp_letter_issued_not_paid",
        "Call Closing": "call_closing",
        "No Police Action": "no_fir",
        "Refuse to Pay Responses": "refuse_to_pay_responses",
        "Settlement Final Chance": "settlement_final_chance",
      };

      Object.keys(selectedCriteria).forEach((criteria) => {
        const apiKey = criteriaMapping[criteria];
        if (apiKey && apiResponse[apiKey] !== undefined) {
          const expectedValue = apiResponse[apiKey] ? "Pass" : "Fail";
          if (selectedCriteria[criteria] !== expectedValue) {
            newErrors[criteria] = `Expected ${expectedValue} based on audit`;
            errorMessages.push(`${criteria}: Expected ${expectedValue} based on audit`);
            isValid = false;
          }
        }
      });

      const expectedQuality = apiResponse.call_quality === "good_call" ? "Good" : "Bad";
      if (callQuality !== expectedQuality) {
        newErrors.callQuality = `Expected ${expectedQuality} based on audit`;
        errorMessages.push(`Call Quality: Expected ${expectedQuality} based on audit`);
        isValid = false;
      }
    }

    console.log("Validation Errors:", newErrors);
    setErrors(newErrors);
    return { isValid, errorMessages };
  };

  const triggerAction = async () => {
    const { isValid, errorMessages } = validateForm();
    if (!isValid) {
      showToast({
        variant: "warning",
        message: errorMessages.length > 0 ? errorMessages.join("; ") : "Please correct the errors before submitting",
      });
      return;
    }
    setLoading(true);
    const payload = {
      call_quality: callQuality === "Good" ? "good_call" : "bad_call",
      call_opening: selectedCriteria["Call Opening"] === "Pass",
      no_grace_period: selectedCriteria["No Grace Period"] === "Pass",
      legal_consequences: selectedCriteria["Legal Consequences"] === "Pass",
      home_and_office_visit: selectedCriteria["Home & Office Visit"] === "Pass",
      willful_defaulter: selectedCriteria["Willful Defaulter"] === "Pass",
      police_action_consequences: selectedCriteria["Police Action Consequences"] === "Pass",
      broken_ptp_letter_issued_not_paid: selectedCriteria["Broken PTP / Letter Issued, Not Paid"] === "Pass",
      ...(disposition === "RTP" && {
        refuse_to_pay_responses: selectedCriteria["Refuse to Pay Responses"] === "Pass",
      }),
      ...(disposition === "Settlement" && {
        settlement_final_chance: selectedCriteria["Settlement Final Chance"] === "Pass",
      }),
      call_closing: selectedCriteria["Call Closing"] === "Pass",
      no_fir: selectedCriteria["No Police Action"] === "Pass",
      loan_account_no: loanAccountNo,
    };

    try {
      const response = await axios.post("https://api.themedius.ai/call/api/agent_call_audit_assignment/", payload, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.status === 200 || response.status === 201) {
        setIsActionTaken(true);
        if (onSubmit) onSubmit(itemId);
        showToast({
          variant: "success",
          message: "Success! All criteria marked correctly. You can now audit the next call.",
        });
        closeModal();
      }
    } catch (error) {
      console.error(`Error submitting audit for item ${itemId}:`, error);
      showToast({ variant: "danger", message: "Failed to submit audit data" });
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    if (!loading) {
      setShow(false);

    }
  };

  const getCorrectSymbol = (criteria) => {
    if (!apiResponse || !selectedCriteria[criteria]) return "➖";
    const criteriaMapping = {
      "Call Opening": "call_opening",
      "No Grace Period": "no_grace_period",
      "Legal Consequences": "legal_consequences",
      "Home & Office Visit": "home_and_office_visit",
      "Willful Defaulter": "willful_defaulter",
      "Police Action Consequences": "police_action_consequences",
      "Broken PTP / Letter Issued, Not Paid": "broken_ptp_letter_issued_not_paid",
      "Call Closing": "call_closing",
      "No Police Action": "no_fir",
      "Refuse to Pay Responses": "refuse_to_pay_responses",
      "Settlement Final Chance": "settlement_final_chance",
    };

    const apiKey = criteriaMapping[criteria];
    const expectedValue = apiResponse[apiKey] ? "Pass" : "Fail";
    return selectedCriteria[criteria] === expectedValue ? "✅" : "❌";
  };

  const getCallQualityCorrectSymbol = () => {
    if (!apiResponse || !callQuality) return "➖";
    const expectedQuality = apiResponse.call_quality === "good_call" ? "Good" : "Bad";
    return callQuality === expectedQuality ? "✅" : "❌";
  };

  const tooltipData = {
    "Call Opening": {
      Fail: "The agent did not verify the borrower's identity or did not state the purpose of the call.",
      Pass: "The agent verified the borrower’s identity and clearly stated the purpose of the call.",
      Info: `<div><strong>Call Opening</strong><ul><li>This is ${data?.agent_name || "Agent"} from KS Legal.</li><li>You have defaulted on your loan with ${
        data?.client_name || "Client"
      }.</li><li>Your case has now been assigned for legal action.</li></ul></div>`,
    },
    "No Grace Period": {
      Fail: "The agent offered extra time for payment or did not ask to arrange from family & friends to pay within 24 hours.",
      Pass: "The agent clearly stated that no further extension or grace period would be given since the case is in legal now & asked to arrange from family & friends.",
      Info: `<div><strong>No Grace Period</strong><ul><li>You have already been given time, further time will NOT be granted</li><li>You have to pay ₹${
        data?.tos || "amount"
      } by 5 PM today / within 24 hours.</li><li>Arrange funds immediately, even through friends or family.</li></ul></div>`,
    },
    "Legal Consequences": {
      Fail: "The agent did not mention legal penalties, conciliation hearing, or CIBIL impact.",
      Pass: "The agent clearly mentioned legal consequences, such as appointment of lawyer, Rs. 30000 penalty for not attending legal hearings, or impact on CIBIL score.",
      Info: `<div><strong>Legal Consequences</strong><ul><li>Pay today - No Grace Period</li><li>Appoint Lawyer - Reply to Notice</li><li>Conciliation Hearing - 30k Penalty</li><li>CIBIL Score - Will Never Get Loan</li></ul></div>`,
    },
    "Home & Office Visit": {
      Fail: "The agent did not inform the borrower about home or office visits.",
      Pass: "The agent informed the borrower that collection executives would visit their home or office with a legal notice.",
      Info: `<div><strong>Home & Office Visit</strong><ul><li>Home Visit & Legal Notice</li><li>Office Visit & Legal Notice</li></ul></div>`,
    },
    "Willful Defaulter": {
      Fail: "The agent did not mention asset seizure, RBI reporting, salary freeze, or bank account freeze.",
      Pass: "The agent clearly stated the consequences of being marked as a willful defaulter, including financial and legal repercussions.",
      Info: `<div><strong>Willful Defaulter</strong><ul><li>All Assets Known to Government</li><li>Reporting to RBI - Willful Defaulter</li><li>If Salaried - Garnishee Order</li><li>If Business - Bank A/c Freeze</li></ul></div>`,
    },
    "Police Action Consequences": {
      Fail: "The agent did not mention FIR, arrest warrant, NACH Bounce (Section 25), or legal prosecution under Bharatiya Nyaya Sanhita (Sections 316, 318, 323).",
      Pass: "The agent clearly communicated FIR, arrest warrant, NACH Bounce (Section 25), or legal prosecution under Bharatiya Nyaya Sanhita (Sections 316, 318, 323).",
      Info: `<div><strong>Police Action Consequences</strong><ul><li>FIR & Arrest Warrant</li><li>NACH Bounce - Section 25</li><li>Bharatiya Nyaya Sanhita (Sections 316, 318, 323)</li></ul></div>`,
    },
    "Broken PTP / Letter Issued, Not Paid": {
      Fail: "Agent did not highlight multiple past broken payment promises. Agent did not mention that settlement letter was issued on request and this is final chance. It will be treated as wilful default, and police action will begin.",
      Pass: "Agent clearly highlighted multiple past broken payment promises. Agent mentioned that settlement letter was issued on request and this is final chance. It will be treated as wilful default, and police action will begin.",
      Info: `<div><strong>Broken PTP / Letter Issued, Not Paid</strong><ul><li>Record broken promises</li><li>Settlement letter expiry warning</li><li>Legal consequences</li></ul></div>`,
    },
    "Refuse to Pay Responses": {
      Fail: "Agent did not handle RTP cases effectively.",
      Pass: "Agent tackled RTP cases effectively.",
      Info: `<div><strong>Refuse to Pay Responses</strong><ul><li>Medical issues don't exempt repayment</li><li>Job loss doesn't exempt repayment</li><li>Business failure doesn't exempt repayment</li></ul></div>`,
    },
    "Settlement Final Chance": {
      Fail: "The agent did not discuss OTS offer (One-Time Settlement) & did not present it as the final opportunity with no negotiation. If not paid within 24 hours, it will be permanently withdrawn.",
      Pass: "Agent discussed OTS offer and presented it as the final opportunity with no negotiation. Agent stated that if not paid within 24 hours, it will be permanently withdrawn.",
      Info: `<div><strong>Settlement Final Chance</strong><ul><li>OTS - One Time Settlement Offer</li><li>No Waiver Negotiation</li><li>Pay settlement in 24 Hours</li><li>Else pay full amount</li></ul></div>`,
    },
    "Call Closing": {
      Fail: "Agent did not mention that PTP/refusal is recorded for legal purposes.",
      Pass: "Agent mentioned that payment/refusal is recorded for legal purposes.",
      Info: `<div><strong>Call Closing</strong><ul><li>Record payment promise/refusal</li><li>Legal action warning</li><li>Proof of payment request</li></ul></div>`,
    },
    "No Police Action": {
      Fail: "Agent mentioned police action despite instructions not to.",
      Pass: "Agent followed guidelines and did not mention police action.",
      Info: `<div><strong>No Police Action</strong><ul>${
        data?.client_id === 51 || data?.client_id === 35
          ? "<li>Do not speak about Police Action</li>"
          : "<li>FIR & Arrest Warrant</li><li>NACH Bounce - Section 25</li><li>Bharatiya Nyaya Sanhita Sections</li>"
      }</ul></div>`,
    },
    "Vehicle Repossession": {
      Fail: "The agent did not mention vehicle repossession, SARFAESI Act, public embarrassment or failed to take complete info",
      Pass: "The agent informed the borrower about the vehicle repossession process, legal provisions, and took all information",
      Info: `<div><strong>Vehicle Repossession</strong><ul><li>The agent did not mention vehicle repossession, SARFAESI Act, public embarrassment or failed to take complete info.</li><li>The agent informed the borrower about the vehicle repossession process, legal provisions, and took all information.</li></ul></div>`,

    },
  };

  const getTooltipId = (criteria, type = "Info") => `${criteria.replace(/[\s&\/,]+/g, "")}${type}`;

  return (
    <>
      <Dropdown.Item
        onClick={() => setShow(true)}
        disabled={isActionTaken || loading}
        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <div
          style={{
            backgroundColor: isActionTaken ? "#009EC0" : "lightblue",
            width: "100%",
            color: isActionTaken ? "white" : "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          {isActionTaken ? "Taken" : "Take Action"}
        </div>
      </Dropdown.Item>

      <Modal className="agent-report-popup" size="md" show={show} onHide={closeModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Call Quality Audit - Criteria Check</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-2">
          <table className="table">
            <thead>
              <tr>
                <th style={{ paddingLeft: "32px" }}>Criteria</th>
                <th style={{ textAlign: "center" }}>Pass</th>
                <th style={{ textAlign: "center" }}>Fail</th>
                <th style={{ textAlign: "center" }}>Result</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(selectedCriteria).map((criteria) => {

                return (
                  <tr key={criteria}>
                    <td>
                      {criteria}{" "}
                      <span>
                        <FaInfoCircle
                          data-tip={tooltipData[criteria]?.Info}
                          data-html={true}
                          data-for={getTooltipId(criteria, "Info")}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                      {errors[criteria] && <div style={{ color: "red", fontSize: "12px" }}>{errors[criteria]}</div>}
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name={criteria}
                        value="Pass"
                        checked={selectedCriteria[criteria] === "Pass"}
                        onChange={() => handleRadioChange(criteria, "Pass")}
                        data-tip={tooltipData[criteria]?.Pass}
                        data-for={getTooltipId(criteria, "Pass")}
                        disabled={loading}
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="radio"
                        name={criteria}
                        value="Fail"
                        checked={selectedCriteria[criteria] === "Fail"}
                        onChange={() => handleRadioChange(criteria, "Fail")}
                        data-tip={tooltipData[criteria]?.Fail}
                        data-for={getTooltipId(criteria, "Fail")}
                        disabled={loading}
                      />
                    </td>
                    <td className="text-center">{getCorrectSymbol(criteria)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {Object.keys(tooltipData).map((criteria) => (
            <React.Fragment key={criteria}>
              <ReactTooltip id={getTooltipId(criteria, "Info")} effect="solid" place="right" type="dark" html={true} className="py-2 px-3" />
              <ReactTooltip id={getTooltipId(criteria, "Pass")} effect="solid" place="right" type="dark" className="py-2 px-3 w-25" />
              <ReactTooltip id={getTooltipId(criteria, "Fail")} effect="solid" place="right" type="dark" className="py-2 px-3 w-25" />
            </React.Fragment>
          ))}

          <div
            className="d-flex"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px",
            }}
          >
            <label
              className="text-bold"
              style={{
                whiteSpace: "nowrap",
                fontWeight: "bold",
                color: "black",
                paddingLeft: "12px",
              }}
            >
              Final Remark
            </label>
            <div
              className="d-flex"
              style={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                <input
                  type="radio"
                  name="callQuality"
                  value="Good"
                  checked={callQuality === "Good"}
                  onChange={(e) => {
                    setCallQuality(e.target.value);
                    if (errors.callQuality) setErrors((prev) => ({ ...prev, callQuality: "" }));
                  }}
                  disabled={loading}
                />
                Good
              </label>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                <input
                  type="radio"
                  name="callQuality"
                  value="Bad"
                  checked={callQuality === "Bad"}
                  onChange={(e) => {
                    setCallQuality(e.target.value);
                    if (errors.callQuality) setErrors((prev) => ({ ...prev, callQuality: "" }));
                  }}
                  disabled={loading}
                />
                Bad
              </label>
            </div>
            <div className="text-center" style={{ width: "60px" }}>
              {getCallQualityCorrectSymbol()}
            </div>
          </div>
          {errors.callQuality && <div style={{ color: "red", fontSize: "12px", textAlign: "center" }}>{errors.callQuality}</div>}
          <div className="mt-2 text-center">
            <Button disabled={loading} variant="primary" onClick={triggerAction}>
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TakeActionButton;